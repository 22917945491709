/* eslint-disable @typescript-eslint/no-var-requires */
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { LineResult, QueryResult } from '../../../store/admin/query/types'

// Load Highcharts modules
// tslint:disable
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/broken-axis')(Highcharts)
// tslint:enable
interface Props {
  queryResult: QueryResult
}

const HcLine: FC<Props> = (props: Props) => {
  const queryResult = props.queryResult
  const [opts, setOpts] = useState<any>(null)
  const [chartData, setChartData] = useState<any>(null)

  useEffect(() => {
    if (queryResult.data.length) {
      const result = queryResult.data.map((d) => {
        const singleResult = d.result.map((r) => {
          const lr = r as LineResult
          return { x: lr.x, y: lr.y, additionalData: lr.additionalData }
        })
        let type = 'line'
        switch (d.plotType) {
          case 2:
            type = 'scatter'
            break
          case 3:
            type = 'candlestick'
        }
        return {
          data: singleResult,
          type,
          name: d.source
        }
      })
      setChartData(result)
    } else {
      setChartData(null)
    }
  }, [queryResult])

  useEffect(() => {
    const test = {
      chart: {
        zoomType: 'x'
      },
      title: {
        text: queryResult.queryTitle
      },
      xAxis: {
        type: 'datetime',
        breaks: queryResult.breaks
        /* labels: {
          formatter() {
            const date = new Date(this.value)
            return `${
              date.getMonth() + 1
            }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
          }
        }*/
      },
      /* yAxis: {
        title: {
          text: 'Exchange rate'
        }
      },*/
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          turboThreshold: 2000 // set it to a larger threshold, it is by default to 1000
        }
      },
      tooltip: {
        formatter() {
          const dt = new Date(this.x)
          const formattedDate = moment(dt).format('MMM DD HH:mm')
          if (queryResult.queryName === 'BondsPro Markets And Trades') {
            if (this.series.name === 'OfferPrice') {
              return `<b>Offer Price</b><br>${formattedDate}<br>Best: ${this.point.additionalData.size} @ ${this.y}<br>Stack: ${this.point.additionalData.count} / ${this.point.additionalData.stackSize}`
            } else if (this.series.name === 'BidPrice') {
              return `<b>Bid Price</b><br>${formattedDate}<br>Best: ${this.point.additionalData.size} @ ${this.y}<br>Stack: ${this.point.additionalData.count} / ${this.point.additionalData.stackSize}`
            } else if (this.series.name === 'MidPrice') {
              return `<b>Mid Price</b><br>${formattedDate}<br>${this.y}`
            } else if (this.series.name === 'OfferSpread') {
              return `<b>Offer Spread</b><br>${formattedDate}<br>Best: ${this.point.additionalData.size} @ ${this.y}<br>Stack: ${this.point.additionalData.count} / ${this.point.additionalData.stackSize}`
            } else if (this.series.name === 'BidSpread') {
              return `<b>Bid Spread</b><br>${formattedDate}<br>Best: ${this.point.additionalData.size} @ ${this.y}<br>Stack: ${this.point.additionalData.count} / ${this.point.additionalData.stackSize}`
            } else if (this.series.name === 'MidSpread') {
              return `<b>Mid Spread</b><br>${formattedDate}<br>${this.y}`
            } else {
              return `<b>${this.series.name}</b><br>${formattedDate}<br>${this.point.additionalData.size} @ ${this.y}`
            }
          } else {
            return `<b>${this.series.name}</b><br>${formattedDate}<br>${this.y}`
          }
        }
      },
      series: chartData,
      time: {
        useUTC: false
      }
    }
    setOpts(test)
  }, [chartData])

  return chartData ? (
    <div>
      <HighchartsReact highcharts={Highcharts} options={opts} />
    </div>
  ) : (
    <div></div>
  )
}
export default HcLine
