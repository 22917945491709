import React, { useMemo } from 'react'
import { CellProps, useSecurity7ChordData } from './helpers'
import { Security7ChordData } from '../../../store/securities/reducer'
import dayjs from 'dayjs'
import styles from '../bondListStyle.scss'
type FormatOptions = Partial<Intl.NumberFormatOptions>

const ChordCells =
  <P extends keyof Security7ChordData>(property: P, sub?: string) =>
  ({ data }: CellProps) => {
    const security = useSecurity7ChordData(data)

    if (!security) return <> </>

    const value =
      sub && typeof security[property] === 'object'
        ? security[property][sub]
        : security[property]

    const classes = useMemo(() => {
      if (sub?.includes('Signal')) {
        return value > 0 ? styles.positive : value < 0 ? styles.negative : ''
      }
      return ''
    }, [sub, value])

    const getOptions = useMemo(() => {
      let options: FormatOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
      }
      if (property === 'topExpertPrediction_7Chord') {
        options = { ...options, maximumFractionDigits: 3 } as const
      }
      if (sub?.includes('Signal')) {
        options = {
          ...options,
          style: 'percent',
          maximumFractionDigits: 3
        } as const
      }
      return options
    }, [sub, value])

    const formatter = new Intl.NumberFormat('en-US', getOptions)

    return isNaN(value) ? (
      dayjs(value).format('MM/DD/YY hh:mm a')
    ) : (
      <div className={classes}>{formatter.format(value)}</div>
    )
  }

export default ChordCells
