import React, { ComponentType } from 'react'
import styles from './HeaderButton.scss'

import cx from 'classnames'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SpanProps = React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>

export interface Props extends SpanProps {
  active: boolean
  toggle: () => void
  Icon?: ComponentType<{ fill: string }>
  faIcon?: IconProp
  dataFor?: string
  className?: string
}

const HeaderButton = ({
  active,
  toggle,
  Icon,
  faIcon,
  dataFor,
  ...props
}: Props) => {
  return (
    <React.Fragment>
      <span
        {...props}
        className={cx(
          props.className,
          styles.button,
          styles.unbounded,
          active && styles.active
        )}
        onClick={toggle}
        data-for={dataFor}
      >
        {faIcon && <FontAwesomeIcon icon={faIcon} className={cx({ active })} />}

        {Icon && <Icon fill={active ? '#FFFFFF' : '#656C78'} />}
      </span>
    </React.Fragment>
  )
}

export default HeaderButton
