import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

const getState = (state: { [namespace]: State }) => state[namespace]

export const getMaxGrids = createSelector(
  [getState],
  (state) => state.settings.maxGrids
)

export const showTsySetting = createSelector(
  [getState],
  (state) => state.settings.showTsy
)

export const showTOBSetting = createSelector(
  [getState],
  (state) => state.settings.showTOB
)

export const getIcebergThreshold = createSelector(
  [getState],
  (state) => state.settings.icebergThreshold
)

export const getWebSettingsLoaded = createSelector(
  [getState],
  (state) => !state.settings.isLoading
)

export const getCanSeeSystemParamEditor = createSelector(
  [getState],
  (state) => state.userPermissions.canSeeSystemParamEditor
)

export const getCanEditTiers = createSelector(
  [getState],
  (state) => state.userPermissions.canEditTiers
)

export const getCanMarkRestricted = createSelector(
  [getState],
  (state) => state.userPermissions.canMarkRestricted
)

export const getCanForceCancel = createSelector(
  [getState],
  (state) => state.userPermissions.canForceCancel
)

export const getIsAdmin = createSelector(
  [getState],
  (state) => state.userPermissions.isAdmin
)

export const getHasTradingRights = createSelector(
  [getState],
  (state) => state.userPermissions.hasTradingRights
)
