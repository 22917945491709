import { createSelector } from 'reselect'
import { namespace } from '.'
import {
  formatDate,
  formatResubmitDate,
  LastDay
} from '../../components/DropdownUpload/helpers'
import { Security } from '../securities/reducer'
import {
  getDisplayedSecurityIds,
  getSecurityOrderDataById
} from '../securities/selectors'
import { StagedOrder } from '../stagedOrders/types'
import { State } from './reducer'
import { Order, OrderType } from './types'
interface OrderToImport {
  [date: string]: {
    [securityId: number]: {
      sell: {
        price: number
        size: number
        spread: number
        isSpreadOrder: boolean
        allOrNone: boolean
      }
      buy: {
        price: number
        size: number
        spread: number
        isSpreadOrder: boolean
        allOrNone: boolean
      }
    }
  }
}

const byDateDesc = (o1: Order, o2: Order) =>
  o2.submitTime.valueOf() - o1.submitTime.valueOf()

const getState = (state: any): State => state[namespace]

export const getUserOrders = createSelector([getState], (state) => {
  const retval = []
  for (const o in state.userOrders) {
    if (state.userOrders.hasOwnProperty(o)) {
      retval.push(state.userOrders[o])
    }
  }
  return retval
})

export const getOperatorOrders = createSelector([getState], (state) => {
  const retval = []
  for (const o in state.operatorOrders) {
    if (state.operatorOrders.hasOwnProperty(o)) {
      retval.push(state.operatorOrders[o])
    }
  }
  return retval
})

export const getUserOrdersRecord = createSelector(
  [getState],
  (state) => state.userOrders
)

export const getAcceptedOrders = createSelector(
  [getState],
  (state) => state.acceptedOrders
)

export const getOperatorOrdersRecord = createSelector(
  [getState],
  (state) => state.operatorOrders
)

export const getRejectedOrders = createSelector(
  [getState],
  (state) => state.rejectedOrders
)

export const getCancelledOrders = createSelector(
  [getState],
  (state) => state.cancelledOrders
)

export const getWaitingForConfirmationOrders = createSelector(
  [getState],
  (state) => state.waitingForConfirmationOrders
)

export const getUserOrdersBySecurityId = createSelector(
  [getState],
  (state) => state.userOrdersBySecurityId
)

export const getFakeTransactionIdMap = createSelector(
  [getState],
  (state) => state.fakeTransactionIdMap
)

export const getUserOrdersByTransactionId = createSelector(
  [getState],
  (state) => state.userOrdersByTransactionId
)

export const getAggressErrors = createSelector(
  [getState],
  (state) => (transactionId: number) => state.hitLiftErrors[transactionId]
)

export const getAllUserOrders = createSelector(
  [getUserOrders, getAcceptedOrders, getRejectedOrders, getCancelledOrders],
  (userOrders, acceptedOrders, rejectedOrders, cancelledOrders) => {
    const retval = []
    for (const o in userOrders) {
      if (userOrders.hasOwnProperty(o)) {
        retval.push(userOrders[o])
      }
    }
    for (const o in acceptedOrders) {
      if (acceptedOrders.hasOwnProperty(o)) {
        retval.push(acceptedOrders[o])
      }
    }
    for (const o in cancelledOrders) {
      if (cancelledOrders.hasOwnProperty(o)) {
        retval.push(cancelledOrders[o])
      }
    }
    for (const o in rejectedOrders) {
      if (rejectedOrders.hasOwnProperty(o)) {
        retval.push(rejectedOrders[o])
      }
    }
    return retval
  }
)

export const getActivitiesOrders = createSelector(
  [
    getAcceptedOrders,
    getRejectedOrders,
    getCancelledOrders,
    getWaitingForConfirmationOrders
  ],
  (
    acceptedOrders,
    rejectedOrders,
    cancelledOrders,
    waitingForConfirmationOrders
  ) => {
    const retval = []
    for (const o in acceptedOrders) {
      if (acceptedOrders.hasOwnProperty(o)) {
        retval.push(acceptedOrders[o])
      }
    }
    for (const o in cancelledOrders) {
      if (cancelledOrders.hasOwnProperty(o)) {
        retval.push(cancelledOrders[o])
      }
    }
    for (const o in rejectedOrders) {
      if (rejectedOrders.hasOwnProperty(o)) {
        retval.push(rejectedOrders[o])
      }
    }
    for (const o in waitingForConfirmationOrders) {
      if (waitingForConfirmationOrders.hasOwnProperty(o)) {
        retval.push(waitingForConfirmationOrders[o])
      }
    }
    return retval
  }
)

export const getOrderById = createSelector(
  [
    getUserOrdersRecord,
    getAcceptedOrders,
    getRejectedOrders,
    getCancelledOrders,
    getWaitingForConfirmationOrders
  ],
  (
      userOrders,
      acceptedOrders,
      rejectedOrders,
      cancelledOrders,
      waitingForConfirmationOrders
    ) =>
    (orderId: Order['id']) =>
      userOrders.hasOwnProperty(orderId)
        ? userOrders[orderId]
        : acceptedOrders.hasOwnProperty(orderId)
        ? acceptedOrders[orderId]
        : rejectedOrders.hasOwnProperty(orderId)
        ? rejectedOrders[orderId]
        : cancelledOrders.hasOwnProperty(orderId)
        ? cancelledOrders[orderId]
        : waitingForConfirmationOrders.hasOwnProperty(orderId)
        ? waitingForConfirmationOrders[orderId]
        : undefined
)

export const getUserOrOperatorOrderById = createSelector(
  [
    getUserOrdersRecord,
    getAcceptedOrders,
    getRejectedOrders,
    getCancelledOrders,
    getWaitingForConfirmationOrders,
    getOperatorOrdersRecord
  ],
  (
      userOrders,
      acceptedOrders,
      rejectedOrders,
      cancelledOrders,
      waitingForConfirmationOrders,
      operatorOrders
    ) =>
    (orderId: Order['id']) =>
      userOrders.hasOwnProperty(orderId)
        ? userOrders[orderId]
        : acceptedOrders.hasOwnProperty(orderId)
        ? acceptedOrders[orderId]
        : rejectedOrders.hasOwnProperty(orderId)
        ? rejectedOrders[orderId]
        : cancelledOrders.hasOwnProperty(orderId)
        ? cancelledOrders[orderId]
        : waitingForConfirmationOrders.hasOwnProperty(orderId)
        ? waitingForConfirmationOrders[orderId]
        : operatorOrders.hasOwnProperty(orderId)
        ? operatorOrders[orderId]
        : undefined
)

export const getAggressorOrderByInitialOrder = createSelector(
  [getUserOrders],
  (userOrders) => (initialOrderId: string) =>
    [...userOrders]
      .sort(byDateDesc)
      .find((o) => o.initialOrder?.id === initialOrderId)
)

export const getOrderByTransactionId = createSelector(
  [getUserOrdersByTransactionId, getFakeTransactionIdMap],
  (userOrdersByTransactionId, fakeTransactionIdMap) =>
    (transactionId: number) => {
      if (fakeTransactionIdMap.hasOwnProperty(transactionId)) {
        const realTransactionId = fakeTransactionIdMap[transactionId]
        return userOrdersByTransactionId.hasOwnProperty(realTransactionId)
          ? userOrdersByTransactionId[realTransactionId]
          : undefined
      } else {
        return userOrdersByTransactionId.hasOwnProperty(transactionId)
          ? userOrdersByTransactionId[transactionId]
          : undefined
      }
    }
)

export const getInitialOrder = createSelector(
  [getOrderById],
  (getOrder) => (orderId: string) => getOrder(orderId)?.initialOrder
)

export const getOrderBySecurityAndType = createSelector(
  getUserOrdersBySecurityId,
  (orders) => (securityId: number, type: OrderType) => {
    return orders.hasOwnProperty(securityId)
      ? orders[securityId].sort(byDateDesc).find((order) => order.type === type)
      : undefined
  }
)

export const getPendingUserOrderForSecurity = createSelector(
  [getUserOrdersBySecurityId],
  (userOrders) => (securityId: number, orderType: OrderType) => {
    return userOrders.hasOwnProperty(securityId)
      ? userOrders[securityId].find(
          (order) => order.status === 'pending' && order.type === orderType
        )
      : undefined
  }
)

export const getErrorForOrder = createSelector(
  [getState, getOrderBySecurityAndType],
  (state, getOrder) => (securityId: Security['id'], orderType: OrderType) => {
    const ord = getOrder(securityId, orderType)
    const status = ord?.status === 'error' && 'server_error'
    const validation = state.validations.find(
      (v) => v.securityId === securityId && v.orderType === orderType
    )?.error
    return status || validation
  }
)

export const getValidationResultForOrder = createSelector(
  [getState, getOrderBySecurityAndType],
  (state, getOrder) => (securityId: Security['id'], orderType: OrderType) =>
    (getOrder(securityId, orderType)?.status === 'error' && 'server_error') ||
    state.validations.find(
      (validation) =>
        validation.securityId === securityId &&
        validation.orderType === orderType
    )
)

export const getSelectedValidation = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.selectedValidation
)

export const getValidationOpen = createSelector(
  [getState],
  (state) => (gridIndex: number) => state.validationOpen
)

export const getOrderValidations = createSelector(
  [getState],
  (state) => state.validations
)

export type ValidationsType = ReturnType<typeof getOrderValidations>

export const getLastErrorDateForDisplayedOrders = createSelector(
  [getDisplayedSecurityIds, getState],
  (displayedSecurities, state) => (gridIndex: number) =>
    [...state.validations]
      .sort((v1, v2) => v2.date.valueOf() - v1.date.valueOf())
      .find((validation) =>
        displayedSecurities(gridIndex).includes(validation.securityId)
      )?.date
)
export const getErrorsForDate = createSelector(
  [getState],
  (state) => (gridIndex: number, d: Date | undefined) =>
    d === undefined ? [] : state.validations.filter((v) => v.date === d)
)

export const isMyOrdersOpen = createSelector(
  [getState],
  (state) => (gridIndex: number) => Boolean(state.myOrdersIsOpen[gridIndex])
)

export const getOrdersFetched = createSelector(
  [getState],
  (state) => state.ordersFetched
)

export const getUserOrdersToAddStagedOrdersByDatePicker = createSelector(
  [getUserOrders],
  (userOrders) =>
    [...userOrders]
      .sort((a, b) => a.submitTime.getTime() - b.submitTime.getTime())
      .reduce((acc, currentUserOrder) => {
        const date = currentUserOrder.submitTime.toDateString()
        return {
          ...acc,
          [date]: {
            ...acc[date],
            [currentUserOrder.securityId]: {
              ...acc[date]?.[currentUserOrder.securityId],
              [currentUserOrder.type]: {
                price: currentUserOrder.price,
                size: currentUserOrder.size,
                spread: currentUserOrder.spread,
                isSpreadOrder: currentUserOrder.isSpreadOrder,
                allOrNone: currentUserOrder.allOrNone
              }
            }
          }
        }
      }, {} as OrderToImport)
)

export const getResubmitOrders = createSelector(
  [getState],
  (state) => state.resubmitOrders
)

export const isOrderMine = createSelector(
  [getUserOrders],
  (orders) => (orderId: Order['id']) => orders.some((o) => o.id === orderId)
)

export const isBestOrderMine = createSelector(
  [isOrderMine, getSecurityOrderDataById],
  (orderIsMine, getSecurity) =>
    (securityId: Security['id'], orderType: OrderType) => {
      const security = getSecurity(securityId)
      if (!security) return false
      const order = orderType === 'buy' ? security.bestBid : security.bestOffer
      if (!order) return false
      return orderIsMine(order.id)
    }
)

export const getXLastDayAndOrdersAssociate = createSelector(
  [getResubmitOrders],
  (resubmitOrders) => (numberOfDays: number) => {
    const result: LastDay[] = []
    const date = new Date()
    let i = 0
    while (result.length < numberOfDays) {
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        const currentDate = new Date(date)
        const formattedDate = formatResubmitDate(currentDate)
        const getOrderByDate = resubmitOrders
          ? resubmitOrders.hasOwnProperty(formattedDate)
            ? resubmitOrders[formattedDate]
            : {}
          : {}
        const stagedOrders: StagedOrder[] = []

        if (getOrderByDate) {
          for (const secId in getOrderByDate) {
            if (getOrderByDate.hasOwnProperty(secId)) {
              const bidAndOffer = getOrderByDate[secId]
              for (const side in bidAndOffer) {
                if (bidAndOffer.hasOwnProperty(side)) {
                  const sideAsOrderType = side as OrderType
                  const ord = bidAndOffer[sideAsOrderType]
                  stagedOrders.push({
                    size: ord.size,
                    price: ord.price,
                    orderType: ord.type,
                    securityId: ord.securityId,
                    spread: ord.spread,
                    isSpreadOrder: ord.isSpreadOrder,
                    allOrNone: ord.allOrNone,
                    individualMin: ord.individualMin,
                    custId: 0,
                    tob: ord.tob
                  })
                }
              }
            }
          }
        }

        result.push({
          id: i,
          isSelected: false,
          dateFormat: formatDate(date),
          stagedOrders
        })
        i++
      }
      date.setDate(date.getDate() - 1)
    }

    return result
  }
)
