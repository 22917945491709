import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import React, { useCallback } from 'react'
import {
  forceCancel,
  markAsRestricted
} from '../../../store/depthOfMarket/actions'
import { Order } from '../../../store/order/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCanForceCancel,
  getCanMarkRestricted
} from '../../../store/webSettings/selectors'

import styles from './Admintools.scss'

interface Props {
  order: Order
}

const AdminTools = ({ order }: Props) => {
  const dispatch = useDispatch()
  const canMarkRestricted = useSelector(getCanMarkRestricted)
  const canForceCancel = useSelector(getCanForceCancel)
  const isExternal = order?.id.startsWith('E')
  const isPending = order?.status === 'pending'

  const showRestrict = canMarkRestricted && isExternal && isPending
  const showForceCancel = canForceCancel && isExternal

  const rootId = `${order.securityId}-${order.type}-`
  const cancelId = rootId + 'cancel'
  const restrictId = rootId + 'restrict'

  const restrictOrder = useCallback(() => {
    dispatch(markAsRestricted(order.id))
  }, [order.id])
  const cancelOrder = useCallback(() => {
    dispatch(forceCancel(order.id))
  }, [order.id])

  return (
    <td>
      <div className={styles.adminCell}>
        <span className={styles.displayName} title={order.displayName ?? ''}>
          {order.displayName}
        </span>
        {showForceCancel ? (
          <span
            data-testid={cancelId}
            onClick={cancelOrder}
            className="pointer"
            title="Force Canncel"
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        ) : (
          <></>
        )}
        {showRestrict ? (
          <span
            data-testid={restrictId}
            onClick={restrictOrder}
            title="Mark Restricted"
            className="pointer"
          >
            <FontAwesomeIcon icon={faBan} />
          </span>
        ) : (
          <></>
        )}
      </div>
    </td>
  )
}

export default AdminTools
