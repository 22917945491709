import {
  faCircle,
  faCircleXmark,
  faExclamationTriangle
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'
import { objectsAreEqual } from '../../../helpers/utils'
import { OrderEntryModel } from '../../../store/depthOfMarket/types'
import { OrderType } from '../../../store/order/types'
import ComponentFooter from '../../ComponentFooter/ComponentFooter'
import {
  capitalize,
  createTob,
  getIsTob,
  isTobParam,
  orderTypeToDisplayName,
  showTitle
} from '../helpers'
import ErrorDialog from './ErrorDialog'

import styles from './OrderEntry.scss'

interface Props {
  order: OrderEntryModel
  className?: string
  rowId: string | number
  onSubmit: (order: OrderEntryModel) => string | undefined
  onCancel: (orderId: string | undefined) => void
  showPegged: boolean
  serverError?: string
  icebergThreshold?: number
}

// ------------------ visual ------------------ //

const CancelText = (
  <span className={styles.cancel}>
    <FontAwesomeIcon icon={faCircleXmark} /> Cancel
  </span>
)

const switchContainerClass = 'pretty p-switch p-fill'

// ------------------ utility fns ------------------ //

const createInputId = (
  type: OrderType,
  rowId: string | number,
  field: string
) => `${rowId}-${type}-${field}`

const getAmountLabel = (amountType: OrderEntryModel['amountType']) =>
  `${capitalize(amountType)}`
const getAmountAbbr = (amountType: OrderEntryModel['amountType']) =>
  amountType === 'price' ? amountType : 'spr'
function isAmountValid(amount: OrderEntryModel['amount']) {
  if (isTobParam(amount)) return amount.floorPrice && amount.limitPrice
  return !!amount
}

// -------------------- reducer -------------------- //
type ReducerCanEdit = Concrete<Omit<OrderEntryModel, 'id' | 'type' | 'isLive'>>
type OrderEntryActions = UpdateActions<ReducerCanEdit>

const createActionForProperty = <Prop extends keyof ReducerCanEdit>(
  prop: Prop,
  payload: ReducerCanEdit[Prop]
) => {
  return {
    type: `update_${prop}` as const,
    payload
  } as const
}

const createCommitTotalSize = () =>
  ({
    type: 'commit_totalSize'
  } as const)

type CommitTotalSizeAction = ReturnType<typeof createCommitTotalSize>

const createCommitDisplaySize = () =>
  ({
    type: 'commit_displaySize'
  } as const)

type CommitDisplaySizeAction = ReturnType<typeof createCommitDisplaySize>

const initOrderModel = (order: OrderEntryModel) => {
  return {
    ...order,
    isChanged: false
  }
}

const adjustSizeFromAon = (
  aon: boolean,
  order: WorkingOrder,
  icebergMinQty: number
) => {
  const totalSize = parseInt(order.totalSize, 10)
  if (!totalSize) {
    return { ...order, totalSize: '', minSize: '', displaySize: '', aon }
  }
  const displaySize = parseInt(order.displaySize ?? '', 10)
  if (aon) {
    if (
      !displaySize ||
      totalSize < icebergMinQty ||
      (order.totalSize.toLowerCase().endsWith('a') && !order.isLive)
    ) {
      return {
        ...order,
        totalSize: `${totalSize}a`,
        minSize: `${totalSize}`,
        displaySize: '',
        aon
      }
    } else {
      return {
        ...order,
        totalSize: `${totalSize}`,
        displaySize: `${displaySize}a`,
        minSize: `${displaySize}`,
        aon
      }
    }
  } else {
    return {
      ...order,
      totalSize: `${totalSize}`,
      displaySize: `${displaySize || ''}`,
      aon
    }
  }
}
type WorkingOrder = ReturnType<typeof initOrderModel>

type EditOrderActions =
  | OrderEntryActions
  | CommitTotalSizeAction
  | CommitDisplaySizeAction
  | InitAction<OrderEntryModel>

const createOrderEntryReducer =
  (icebergMinQty: number) =>
  (order: WorkingOrder, action: EditOrderActions): WorkingOrder => {
    const newOrder = { ...order, isChanged: true }
    switch (action.type) {
      case 'update_totalSize':
        return { ...newOrder, totalSize: action.payload }
      case 'commit_totalSize': {
        if (parseInt(order.totalSize, 10) < icebergMinQty) {
          newOrder.displaySize = ''
        }
        const aon = order.isLive
          ? order.aon
          : order.totalSize !== 'a' &&
            (order.totalSize.toLowerCase().endsWith('a') || order.aon)
        return adjustSizeFromAon(aon, newOrder, icebergMinQty)
      }
      case 'update_aon': {
        const aon = action.payload
        return adjustSizeFromAon(aon, newOrder, icebergMinQty)
      }
      case 'update_amountType':
        if (typeof newOrder.amount === 'string') {
          newOrder.amount = ''
        } else {
          newOrder.amount = createTob()
        }
      // intentionally falls through
      case 'update_amount':
      case 'update_selectedBook':
      case 'update_minSize': {
        const [_ignore, field] = action.type.split('_')
        return { ...newOrder, [field]: action.payload }
      }
      case 'update_displaySize': {
        return {
          ...newOrder,
          displaySize: action.payload,
          totalSize: parseInt(action.payload, 10)
            ? newOrder.totalSize.replace('a', '')
            : newOrder.totalSize
        }
      }
      case 'commit_displaySize': {
        const aon =
          !order.isLive &&
          order.displaySize !== 'a' &&
          (order.displaySize?.toLowerCase().endsWith('a') || order.aon)
        return adjustSizeFromAon(aon, newOrder, icebergMinQty)
      }

      case 'init':
        return initOrderModel(action.payload)
    }
    return order
  }

// ------------------ end reducer ------------------ //

const OrderEntry = ({
  order,
  className,
  rowId,
  onSubmit,
  onCancel,
  showPegged,
  serverError,
  icebergThreshold = 500
}: Props) => {
  const [workingOrder, localDispatch] = useReducer(
    createOrderEntryReducer(icebergThreshold),
    order,
    initOrderModel
  )

  const thisRef = useRef<HTMLDivElement>(null)

  const title = `Enter ${orderTypeToDisplayName(workingOrder.type)}`
  const orderType = orderTypeToDisplayName(workingOrder.type)
  const submitLabel = `Submit ${orderType}`
  const isSpread = workingOrder.amountType === 'spread'
  const isAllOrNone = workingOrder.aon
  const isTob = getIsTob(workingOrder.amount)
  const amountLabel = getAmountLabel(workingOrder.amountType)
  const amountIsValid = isAmountValid(workingOrder.amount)

  const aonLabel =
    parseInt(workingOrder.displaySize, 10) > 0
      ? 'All or none (of shown)'
      : 'All or none (of total)'

  const [error, setError] = useState('')
  const [enableDisplaySize, setEnableDisplaySize] = useState(
    parseInt(order.totalSize, 10) >= icebergThreshold && !order.isLive
  )

  useEffect(() => {
    const { isChanged, ...orderPart } = workingOrder
    if (!objectsAreEqual(order, orderPart) && !isChanged) {
      localDispatch({
        type: 'init',
        payload: order
      } as const)
      setEnableDisplaySize(
        parseInt(order.totalSize, 10) >= icebergThreshold && !order.isLive
      )
    }
    // workingOrder deliberately not part of dependencies,
    // bc we only want to do this when order changes, not workingOrder
  }, [order])

  useEffect(() => {
    if (serverError) {
      setError(`Server error: ${serverError}`)
    } else {
      setError('')
    }
  }, [serverError])

  const handleAmountTypeClicked = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const field = 'amountType'
      const value = e.currentTarget.checked ? 'spread' : 'price'
      localDispatch(createActionForProperty(field, value))
    },
    []
  )

  // so that the Price label acts on the switch as the Spread label does
  const handlePriceLabelClicked = useCallback(() => {
    localDispatch(createActionForProperty('amountType', 'price'))
  }, [])

  const handleSizeFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const field = e.currentTarget.name as unknown as 'totalSize' | 'minSize'
      const value = e.currentTarget.value
      localDispatch(createActionForProperty(field, value))
    },
    []
  )

  const commitTotalSize = useCallback(() => {
    const commitTotalSizeAction = createCommitTotalSize()
    localDispatch(commitTotalSizeAction)
    const nextWorkingOrder = createOrderEntryReducer(icebergThreshold)(
      workingOrder,
      commitTotalSizeAction
    )
    setEnableDisplaySize(
      parseInt(nextWorkingOrder.totalSize, 10) >= icebergThreshold &&
        !workingOrder.isLive
    )
  }, [workingOrder])

  const handleTotalSizeBlur = useCallback(() => {
    if (workingOrder.isChanged) {
      commitTotalSize()
    }
  }, [commitTotalSize])

  const handleDisplaySizeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      localDispatch(createActionForProperty('displaySize', value))
    },
    []
  )
  const handleDisplaySizeBlur = useCallback(() => {
    if (workingOrder.isChanged) {
      localDispatch(createCommitDisplaySize())
    }
  }, [workingOrder])
  const handleAonChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    localDispatch(createActionForProperty('aon', e.currentTarget.checked))
  }, [])

  const toggleTob = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.checked ? createTob() : ''
    localDispatch(createActionForProperty('amount', value))
  }, [])

  const handleAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (typeof workingOrder.amount === 'string') {
        localDispatch(createActionForProperty('amount', e.currentTarget.value))
        return
      }
      // pegged/TOB
      const tobProp =
        e.target.name === 'limitPrice' ? 'limitPrice' : 'floorPrice'
      localDispatch(
        createActionForProperty('amount', {
          ...workingOrder.amount,
          [tobProp]: e.currentTarget.value.length
            ? Number(e.currentTarget.value)
            : null
        })
      )
    },
    [workingOrder]
  )

  const submitOrder = useCallback(
    (submittedOrder: WorkingOrder) => {
      const { isChanged, ...updatedOrder } = submittedOrder
      const err = onSubmit({ ...updatedOrder, minSize: '' }) // remove minSize override to start sending minSize again
      if (err) {
        setError(err)
      } else {
        localDispatch({
          type: 'init',
          payload: updatedOrder
        } as const)
      }
    },
    [onSubmit]
  )

  const handleSubmit = useCallback(() => {
    submitOrder(workingOrder)
  }, [workingOrder, submitOrder])

  const handleCancel = useCallback(() => {
    onCancel(workingOrder.id)
    reset()
  }, [workingOrder])

  const reset = useCallback(() => {
    setError('')
    localDispatch({
      type: 'init',
      payload: order
    } as const)
  }, [order])

  const clearError = useCallback(() => {
    setError('')
  }, [])

  const showServerError = useCallback(() => {
    setError(serverError ?? '')
  }, [serverError])

  const maybeSubmit = useCallback(() => {
    if (amountIsValid) {
      handleSubmit()
    }
  }, [amountIsValid, workingOrder])

  const generalKeyUpHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'Enter') {
        maybeSubmit()
      }
    },
    [maybeSubmit]
  )

  // ------------------ fix focus when display size shown ------------------ //
  const [needsRefocus, setNeedsRefocus] = useState(false)
  const displaySizeRef = useRef<HTMLInputElement>(null)
  const totalSizeTabHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Tab':
          const value = e.currentTarget?.value
          if (parseInt(value, 10) >= icebergThreshold) {
            // display size is probably not currently enabled, but needs to get focus
            setNeedsRefocus(true)
          }
          break
        case 'Enter':
          // prepare model for submit
          commitTotalSize()
          break
      }
    },
    [commitTotalSize]
  )
  useEffect(() => {
    if (needsRefocus && enableDisplaySize) {
      displaySizeRef.current?.focus()
    }
  }, [needsRefocus, enableDisplaySize])
  const onDisplaySizeFocus = useCallback(() => {
    setNeedsRefocus(false)
  }, [])
  const onDisplaySizeKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        localDispatch(createCommitDisplaySize())
      }
    },
    []
  )

  if (error) {
    return (
      <ErrorDialog className={styles.error} message={error} onOk={clearError} />
    )
  }

  return (
    <div
      ref={thisRef}
      className={cx(
        className,
        styles.orderEntry,
        workingOrder.isLive && styles.isLive
      )}
    >
      <header>
        <p>
          {workingOrder.isLive ? (
            <FontAwesomeIcon icon={faCircle} fade size="xs" />
          ) : (
            <></>
          )}
          {serverError && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              onClick={showServerError}
            />
          )}
          {title}
        </p>
        <div>
          <div className={styles.amountType}>
            <label
              className={cx({ [styles.selectedAmountType]: !isSpread })}
              onClick={isSpread ? handlePriceLabelClicked : undefined}
            >
              Price
            </label>
            <div className={cx(switchContainerClass)}>
              <input
                type="checkbox"
                name="amountType"
                id={createInputId(order.type, rowId, 'isSpread')}
                data-testid={createInputId(order.type, rowId, 'isSpread')}
                checked={isSpread}
                disabled={workingOrder.isLive}
                onChange={handleAmountTypeClicked}
                onKeyUp={generalKeyUpHandler}
              />
              <div className="state">
                <label
                  htmlFor={createInputId(order.type, rowId, 'isSpread')}
                  className={cx({ [styles.selectedAmountType]: isSpread })}
                >
                  Spread
                </label>
              </div>
            </div>
          </div>
          {showPegged ? (
            <div className={cx(switchContainerClass, styles.controlGroup)}>
              <input
                type="checkbox"
                name="isTob"
                id={createInputId(order.type, rowId, 'isTob')}
                checked={isTob}
                onChange={toggleTob}
                onKeyUp={generalKeyUpHandler}
                disabled={workingOrder.isLive}
              />
              <div className="state">
                <label htmlFor={createInputId(order.type, rowId, 'isTob')}>
                  Pegged
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </header>
      <div className={styles.column}>
        <div className={cx(styles.controlGroup, styles.alignInputs)}>
          <label htmlFor={createInputId(order.type, rowId, 'totalSize')}>
            Total size:
          </label>
          <input
            id={createInputId(order.type, rowId, 'totalSize')}
            name="totalSize"
            value={workingOrder.totalSize}
            onChange={handleSizeFieldChange}
            onBlur={handleTotalSizeBlur}
            onKeyDown={totalSizeTabHandler}
            onKeyUp={generalKeyUpHandler}
            type="text"
            autoComplete={'off'}
          />
        </div>
        <div
          className={cx(
            styles.controlGroup,
            styles.alignInputs,
            enableDisplaySize ? '' : styles.disabled
          )}
          title={showTitle}
        >
          <label htmlFor={createInputId(order.type, rowId, 'displaySize')}>
            Show sz:
          </label>
          <input
            ref={displaySizeRef}
            id={createInputId(order.type, rowId, 'displaySize')}
            name="displaySize"
            value={workingOrder.displaySize ?? ''}
            onChange={handleDisplaySizeChange}
            onBlur={handleDisplaySizeBlur}
            onFocus={onDisplaySizeFocus}
            onKeyDown={onDisplaySizeKeyDown}
            onKeyUp={generalKeyUpHandler}
            disabled={!enableDisplaySize}
            type="text"
            autoComplete={'off'}
            placeholder={!enableDisplaySize ? ' ---' : ''}
          />
        </div>
      </div>
      <div className={cx(switchContainerClass, styles.controlGroup)}>
        <input
          type="checkbox"
          name="aon"
          id={createInputId(order.type, rowId, 'aon')}
          data-testid={createInputId(order.type, rowId, 'aon')}
          checked={isAllOrNone}
          onChange={handleAonChange}
          onKeyUp={generalKeyUpHandler}
          disabled={workingOrder.isLive}
          tabIndex={-1}
        />
        <div className="state">
          <label htmlFor={createInputId(order.type, rowId, 'aon')}>
            {aonLabel}
          </label>
        </div>
      </div>
      <div className={cx(styles.column, styles.amounts)}>
        {isTobParam(workingOrder.amount) ? (
          <>
            <div className={cx(styles.controlGroup, styles.alignInputs)}>
              <label htmlFor={createInputId(order.type, rowId, 'limitPrice')}>
                {`Limit ${getAmountAbbr(workingOrder.amountType)}`}
              </label>
              <input
                type="number"
                id={createInputId(order.type, rowId, 'limitPrice')}
                name="limitPrice"
                value={`${workingOrder.amount.limitPrice}`}
                onChange={handleAmountChange}
                onKeyUp={generalKeyUpHandler}
                autoComplete="new-password"
              />
            </div>
            <div className={cx(styles.controlGroup, styles.alignInputs)}>
              <label htmlFor={createInputId(order.type, rowId, 'floorPrice')}>
                {`Starting ${getAmountAbbr(workingOrder.amountType)}`}
              </label>
              <input
                type="number"
                id={createInputId(order.type, rowId, 'floorPrice')}
                name="floorPrice"
                value={`${workingOrder.amount.floorPrice}`}
                onChange={handleAmountChange}
                onKeyUp={generalKeyUpHandler}
                autoComplete="off"
              />
            </div>
          </>
        ) : (
          <div className={cx(styles.controlGroup, styles.alignInputs)}>
            <label htmlFor={createInputId(order.type, rowId, 'amount')}>
              {amountLabel}
            </label>
            <input
              type="text"
              id={createInputId(order.type, rowId, 'amount')}
              value={workingOrder.amount}
              onChange={handleAmountChange}
              onKeyUp={generalKeyUpHandler}
              autoComplete="off"
            />
          </div>
        )}
      </div>
      <ComponentFooter
        onCancelClick={handleCancel}
        onApplyClick={maybeSubmit}
        cancelText={workingOrder.isLive ? CancelText : 'Cancel'}
        submitText={submitLabel}
        disableSubmit={!amountIsValid}
        disableCancel={!workingOrder.isLive}
        applyId={createInputId(workingOrder.type, rowId, 'submit')}
      />
    </div>
  )
}

export default OrderEntry
