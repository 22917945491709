import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { concat, EMPTY, from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { createStagedOrdersFromUploadRow } from '../../components/Upload/helpers'
import { getHub } from '../../helpers/hub'
import { createTempCheckedOrders } from '../checkedOrders/actions'
import { defaultFilter } from '../filter/types'
import { splitCallByChunksAndAggregateResult } from '../helpers'
import { setMyOrdersOpen } from '../order/actions'
import { setIsMine, setWatchListId } from '../securities/actions'
import { Security } from '../securities/reducer'
import { addOrUpdateStagedOrders } from '../stagedOrders/actions'
import { StagedOrder } from '../stagedOrders/types'
import {
  addSecuritiesToWatchlist,
  FetchWatchListsSuccessAction
} from '../watchList/actions'
import { getResponseForPermission } from '../watchList/helpers'
import { getDetailsForCurrentWatchlist } from '../watchList/selectors'
import { logError } from '../ws/actions'
import {
  CreateNewWatchlistWithIdentifiersAction,
  createNewWatchlistWithSecurityIds,
  CreateNewWatchlistWithSecurityIdsAction,
  createWatchlistFromWatchlistInfo,
  CreateWatchlistFromWatchlistInfoAction,
  CreateWatchlistWithEnhancedUploadRowsAction,
  resetNewWatchlistTransactionId,
  setNewWatchlistTransactionId,
  toggleDropdownState,
  UploadEnhancedOrderRowsAction,
  UploadOrdersAction,
  uploadOrdersFailure,
  UploadOrdersFromPreviousDate,
  uploadOrdersServerError,
  uploadOrdersToNewWatchlist,
  UploadOrdersToNewWatchlistAction
} from './actions'
import {
  areLevelsEmpty,
  getFailures,
  getOrdersToStage,
  getSecuritiesToAdd
} from './helpers'
import { getNewWatchlistTransactionId } from './selectors'
import { DropdownState, ErrorInfo, OrdersWithSecurityIds } from './types'

const GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE = 1000

const getSecurityIdsFromIdentifier$ = (identifiers: string[]) =>
  splitCallByChunksAndAggregateResult(
    identifiers,
    GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE,
    (identifiersChunk) =>
      getHub().invoke<Array<Array<Security['id']>>>(
        'GetSecurityIds',
        identifiersChunk
      )
  )

const uploadOrdersEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('upload.uploadOrders'),
    mergeMap((action: UploadOrdersAction) => {
      const { gridIndex, identifiers, orders, book } = action.payload
      const watchlistDetails = getDetailsForCurrentWatchlist(state$.value)(
        gridIndex
      )
      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        map((securityIdsArray) =>
          securityIdsArray.map((securityIds, i) => ({
            orderInfo: orders[i],
            securityIds
          }))
        ),
        mergeMap((ordersWithSecurityIds: OrdersWithSecurityIds) => {
          const failures = getFailures(ordersWithSecurityIds)
          const securitiesToAdd = watchlistDetails?.id
            ? getSecuritiesToAdd(ordersWithSecurityIds)
            : []
          const ordersToStage = getOrdersToStage(ordersWithSecurityIds, book.id)
          if (watchlistDetails?.id && securitiesToAdd.length !== 0) {
            if (failures.length > 0) {
              if (ordersToStage.length > 0) {
                return of(
                  uploadOrdersFailure(failures),
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropdownState(gridIndex, 'upload'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  uploadOrdersFailure(failures),
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  toggleDropdownState(gridIndex, 'upload')
                )
              }
            } else {
              if (ordersToStage.length > 0) {
                return of(
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropdownState(gridIndex, 'closed'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  addSecuritiesToWatchlist(
                    gridIndex,
                    watchlistDetails.id,
                    securitiesToAdd,
                    identifiers
                  ),
                  toggleDropdownState(gridIndex, 'closed')
                )
              }
            }
          } else {
            if (failures.length > 0) {
              if (ordersToStage.length > 0) {
                return of(
                  uploadOrdersFailure(failures),
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropdownState(gridIndex, 'upload'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(
                  uploadOrdersFailure(failures),
                  toggleDropdownState(gridIndex, 'upload')
                )
              }
            } else {
              if (ordersToStage.length > 0) {
                return of(
                  addOrUpdateStagedOrders(ordersToStage, true),
                  toggleDropdownState(gridIndex, 'closed'),
                  setIsMine(gridIndex, true)
                )
              } else {
                return of(toggleDropdownState(gridIndex, 'closed'))
              }
            }
          }
        })
      )
    }),
    catchError((err) => of(logError(err)))
  )

const createNewWatchlistWithIdentifiersEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createNewWatchlistWithIdentifiers'),
    mergeMap((action: CreateNewWatchlistWithIdentifiersAction) => {
      const {
        gridIndex,
        name,
        identifiers,
        permission,
        book,
        orders,
        filter,
        myFirmChecked,
        useSizeChecked,
        size,
        useAdvancedFilter
      } = action.payload

      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        map((securityIdsArray) =>
          securityIdsArray.filter(
            (securityIds, i) =>
              !(securityIds.length > 0 && !areLevelsEmpty(orders[i])) ||
              securityIds.length === 1
          )
        ),
        mergeMap((secIds) =>
          of(
            createNewWatchlistWithSecurityIds(
              gridIndex,
              name,
              permission,
              book,
              orders,
              secIds,
              identifiers,
              filter,
              myFirmChecked,
              useSizeChecked,
              size,
              useAdvancedFilter
            )
          )
        )
      )
    })
  )

const createNewWatchlistWithSecurityIdsEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createNewWatchlistWithSecurityIds'),
    mergeMap((action: CreateNewWatchlistWithSecurityIdsAction) => {
      const {
        gridIndex,
        name,
        permission,
        book,
        orders,
        securityIds,
        identifiers,
        filter,
        myFirmChecked,
        useSizeChecked,
        size,
        useAdvancedFilter
      } = action.payload
      const ordersWithSecurityIds = orders.reduce(
        (acc, order, i) => [
          ...acc,
          { orderInfo: order, securityIds: securityIds[i] }
        ],
        []
      )

      return getHub()
        .invoke(
          'CreateWatchlist',
          name,
          identifiers,
          permission,
          book.name,
          filter,
          myFirmChecked,
          useSizeChecked,
          size,
          useAdvancedFilter
        )
        .pipe(
          mergeMap((id: number) =>
            of(
              setNewWatchlistTransactionId(gridIndex, id),
              uploadOrdersToNewWatchlist(
                gridIndex,
                ordersWithSecurityIds,
                book.id
              ),
              setWatchListId(gridIndex, id)
            )
          ),
          catchError((err) => of(uploadOrdersServerError(`${err.message}`)))
        )
    })
  )

const uploadOrdersToNewWatchlistEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadOrdersToNewWatchlist'),
    mergeMap((action: UploadOrdersToNewWatchlistAction) => {
      const { gridIndex, securityIds, bookId } = action.payload
      const failures = getFailures(securityIds)
      const ordersToStage = getOrdersToStage(securityIds, bookId)

      if (failures.length > 0) {
        if (ordersToStage.length > 0) {
          return of(
            uploadOrdersFailure(failures),
            addOrUpdateStagedOrders(ordersToStage, true),
            toggleDropdownState(gridIndex, 'upload'),
            setIsMine(gridIndex, true)
          )
        } else {
          return of(
            uploadOrdersFailure(failures),
            toggleDropdownState(gridIndex, 'upload')
          )
        }
      } else {
        if (ordersToStage.length > 0) {
          return of(
            addOrUpdateStagedOrders(ordersToStage, true),
            toggleDropdownState(gridIndex, 'closed'),
            setIsMine(gridIndex, true)
          )
        } else {
          return of(toggleDropdownState(gridIndex, 'closed'))
        }
      }
    }),
    catchError((err) => of(logError(err)))
  )

const setCreatedWatchlistAsCurrentWatchlistEpic: Epic<Action> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType<FetchWatchListsSuccessAction>('watchList.fetchWatchListsSuccess'),
    mergeMap((action) => {
      const { gridIndex, watchlists } = action.payload
      const { transactionId, id: watchlistId } = watchlists[0]

      const newWatchlistTransactionId = getNewWatchlistTransactionId(
        state$.value
      )(gridIndex)

      if (newWatchlistTransactionId === transactionId) {
        return of(
          setWatchListId(gridIndex, watchlistId),
          resetNewWatchlistTransactionId(gridIndex)
        )
      }

      return EMPTY
    })
  )

const uploadOrdersFromPreviousDateEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadOrdersFromPreviousDate'),
    mergeMap((action: UploadOrdersFromPreviousDate) => {
      const stagedOrders = action.payload
      return concat(
        of(addOrUpdateStagedOrders(stagedOrders, true)),
        of(
          createTempCheckedOrders(
            0,
            stagedOrders.map((stagedOrder) => ({
              securityId: stagedOrder.securityId,
              orderType: stagedOrder.orderType
            }))
          )
        )
      )
    })
  )

// ------------------ enhanced orders ------------------ //
const prepareWatchlistForUploadEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createWatchlistWithEnhancedUploadRows'),
    mergeMap((action: CreateWatchlistWithEnhancedUploadRowsAction) => {
      const { watchlist, gridIndex, book } = action.payload
      const watchlistInfo = {
        gridIndex,
        name: watchlist.name,
        permission: getResponseForPermission(
          watchlist.permission === 'Private' ? 'private' : 'myFirm'
        ),
        book,
        filter: defaultFilter,
        myFirmChecked: false,
        useSizeChecked: false,
        size: 200,
        useAdvancedFilter: false
      } as const
      const identifiers: string[] = watchlist.rows.map(
        (row) => row.identifier || ''
      )
      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        mergeMap((secIds) => {
          const create = createWatchlistFromWatchlistInfo(
            watchlistInfo,
            identifiers
          )
          const ordersByRow: StagedOrder[][] = []
          const errors: ErrorInfo[] = []
          secIds.forEach((ids, i) => {
            if (ids.length === 1) {
              ordersByRow.push(
                createStagedOrdersFromUploadRow(
                  ids[0],
                  watchlist.rows[i],
                  book?.id
                )
              )
            }
            if (!ids.length) {
              errors.push({
                index: i,
                identifier: identifiers[i],
                errorIndex: i
              })
            }
          })

          const toggleState: DropdownState = errors.length ? 'upload' : 'closed'
          const toggle = toggleDropdownState(gridIndex, toggleState)
          if (errors.length) {
            return of(uploadOrdersFailure(errors))
          }
          const successActions: Action[] = [create, toggle]
          const orders = ordersByRow.flat()
          if (orders.length) {
            successActions.push(
              addOrUpdateStagedOrders(orders, true),
              setIsMine(gridIndex, true),
              setMyOrdersOpen(gridIndex, true),
              createTempCheckedOrders(
                0,
                orders.map(({ securityId, orderType }) => ({
                  securityId,
                  orderType
                }))
              )
            )
          }
          return from(successActions)
        })
      )
    })
  )

const createWatchlistFromWatchlistInfoEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.createWatchlistFromWatchlistInfo'),
    mergeMap((action: CreateWatchlistFromWatchlistInfoAction) => {
      const {
        name,
        permission,
        book,
        filter,
        myFirmChecked,
        useSizeChecked,
        useAdvancedFilter,
        size,
        gridIndex
      } = action.payload.watchlist
      return getHub()
        .invoke(
          'CreateWatchlist',
          name,
          action.payload.identifiers,
          permission,
          book?.name ?? '',
          filter,
          myFirmChecked,
          useSizeChecked,
          size,
          useAdvancedFilter
        )
        .pipe(
          mergeMap((transactionId: number) =>
            of(setNewWatchlistTransactionId(gridIndex, transactionId))
          ),
          catchError((err) => of(uploadOrdersServerError(`${err.message}`)))
        )
    })
  )

const uploadEnhancedOrderRowsEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('upload.uploadEnhancedOrderRows'),
    mergeMap((action: UploadEnhancedOrderRowsAction) => {
      const { gridIndex, watchlist, rows, bookId } = action.payload
      const identifiers = rows.map((row) => row.identifier || '')
      return getSecurityIdsFromIdentifier$(identifiers).pipe(
        mergeMap((secIds) => {
          const ordersByRow: StagedOrder[][] = []
          const actions: Action[] = []
          const securitiesToAdd: number[] = []
          const identifiersToAdd: string[] = []
          const errors: ErrorInfo[] = []
          secIds.forEach((ids, i) => {
            if (ids.length === 1) {
              ordersByRow.push(
                createStagedOrdersFromUploadRow(ids[0], rows[i], bookId)
              )
            }
            if (ids.length) {
              let added = false
              ids.forEach((id) => {
                // if (!watchlist.securityIds.includes(id)) {
                securitiesToAdd.push(id)
                added = true
                // }
              })
              if (added) {
                identifiersToAdd.push(rows[i].identifier || '')
              }
            } else {
              errors.push({
                index: i,
                identifier: identifiers[i],
                errorIndex: i
              })
            }
          })
          if (errors.length) {
            return of(
              uploadOrdersFailure(errors),
              toggleDropdownState(0, 'upload')
            )
          }
          if (securitiesToAdd.length) {
            actions.push(
              addSecuritiesToWatchlist(
                gridIndex,
                watchlist.id!,
                securitiesToAdd,
                identifiersToAdd
              )
            )
          }
          const orders = ordersByRow.flat()
          if (orders.length) {
            actions.push(
              addOrUpdateStagedOrders(orders),
              setIsMine(gridIndex, true),
              setMyOrdersOpen(gridIndex, true),
              createTempCheckedOrders(
                0,
                orders.map(({ securityId, orderType }) => ({
                  securityId,
                  orderType
                }))
              )
            )
          }
          actions.push(toggleDropdownState(gridIndex, 'closed'))
          return actions
        }),
        catchError((err) => of(uploadOrdersServerError(`${err.message}`)))
      )
    })
  )

export default combineEpics(
  uploadOrdersEpic,
  createNewWatchlistWithIdentifiersEpic,
  setCreatedWatchlistAsCurrentWatchlistEpic,
  uploadOrdersFromPreviousDateEpic,
  createNewWatchlistWithSecurityIdsEpic,
  uploadOrdersToNewWatchlistEpic,
  prepareWatchlistForUploadEpic,
  createWatchlistFromWatchlistInfoEpic,
  uploadEnhancedOrderRowsEpic
)
