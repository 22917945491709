import {
  faAnglesDown,
  faAnglesUp
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'

interface Props {
  isExpanded: boolean
}

const ExpandableComponentIcon: FC<Props> = ({ isExpanded }) => {
  return <FontAwesomeIcon icon={!isExpanded ? faAnglesDown : faAnglesUp} />
}

export default ExpandableComponentIcon
