import { faExternalLinkAlt } from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { useOpenFin } from '../../../app/openFinContext'
import { popoutSecurity } from '../../../store/depthOfMarket/actions'

export const PopoutDepthIcon = ({ securityId }: { securityId: number }) => {
  const { fin, manageWindows } = useOpenFin()
  const dispatch = useDispatch()
  const onPopout = useCallback(() => {
    dispatch(popoutSecurity(securityId))
    if (fin) {
      manageWindows(`Depth-${securityId}`, 'restore')
    }
  }, [securityId])

  return (
    <span>
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        onClick={onPopout}
        color={'#656c78'}
        data-tip="Popout Depth for this Security"
        data-for="popoutDepth"
      />
      <ReactTooltip
        id="popoutDepth"
        backgroundColor="#e9edf1"
        textColor="#656C78"
        effect="solid"
        className="iconTooltip"
      >
        Popout Depth for this Security
      </ReactTooltip>
    </span>
  )
}
