import {
  faCirclePlay,
  faCircleX
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelPassiveOrder,
  createOrderFromPassiveOrder
} from '../../../store/passiveOrders/actions'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import styles from '../PassiveOrders.scss'

const PriceSpreadCell = (props: any) => {
  const dispatch = useDispatch()
  const priceOrSpread = props.colDef.colId
  const orderId = props.data.id
  const isSpread = props.data.isSpread
  const admin = useSelector(getIsAdmin)
  const isMine = props.data.isMine
  const showIcon = () => {
    if (props.data.eligibleForResubmit && (isMine || admin)) {
      if (
        (isSpread && priceOrSpread === 'spread') ||
        (!isSpread && priceOrSpread === 'price')
      ) {
        return (
          <FontAwesomeIcon
            icon={faCirclePlay}
            style={{ cursor: 'pointer', color: 'green' }}
            onClick={() => dispatch(createOrderFromPassiveOrder(orderId))}
          />
        )
      }
    } else if (props.data.isLive && (isMine || admin)) {
      if (
        (props.data.isSpread && priceOrSpread === 'spread') ||
        (!props.data.isSpread && priceOrSpread === 'price')
      ) {
        return (
          <FontAwesomeIcon
            icon={faCircleX}
            style={{ cursor: 'pointer', color: 'red' }}
            onClick={() => dispatch(cancelPassiveOrder(`O${orderId}`))}
          />
        )
      }
    }
    return <></>
  }

  return (
    <>
      {props.value}
      <span className={styles.actionIcon}>{showIcon()}</span>
    </>
  )
}

PriceSpreadCell.displayName = 'PriceSpreadCell'

export default PriceSpreadCell
