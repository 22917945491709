import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import cx from 'classnames'
import React, { FC, useCallback, useRef } from 'react'
import { noop } from 'rxjs'
import { useDropDownMenu } from '../../helpers/hooks'
import Checkbox from '../Checkbox'
import DropdownList from '../DropdownList/DropdownList'
import styles from './GenericDropdownMenu.scss'

export interface Props {
  selectedItem: string
  setSelectedItem(st: string): void
  options: string[]
  selectId: string
  openRight: boolean
  className: string
  placeholder?: string
  dropDownClassname?: string
  labelClassName?: string
}
interface ListItemProps {
  id: string
  label: string
  selected: boolean
  toggleSelected: () => void
}
const ListItem: FC<ListItemProps> = ({
  id,
  label,
  selected,
  toggleSelected
}) => {
  return (
    <div className={styles.listItem}>
      <Checkbox
        locator={`column-${id}`}
        checked={selected}
        onChange={toggleSelected}
      >
        {label}
      </Checkbox>
    </div>
  )
}
const GenericDropdownMenu: FC<Props> = ({
  selectedItem,
  setSelectedItem,
  options,
  selectId,
  openRight,
  className,
  placeholder,
  dropDownClassname,
  labelClassName
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { displayMenu, setDisplayMenu } = useDropDownMenu(ref)
  const isItemSelected = (item: string) => selectedItem === item
  const toggleSelected = useCallback(
    (item: string) => () => {
      setSelectedItem(item)
      setDisplayMenu(false)
    },
    [selectedItem]
  )
  const showOrHideDropdown = useCallback(() => {
    setDisplayMenu((value) => !value)
  }, [selectedItem])
  return (
    <div ref={ref} className={cx(styles.dropdown, className)}>
      <div className={styles.labelContainer} onClick={showOrHideDropdown}>
        <label className={cx(labelClassName)}>
          {selectedItem ? selectedItem : placeholder}
        </label>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {displayMenu && (
        <div>
          <div
            className={openRight ? styles.menuRightSingle : styles.menuSingle}
          >
            <DropdownList
              classOverride={dropDownClassname}
              onElementSelected={noop}
              elements={options.map((name) => ({
                id: name,
                label: (
                  <ListItem
                    id={name}
                    label={name}
                    selected={isItemSelected(name)}
                    toggleSelected={toggleSelected(name)}
                  />
                )
              }))}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default GenericDropdownMenu
