import moment from 'moment'
import React, { FC } from 'react'
import { ManagementReport } from '../../../store/admin/managementReports/types'
import styles from './ManagementReports.scss'

interface Props {
  managementReport: ManagementReport
}

const ManagementReportComponent: FC<Props> = (props: Props) => {
  const managementReport = props.managementReport
  const formattedDate = moment
    .parseZone(managementReport.reportDate)
    .format('DD-MMM-yyyy')
  const topWebUsers = managementReport.topWebTraders.map((u, index) => (
    <tr key={index}>
      <td>
        {u.customerName}-&gt;{u.userName}
      </td>
      <td>{u.tradeCount}</td>
    </tr>
  ))
  const topWebMarketMakers = managementReport.topWebMarketMakers.map(
    (u, index) => (
      <tr key={index}>
        <td>
          {u.customerName}-&gt;{u.userName}
        </td>
        <td>{u.orderCount}</td>
      </tr>
    )
  )
  const allCustomers = managementReport.allUsers?.map((u, i) => (
    <div key={i}>{u}</div>
  ))
  return (
    <div className={styles.ManagementReports}>
      <div className={styles.ManagementReportHeader}>
        Key Stats {formattedDate}
      </div>
      <table>
        <tbody>
          <tr>
            <td>Web Maker Orders</td>
            <td>{managementReport.webMakerOrders}</td>
          </tr>
          <tr>
            <td>Web TradeTickets (1 side of trade)</td>
            <td>{managementReport.webTradeTickets}</td>
          </tr>
          <tr>
            <td>API TradeTickets (1 side of trade)</td>
            <td>{managementReport.apiTradeTickets}</td>
          </tr>
          <tr>
            <td>Citrix Tickets (1 side of trade)</td>
            <td>{managementReport.citrixTickets}</td>
          </tr>
          <tr>
            <td>All TradeTickets (2 per trade)</td>
            <td>{managementReport.allTradeTickets}</td>
          </tr>
          <tr>
            <td>Unique Web Users</td>
            <td>{managementReport.uniqueWebUsers}</td>
          </tr>
          <tr>
            <td>Unique Citrix Users</td>
            <td>
              {managementReport.totalCitrixUsers} BONDSCOM:{' '}
              {managementReport.bondsComCitrixUsers} / Customers:{' '}
              {managementReport.customerCitrixUsers}
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <label className={styles.ManagementReportHeader}>
          Top 20 Web Users by Trade Count
        </label>
        <table>
          <tbody>{topWebUsers}</tbody>
        </table>
      </div>
      <div>
        <label className={styles.ManagementReportHeader}>
          Top 20 Web Market Maker Users
        </label>
        <table>
          <tbody>{topWebMarketMakers}</tbody>
        </table>
      </div>
      <div>
        <label className={styles.ManagementReportHeader}>All Users</label>
        {allCustomers}
      </div>
    </div>
  )
}

export default ManagementReportComponent
