import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useClickAway } from 'react-use'
import searchIcon from '../../../assets/images/search.svg'
import SecuritySummary from '../SecuritySummary'
import { SecurityStaticData } from '../../../store/securities/reducer'
import {
  getSelectedUser,
  getUserFromUserId
} from '../../../store/users/selectors'
import { getIsAdmin } from '../../../store/webSettings/selectors'
import DepthOfMarketTitleBondsSearch from './DepthOfMarketTitleBondsSearch'

import styles from './Header.scss'

interface Props {
  security: SecurityStaticData
}

const PopoutDepthHeaderView = ({
  security,
  children
}: PropsWithChildren<Props>) => {
  const [searching, setSearching] = useState(false)
  const showSearch = useCallback(() => {
    setSearching(true)
  }, [])
  const hideSearch = useCallback(() => {
    setSearching(false)
  }, [])

  const myself = useRef<HTMLDivElement>(null)

  useClickAway(myself, hideSearch)

  return (
    <>
      <div ref={myself} className={styles.popoutDepthHeader}>
        {searching ? (
          <DepthOfMarketTitleBondsSearch
            gridIndex={0}
            previousId={security.id}
          />
        ) : (
          <>
            <img
              src={searchIcon}
              onClick={showSearch}
              alt="search for other bonds"
            />
            <SecuritySummary security={security} instance="popout" />
          </>
        )}
        {children}
      </div>
      {/* This is a temporary fix for the search not closing when clicking outside of it in openfin. */}
      {searching && <div onClick={hideSearch} className={styles.searching} />}
    </>
  )
}

const PopoutDepthHeader = ({ security }: { security: SecurityStaticData }) => {
  const selUserId = useSelector(getSelectedUser)
  const selectedUser = useSelector(getUserFromUserId)(selUserId)
  const isAdmin = useSelector(getIsAdmin)
  const selectedAdminUser =
    isAdmin && selectedUser
      ? `${selectedUser?.custName}: ${selectedUser?.userName}`
      : ''

  return (
    <PopoutDepthHeaderView security={security}>
      {selectedAdminUser}
    </PopoutDepthHeaderView>
  )
}

export default PopoutDepthHeader
