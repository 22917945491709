import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import gridStyles from '../../../assets/scss/grid.scss'
import { systemParamFetch } from '../../../store/admin/sysparam/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import SystemParametersGrid from './Grid/SystemParametersGrid'
import SystemParameterModal from './SystemParameterModal'
import styles from './SystemParameters.scss'

const SystemParameters: FC = () => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<number>(0)
  const [displayModal, toggleModal] = useState(false)
  const fetchParameters = useCallback(() => {
    dispatch(systemParamFetch())
  }, [])
  useEffect(() => {
    fetchParameters()
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.id)
  }

  const handleShowModal = useCallback(
    (row: number) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <React.Fragment>
      <div className={gridStyles.outerGridContainer}>
        <ComponentHeader title="System Parameters" />
        <div className={styles.sysParamsButtons}>
          <button onClick={() => handleShowModal(0)}>New</button>
          <button onClick={fetchParameters}>Refresh</button>
        </div>
        <SystemParametersGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <SystemParameterModal
          paramId={selectedRow}
          displayModal={displayModal}
          closeFunc={() => handleShowModal(0)}
        />
      )}
    </React.Fragment>
  )
}

export default SystemParameters
