import {
  faWindowMinimize,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useCallback } from 'react'
import { useOpenFin } from '../../app/openFinContext'
import styles from './ComponentHeader.scss'

export interface Props {
  title: string
  customControls?: React.ReactNode // additional custom header functionality
  customClose?: () => void // custom close button function
  children?: React.ReactNode
}

const ComponentHeader: FC<Props> = ({
  title,
  customControls,
  customClose,
  children
}) => {
  const { fin, manageWindows } = useOpenFin()
  const openfinTitle =
    title === 'My Orders' ? 'PassiveOrders' : title.replace(/\s/g, '')

  const finMinimize = useCallback(() => {
    return (
      <div
        className={styles.icon}
        onClick={() => manageWindows(openfinTitle, 'minimize')}
        title="Minimize"
      >
        <FontAwesomeIcon icon={faWindowMinimize} />
      </div>
    )
  }, [fin])
  return (
    <header className={styles.header}>
      <label className={styles.title}>{children ?? title}</label>
      <div className={styles.iconContainer}>
        {fin && finMinimize()}
        {customControls}
        {(fin || customClose) && (
          <div
            className={styles.icon}
            onClick={() =>
              (customClose ?? (() => manageWindows(openfinTitle, 'close')))()
            }
            title="Close"
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>
    </header>
  )
}

export default ComponentHeader
