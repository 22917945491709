import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { revertTier } from '../../../store/admin/tiers/actions'
import { TierList } from '../../../store/admin/tiers/types'
import { getCustomerById } from '../../../store/customers/selectors'
import { getUserFromUserId } from '../../../store/users/selectors'
import ComponentFooter from '../../ComponentFooter/ComponentFooter'
import modalStyles from '../../GenericModal/modal.scss'

interface Props {
  tier: TierList
  handleClose: () => void
  isOpen: boolean
}

const RevertTiersModal: FC<Props> = ({ tier, handleClose, isOpen }) => {
  const dispatch = useDispatch()
  const revert = () => {
    dispatch(revertTier(tier.id))
    handleClose()
  }

  const customer = useSelector(getCustomerById)(tier?.custId)
  const user = useSelector(getUserFromUserId)(tier?.userId)

  return (
    <ReactModal
      className={modalStyles.wrapperModal}
      overlayClassName={modalStyles.overlay}
      isOpen={isOpen}
      shouldReturnFocusAfterClose={false}
    >
      <div className={modalStyles.confirmContent}>
        Are you sure you want to revert to live tier for{' '}
        {customer ? customer.shortName : user?.userName}?
      </div>
      <ComponentFooter
        onCancelClick={handleClose}
        onApplyClick={revert}
        submitText="Yes"
      />
    </ReactModal>
  )
}

export default RevertTiersModal
