import moment from 'moment'
import React from 'react'

export const formatCoupon = (coupon: number) =>
  Math.round(coupon * 10000) / 10000

export const formatMaturityDateAsText = (
  date: Date,
  withLeadingZero = true
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }

  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  return `${`${withLeadingZero ? '0' : ''}${month}`.substr(-2, 2)}/${
    year % 100
  }`
}

export const formatMaturityDateAsHTML = (
  date: Date,
  withLeadingZero = true
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }

  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  const displayedMonth =
    !withLeadingZero || month >= 10
      ? month
      : `<span class="normalGray">0</span>${month}`

  return `${displayedMonth}/${year % 100}`
}

export const formatMaturityDate = (
  date: Date,
  className: 'normal' | 'normalGray'
) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }
  // Using toISOString to be timezone-agnostic
  const [year, month] = date.toISOString().split('-').map(Number)

  const formatMonth =
    month < 10 ? (
      <>
        <span className={className}>0</span>
        {month}
      </>
    ) : (
      month
    )

  return (
    <>
      {formatMonth}/{year % 100}
    </>
  )
}

export const fullMonth = [
  'Jan',
  'Febr',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const formatMaturityFullDate = (date: Date) => {
  if (Number.isNaN(date.valueOf())) {
    // Invalid date
    return ''
  }
  return `${date.getDate()}-${fullMonth[date.getMonth()]}-${date.getFullYear()}`
}

export const formatSecondtimer = (timer: number) => {
  if (Math.floor(timer % 60) < 10 && Math.floor(timer % 60) > 0) {
    return `0${Math.floor(timer % 60)}`
  } else if (Math.floor(timer % 60) === 0) {
    return '00'
  } else {
    return Math.floor(timer % 60)
  }
}

export const secondsToMinutes = (time: number) =>
  Math.floor(time / 60) + ':' + formatSecondtimer(time)

export const ConvertUSTPrice = (priceStr: string) => {
  let ustPrice = 0
  const endsWithPlus = priceStr.endsWith('+')
  const arr = endsWithPlus
    ? priceStr.substring(0, priceStr.length - 1).split('-')
    : priceStr.split('-')
  if (arr.length === 2) {
    const dollarsStr = arr[0]
    const dollars = Number(dollarsStr)
    const centsStr = arr[1]
    const cents = Number(centsStr)

    let thirtySeconds: number
    let twoHundredFiftySixths: number
    if (!isNaN(dollars) && !isNaN(cents)) {
      if (endsWithPlus) {
        if (centsStr.length === 2) {
          thirtySeconds = Number(centsStr)
          ustPrice = dollars + thirtySeconds / 32 + 4 / 256
        }
      } else {
        if (centsStr.length === 2) {
          thirtySeconds = Number(centsStr)
          ustPrice = dollars + thirtySeconds / 32
        } else if (centsStr.length === 3) {
          thirtySeconds = Number(centsStr.substring(0, 2))
          twoHundredFiftySixths = Number(centsStr.substring(2, 3))
          ustPrice = dollars + thirtySeconds / 32 + twoHundredFiftySixths / 256
        }
      }
    }
  }
  return ustPrice
}

export const formatTreasuryPrice = (price: number) => {
  const truncated = Math.trunc(price)
  const fraction = price - truncated
  const number256ths = Math.trunc(fraction * 256)
  const number32nds = Math.trunc(fraction * 32)
  const remainder = number256ths - number32nds * 8
  let stRemainder = remainder.toString()

  if (stRemainder === '4') stRemainder = '+'
  else if (stRemainder === '0') stRemainder = ''

  return (
    truncated.toString() +
    '-' +
    number32nds.toString().padStart(2, '0') +
    stRemainder
  )
}

const currencyMap: Record<string, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£'
}

export const formatCurrency = (
  value: string | number | undefined,
  currencyAbbr: string = ''
) => {
  const symbol = value ? currencyMap[currencyAbbr] ?? '' : ''
  return `${symbol}${value || ''}`
}

export const formatAggressorLLPrice = (
  price: number,
  currency: string,
  isTreasury: boolean
) => {
  let tsyPrice
  if (price && isTreasury) {
    tsyPrice = formatTreasuryPrice(price)
  }
  return formatCurrency(tsyPrice ?? price, currency)
}

export const highlight = (
  search: string,
  text: string,
  highlightFn = (str: string) => <strong>{str}</strong>
) =>
  text.split(new RegExp(search, 'i')).reduce(
    (acc, part, index) => (
      <>
        {acc}
        {index > 0 && highlightFn(search.toUpperCase())}
        {part}
      </>
    ),
    <></>
  )

export const abbreviateVolume = (value: number) => {
  let newValue = Intl.NumberFormat('en-US', {
    notation: 'compact'
  }).format(value)

  newValue = newValue.replace('M', 'mm')
  return newValue
}

const isNumeric = (val: string): boolean => {
  return !isNaN(Number(val))
}

export const dateFormatter2 = (params: any) => {
  const date = moment(params.value)
  if (date.year() < 1900) {
    return ''
  }
  return date.format('M/DD/YY, h:mm a')
}

export const dateFormatterIgnoreNumber = (params: any) => {
  if (isNumeric(params.value)) {
    return params.value
  }
  return moment(params.value).format('M/DD/YY, h:mm a')
}

export const dateFormatter = (params: any) => {
  return moment(params.value).format('M/DD/YY, h:mm a')
}

export const dateFormatterUserData = (params: any) => {
  if (isNumeric(params.value)) {
    return params.value
  }
  const mom = moment(params.value)
  if (mom.year() < 2000) {
    return '-'
  }
  return mom.format('MMM-DD-YYYY HH:mm')
}

export const diffDateFormatter = (params: any) => {
  if (isNumeric(params.value)) {
    return params.value
  }
  const orderTime = moment(params.value)
  const diff = moment().diff(orderTime, 'days')

  if (diff > 0) {
    return orderTime.format('MMMM Do')
  } else {
    return orderTime.format('HH:mm:ss')
  }
}

export const formatSize = (
  size: number,
  totalSize: number,
  aon: boolean,
  isMine: boolean | string | undefined
) => {
  if (!size) return '-'

  const sizeAon = size + (aon ? 'a' : '')
  if (size === totalSize) return sizeAon
  return size && isMine ? `${sizeAon} (${totalSize})` : `${sizeAon}`
}

export const defaultColumnDefinitions = {
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  menuTabs: ['filterMenuTab' as const],
  filterParams: {
    buttons: ['clear']
  },
  cellStyle: { border: 'var(--border)', borderWidth: '0 1px' }
}
