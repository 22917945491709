const homeUrl = `http://localhost:55555`;
window.config = {
  api: {
    url: `${homeUrl}/mts`,
    reconnectDelay: 5000,
    refreshTokenTimeout: 25000,
    serverTimeoutInMilliseconds: 60000
  },
  okta: {
    issuer: 'https://bondspro-dev.oktapreview.com/oauth2/default',
    redirectUri: 'http://localhost:1234/implicit/callback',
    clientId: '0oaizsvf5pILTFgkF1d6',
    deactivate: false
  },
  env: 'LOCAL',
  sentry: {
    baseProps: {
      enableTracing: false
    }
  }
};
