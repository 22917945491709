import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'
import styles from '../../../assets/scss/grid.scss'
import {
  activityTickerFetch,
  unsubscribeFromTicker
} from '../../../store/admin/activityTicker/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import ActivityTickerGrid from './Grid/ActivityTickerGrid'

const ActivityTicker: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(activityTickerFetch())
    return () => {
      dispatch(unsubscribeFromTicker())
    }
  }, [])

  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader title="Activity Ticker" />
      <div className={cx(styles.gridDimensions, 'ag-theme-balham')}>
        <ActivityTickerGrid />
      </div>
    </div>
  )
}

export default ActivityTicker
