import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../../assets/scss/grid.scss'
import { getQueries } from '../../../store/admin/query/actions'
import { getClickhouseQueries } from '../../../store/admin/query/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import QueryList from './QueryList'

const QuerySelector: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getQueries())
  }, [])

  const clickhouseQueries = useSelector(getClickhouseQueries)
  return (
    <div className={styles.outerGridContainer}>
      {clickhouseQueries.length > 0 && (
        <>
          <ComponentHeader title="Query Selector" />
          <QueryList queries={clickhouseQueries} />
        </>
      )}
    </div>
  )
}

export default QuerySelector
