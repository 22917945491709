import React from 'react'
import { faSpinner } from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './loading.scss'

const Loading = () => (
  <div className={styles.loading}>
    <FontAwesomeIcon icon={faSpinner} spin /> Loading...
  </div>
)

export default Loading
