import cx from 'classnames'
import {
  faAnglesRight,
  faAnglesDown
} from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminTools from '../../components/DepthOfMarket/Depth/AdminTools'
import { PassiveDepthOfMarket } from '../../components/DepthOfMarket/PassiveDepthOfMarket'
import { IsInPopupContext } from '../popoutContext'
import ComponentHeader from '../../components/ComponentHeader/ComponentHeader'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getHasTradingRights } from '../../store/webSettings/selectors'
import { setWindowFront } from '../../store/windows/actions'
import PopoutDepthHeader from './Title/PopoutDepthHeader'

import styles from './depthPopout.scss'
import headerIconStyles from '../../components/ComponentHeader/ComponentHeader.scss'
import { useOpenFin } from '../../app/openFinContext'
import { closePopout } from '../../store/depthOfMarket/actions'

interface Props {
  security: SecurityStaticData
  gridIndex: number
  isAdmin: boolean
}

const adminExtraColumns = [AdminTools]

const PopoutDepthOfMarket = ({ security, gridIndex, isAdmin }: Props) => {
  const extraColumns = isAdmin ? adminExtraColumns : undefined
  const dispatch = useDispatch()
  const { fin } = useOpenFin()
  const hasTradingRights = useSelector(getHasTradingRights)
  const [showOrderEntry, setShowOrderEntry] = useState(hasTradingRights)

  const classes = cx(styles.depthOpenerPopout, {
    [styles.isFin]: !!fin,
    [styles.showOrderEntry]: showOrderEntry
  })

  const moveWindowFront = useCallback(() => {
    dispatch(setWindowFront('depth'))
  }, [])

  const orderEntryToggle = useMemo(() => {
    return (
      <div
        className={headerIconStyles.icon}
        onClick={() => setShowOrderEntry(!showOrderEntry)}
        title={`${showOrderEntry ? 'Hide' : 'Show'} Order Entry`}
      >
        <FontAwesomeIcon icon={showOrderEntry ? faAnglesRight : faAnglesDown} />
      </div>
    )
  }, [showOrderEntry])

  const closeDepth = useCallback(() => {
    dispatch(closePopout(security.id))
  }, [security.id])

  if (!security) {
    return <></>
  }

  return (
    <IsInPopupContext.Provider value={true}>
      <ComponentHeader
        title={`Depth/${security.id}`}
        customControls={hasTradingRights && orderEntryToggle}
        customClose={closeDepth}
      >
        <PopoutDepthHeader security={security} />
      </ComponentHeader>
      <div onClick={moveWindowFront} className={classes}>
        <PassiveDepthOfMarket
          security={security}
          gridIndex={gridIndex}
          isPopout={true}
          extraColumns={extraColumns}
          showOrderEntry={showOrderEntry}
        />
      </div>
    </IsInPopupContext.Provider>
  )
}

PopoutDepthOfMarket.displayName = 'PopoutDepthOfMarket'

export default PopoutDepthOfMarket
