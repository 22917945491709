import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { memo } from 'react'
import ReactModal from 'react-modal'

import { SecurityStaticData } from '../../../store/securities/reducer'
import './securityDetailsModal.scss'

interface Props {
  security: SecurityStaticData
  isOpen: boolean
  toggleIsOpen: () => void
}

const SecurityDetailsModal: React.FC<Props> = ({
  security,
  isOpen = false,
  toggleIsOpen
}) => {
  const maturityDate = moment(security.maturityDate).format('DD-MMM-YYYY')
  const issueDate = moment(security.issueDate).format('DD-MMM-YYYY')
  const datedDate = moment(security.datedDate).format('DD-MMM-YYYY')
  const firstCouponDate = moment(security.firstCouponDate).format('DD-MMM-YYYY')
  const nextCouponDate = moment(security.nextCouponDate).format('DD-MMM-YYYY')
  const benchmarkMaturityData = moment(security.benchmarkMaturity).format(
    'DD-MMM-YYYY'
  )

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => toggleIsOpen()}
      style={{
        content: {
          margin: '0 auto',
          bottom: '60px',
          width: '50%'
        }
      }}
    >
      <div className="d-flex flex-row justify-content-between securityDetailsHeader">
        <span className="d-inline">Security Details</span>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => toggleIsOpen()}
          className="d-inline"
          color="#77828b"
        />
      </div>
      <div className="securityDetailContent">
        <span className="benchmarkName">{security.description}</span>
        <div className="d-flex flex-row justify-content-between dividedContent">
          <div className="contentLabels text-right">
            <span>Issue Description:</span>
            <span>Board Label:</span>
            <span>Cusip:</span>
            <span>ISIN:</span>
            <span>Product:</span>
            <span>Industry Sector Label:</span>
            <span>Debt Type:</span>
            <span>Coupon:</span>
            <span>Maturity:</span>
            <span>Issue Date:</span>
            <span>Dated Date:</span>
            <span>First Coupon Date:</span>
            <span>Pay Frequency:</span>
            <span>Next Coupon Date:</span>
            <span>Day Count:</span>
            <span>Currency:</span>
            <span>Coupon Type:</span>
            <span>Term To Maturity:</span>
            <span>Minimum Piece:</span>
            <span>Minimum Increment:</span>
            <span>Original Amount Issued:</span>
            <span>Amount Outstanding:</span>
          </div>
          <div className="contentValues">
            <span className="benchmarkName">{security.description ?? '-'}</span>
            <span>{security.boardLabel ?? '-'}</span>
            <span>{security.cusip ? security.cusip : '-'}</span>
            <span>{security.isin ?? '-'}</span>
            <span>{security.product ?? '-'}</span>
            <span>{security.sector ?? '-'}</span>
            <span>
              {security.debtType.length !== 0 ? security.debtType : '-'}
            </span>
            <span>{security.coupon ?? '-'}</span>
            <span>{maturityDate ?? '-'}</span>
            <span>{issueDate ?? '-'}</span>
            <span>{datedDate ?? '-'}</span>
            <span>{firstCouponDate ?? '-'}</span>
            <span>{security.payFreq ?? '-'}</span>
            <span>{nextCouponDate ?? '-'}</span>
            <span>{security.dayCount ?? '-'}</span>
            <span>{security.currency ?? '-'}</span>
            <span>{security.couponType ?? '-'}</span>
            <span>{security.termToMat ?? '-'}</span>
            <span>{security.minimumPiece}</span>
            <span>{security.minimumSizeIncrement ?? '-'}</span>
            <span>
              {security.amountIssued
                ? security.amountIssued.toLocaleString()
                : '-'}
            </span>
            <span>
              {security.amountOutstanding
                ? security.amountOutstanding.toLocaleString()
                : '-'}
            </span>
          </div>
          <div className="d-flex flex-column contentHighlight">
            <div className="d-flex flex-column creditRatingsContainer">
              <span className="contentHighlightSectionLabel">
                Credit Ratings:
              </span>
              <div className="d-flex flex-row justify-content-between  contentHighlightContainer">
                <span className="contentHighlightLabel">S & P:</span>
                <span>{security.sAndPRating}</span>
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="contentHighlightSectionLabel">
                Benchmark (Hedge) Data:
              </span>
              <div className="d-flex flex-row justify-content-between contentHighlightContainer">
                <div className="d-flex flex-column contentHighlightLabel">
                  <span>{security.benchmarkCusip ? 'Cusip:' : 'ISIN:'}</span>
                  <span>Board Label:</span>
                  <span>Coupon:</span>
                  <span>Maturity:</span>
                </div>
                <div className="d-flex flex-column contentHighlightItem">
                  <span>
                    {security.benchmarkCusip
                      ? security.benchmarkCusip
                      : security.benchmarkISIN}
                  </span>
                  <span>{security.benchmarkSecurity}</span>
                  <span>{security.benchmarkCoupon}</span>
                  <span>{benchmarkMaturityData}</span>
                </div>
              </div>
            </div>
            {/* Call Schedule??? */}
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default memo(SecurityDetailsModal)
