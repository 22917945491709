import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  benchmarkDataFetch,
  unsubscribeFromBenchmark
} from '../../store/benchmarkData/actions'
import BenchmarkData from './BenchmarkData'

const BenchmarkContainer: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(benchmarkDataFetch())
    return () => {
      dispatch(unsubscribeFromBenchmark())
    }
  })

  return <BenchmarkData />
}

export default BenchmarkContainer
