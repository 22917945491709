import {
  faArrowUp,
  faBellPlus,
  faChalkboardUser,
  faChartSimpleHorizontal,
  faCircleInfo,
  faCookieBite,
  faDesktop,
  faEnvelope,
  faExclamationCircle,
  faGear,
  faList,
  faMemoCircleInfo,
  faPhone,
  faShieldCheck,
  faWavePulse
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setUser } from '@sentry/react'
import cx from 'classnames'
import { toggleNotificationCenter } from 'openfin-notifications'
import React, { FC, useCallback, useState } from 'react'

import { useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import SSIPDF from '../../../assets/pdfs/SSI.pdf'
import UserPDF from '../../../assets/pdfs/UserGuide.pdf'
import SettingsModal from '../../../components/SettingsModal/SettingsModal'
import { getOktaAuth } from '../../../store/auth/selectors'
import styles from './menus.scss'
export interface Props {
  closeMenu: () => void
}

const ClientMenu: FC<Props> = ({ closeMenu }) => {
  const { manageWindows, renderWindowCheck, toggleFrame } = useOpenFin()
  const [traderPrefs, setShowTraderPrefs] = useState(false)
  const auth = useSelector(getOktaAuth)

  // This is temporary, see todo below
  const handleTraderPrefs = useCallback(() => {
    setShowTraderPrefs(!traderPrefs)
  }, [traderPrefs])

  const handleSignout = useCallback(() => {
    toggleFrame(true)
    manageWindows(null, 'hide')
    auth?.signOut({ postLogoutRedirectUri: null })
    setUser(null)
  }, [auth])

  return (
    <>
      {traderPrefs && <SettingsModal closeFunc={handleTraderPrefs} />}
      <div className={styles.menuSection}>
        Menu
        <span onClick={closeMenu}>
          <FontAwesomeIcon icon={faTimes} title="Close Menu" />
        </span>
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('Benchmarks')}
        >
          <FontAwesomeIcon icon={faArrowUp} />
          <span>Show Benchmarks</span>
        </div>
        {/* TODO: data isnt coming back in openfin window */}
        <div className={styles.menuSection}>Settings</div>
        <div
          className={styles.dropdownButton}
          onClick={handleTraderPrefs}
          // onClick={() => renderWindowCheck('Settings')}
        >
          <FontAwesomeIcon icon={faGear} />
          <span>Trader Preferences</span>
        </div>

        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('AlertFilterEditor')}
        >
          <FontAwesomeIcon icon={faBellPlus} />
          <span>Alert Manager</span>
        </div>

        <div className={styles.menuSection}>Activity</div>
        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('ActivityBlotter')}
        >
          <FontAwesomeIcon icon={faChartSimpleHorizontal} />
          <span>My Trade Blotter</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('PassiveOrders')}
        >
          <FontAwesomeIcon icon={faList} />
          <span>My Orders</span>
        </div>
        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('ActivityLog')}
        >
          <FontAwesomeIcon icon={faWavePulse} />
          <span>My Activity Log</span>
        </div>
        <div
          className={styles.dropdownButton}
          /* eslint-disable*/
          onClick={() => toggleNotificationCenter()}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span>Notification Center</span>
        </div>
        <div className={styles.menuSection}>Help</div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faPhone} />
          <a href="tel:2122574062">(212) 257-4062</a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faEnvelope} />
          <a href="mailto:help@bonds.com">help@bonds.com</a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faChalkboardUser} />
          <a href={UserPDF} download="bonds.com_userGuide.pdf">
            Self Guided Tour PDF
          </a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faMemoCircleInfo} />
          <a href={SSIPDF} download="bonds.com_ssi.pdf">
            Settlement Instructions (SSI)
          </a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faDesktop} />
          <a href="https://www.bonds.com/" target="_blank" rel="noreferrer">
            Corporate website
          </a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faCookieBite} />
          <a
            href="https://bonds.com/cookie-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Policy
          </a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faShieldCheck} />
          <a
            href="https://bonds.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faEnvelope} />
          <a href="mailto:hello@bonds.com">Request Trading Rules</a>
        </div>
        <div className={styles.dropdownButton}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <a
            href="https://bonds.com/regulatory-information/"
            target="_blank"
            rel="noreferrer"
          >
            More regulatory info
          </a>
        </div>
        <div
          className={cx(styles.dropdownButton, styles.menuSection)}
          onClick={handleSignout}
        >
          Sign Out
        </div>
      </div>
    </>
  )
}

export default ClientMenu
