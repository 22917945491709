import { GridApi } from '@ag-grid-community/core'
import { useSelector } from 'react-redux'
import {
  getErrorForOrder,
  getPendingUserOrderForSecurity,
  getValidationResultForOrder
} from '../../../store/order/selectors'
import { OrderType } from '../../../store/order/types'
import { Security } from '../../../store/securities/reducer'
import {
  getSecurityOrderDataById,
  getSecurityStaticDataById,
  getSecurity7ChordDataById
} from '../../../store/securities/selectors'
import { getStagedOrderBySecurityId } from '../../../store/stagedOrders/selectors'

export interface CellProps {
  rowIndex: number
  value: any
  api: GridApi
  data: any
  column: any
  node: any
  setValue: (v: any) => void
}

export const useSecurityOrderData = (data: any) => {
  const getSecurity = useSelector(getSecurityOrderDataById)
  return data && getSecurity(data.id)
}

export const useSecurityStaticData = (data: any) => {
  const getSecurity = useSelector(getSecurityStaticDataById)
  return data && getSecurity(data.id)
}

export const useSecurity7ChordData = (data: any) => {
  const getSecurity = useSelector(getSecurity7ChordDataById)
  return data && getSecurity(data.id)
}

export const useLoadSecurity = (id: number) => {
  const getSecurity = useSelector(getSecurityStaticDataById)
  return id && getSecurity(id)
}

export const useStagedAndLiveOrders = (
  securityId: Security['id'] | undefined,
  orderType: OrderType
) => {
  const getStagedOrder = useSelector(getStagedOrderBySecurityId)
  const getLiveOrder = useSelector(getPendingUserOrderForSecurity)
  const getOrderValidation = useSelector(getErrorForOrder)

  const stagedOrder =
    (securityId && getStagedOrder(securityId)?.[orderType]) || undefined
  const liveOrder =
    (securityId && getLiveOrder(securityId, orderType)) || undefined
  const orderValidation =
    securityId && getOrderValidation(securityId, orderType)
  return { stagedOrder, liveOrder, orderValidation }
}

export const useStagedAndLiveOrdersValidation = (
  securityId: Security['id'] | undefined,
  orderType: OrderType
) => {
  const getStagedOrder = useSelector(getStagedOrderBySecurityId)
  const getLiveOrder = useSelector(getPendingUserOrderForSecurity)
  const getOrderValidation = useSelector(getValidationResultForOrder)

  const stagedOrder =
    (securityId && getStagedOrder(securityId)?.[orderType]) || undefined
  const liveOrder =
    (securityId && getLiveOrder(securityId, orderType)) || undefined
  const orderValidation =
    securityId && getOrderValidation(securityId, orderType)
  return { stagedOrder, liveOrder, orderValidation }
}
