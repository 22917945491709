import React, { FC } from 'react'
import { highlight } from '../../../../helpers/formatting'
import styles from './SecuritySearchResultIssuer.scss'

export interface Props {
  issuerSymbol: string
  numberOfSecurities: number
  search: string
}

const SecuritySearchResultIssuer: FC<Props> = ({
  issuerSymbol,
  numberOfSecurities,
  search
}) => {
  return (
    <div className={styles.issuer}>
      <span>
        {highlight(search, issuerSymbol)} ({numberOfSecurities})
      </span>
    </div>
  )
}

export default SecuritySearchResultIssuer
