import {
  ColumnApi,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { faDownload } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tradeBlotterFetch } from '../../../store/activityTradeBlotter/actions'
import {
  getActivityTradeBlotter,
  getActivityTradeBlotterError
} from '../../../store/activityTradeBlotter/selectors'
import { Trade } from '../../../store/activityTradeBlotter/types'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import styles from '../ActivityTradeBlotter.scss'
import gridStyles from '../../../assets/scss/grid.scss'
import { columnDefinitions } from './columnDefs'

interface Props {
  onToggle?: (value: boolean) => void
}

const getTradeRowId: GetRowIdFunc<Trade> = ({ data }) => {
  return `${data?.id ?? ''}`
}

const ActivityTradeBlotterGrid: FC<Props> = ({ onToggle }) => {
  const dispatch = useDispatch()
  const tradeData = useSelector(getActivityTradeBlotter)
  const tradeDataError = useSelector(getActivityTradeBlotterError)
  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  useEffect(() => {
    dispatch(tradeBlotterFetch())
  }, [])

  const onGridReady = useCallback(({ api, columnApi }: GridReadyEvent) => {
    if (!gridApi) {
      setGridApi({ api, columnApi })
      columnApi.autoSizeAllColumns()
    }
  }, [])

  if (tradeDataError) {
    return <div>{tradeDataError}</div>
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Activity Blotter"
        customClose={onToggle ? () => onToggle(false) : undefined}
      />
      <div className={styles.buttonRow}>
        <button
          onClick={() => {
            gridApi?.api.exportDataAsCsv({})
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
          &nbsp;Export
        </button>
      </div>
      <div className={cx(gridStyles.gridDimensions, `ag-theme-balham`)}>
        <AgGridReact
          rowData={tradeData}
          columnDefs={columnDefinitions}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={'No trades found.'}
          overlayLoadingTemplate="Loading Trades..."
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowId={getTradeRowId}
        />
      </div>
    </div>
  )
}

export default ActivityTradeBlotterGrid
