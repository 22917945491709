import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../../assets/scss/grid.scss'
import { queueStatsFetch } from '../../../store/admin/queueStats/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import QueueStatsGrid from './Grid/QueueStatsGrid'

const QueueStats: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(queueStatsFetch())
  }, [])
  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader title="Queue Stats" />
      <QueueStatsGrid />
    </div>
  )
}
export default QueueStats
