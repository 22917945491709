import cx from 'classnames'
import dayjs from 'dayjs'
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import {
  getBook,
  getBooks,
  getDefaultBookId
} from '../../../store/books/selectors'
import { getOrderValidations } from '../../../store/order/selectors'
import {
  createWatchlistWithEnhancedUploadRows,
  resetInvalidIdentifiers,
  resetUploadOrdersServerError,
  toggleDropdownState,
  uploadEnhancedOrderRows
} from '../../../store/upload/actions'
import {
  getInvalidIdentifiers,
  getState as getUploadState,
  isUploadPending
} from '../../../store/upload/selectors'
import { DropdownState } from '../../../store/upload/types'
import { getDetailsForCurrentWatchlist } from '../../../store/watchList/selectors'
import { WatchList, WatchlistPermission } from '../../../store/watchList/types'
import {
  copyRow,
  createGridRows,
  dateFormat,
  invalidDataToCopy
} from '../helpers'
import NewWatchListInput from '../NewWatchListInput'

import styles from '../uploadDropDownMenu.scss'
import UploadMenuFooter from '../UploadMenuFooter'
import UploadMenuHeader from '../UploadMenuHeader'
import Grid from './Grid'
import { EnhancedOrderUploadRow } from './types'
import OrderErrorTable from './OrderErrorTable'

interface Props {
  gridIndex: number
  currentState: DropdownState
}

const invalidOrderMsg = 'Please correct errors before submitting.'
const permissions = ['My Firm', 'Private'] as const
type PermissionsType = (typeof permissions)[number]

export type RowSetterFn = (
  rows: EnhancedOrderUploadRow[]
) => EnhancedOrderUploadRow[]
const convertWatchlistPermissionToDisplay = (
  permission?: WatchlistPermission
) => {
  if (!permission) return undefined
  const map = {
    myFirm: permissions[0],
    private: permissions[1]
  }
  return map[permission]
}
const getWatchlistName = (name?: string) => name || dayjs().format(dateFormat)

// ------------------ reducer ------------------ //
export type WorkingWatchlistUpload = {
  name: string
  bookId: number
  permission: PermissionsType
  rows: EnhancedOrderUploadRow[]
  isChanged: boolean
}
type ResetAction = {
  type: 'reset'
  payload: {
    watchlist: WorkingWatchlistUpload
    isTob: boolean
    isIceberg: boolean
  }
}

type ReducerCanEdit = Omit<WorkingWatchlistUpload, 'isChanged'>
type EditActions = UpdateActions<ReducerCanEdit>
const createActionForProperty = <Prop extends keyof ReducerCanEdit>(
  prop: Prop,
  payload: ReducerCanEdit[Prop]
) => {
  return {
    type: `update_${prop}` as const,
    payload
  } as const
}
const uploadWatchlistReducer = (
  watchlist: WorkingWatchlistUpload,
  action: EditActions | ResetAction
): WorkingWatchlistUpload => {
  switch (action.type) {
    case 'update_name':
      return { ...watchlist, name: action.payload, isChanged: true }
    case 'update_bookId':
      return { ...watchlist, bookId: action.payload, isChanged: true }
    case 'update_permission':
      return { ...watchlist, permission: action.payload, isChanged: true }
    case 'update_rows':
      return { ...watchlist, rows: action.payload, isChanged: true }
    case 'reset':
      return {
        ...action.payload.watchlist,
        rows: createGridRows([], action.payload.isIceberg, action.payload.isTob)
      }
  }
}

// ------------------ end reducer ------------------ //

const EnhancedUploadDropdownMenu = ({ gridIndex, currentState }: Props) => {
  const { fin } = useOpenFin()
  const isTob = false
  const [isIceberg, setIsIceberg] = useState(false)
  /*  This allows us to force a rerender of the grid when
      we clear rows so we can start our row indexes at 0.
   */
  const [gridVersion, setGridVersion] = useState(0)
  const defaultBookId = useSelector(getDefaultBookId)
  const thisBook = useSelector(getBook)
  const books = useSelector(getBooks)
  const validations = useSelector(getOrderValidations)
  const invalidIdentifiers = useSelector(getInvalidIdentifiers)
  const processing = useSelector(isUploadPending)

  const currentWatchlist = useSelector(getDetailsForCurrentWatchlist)(gridIndex)
  const dispatch = useDispatch()

  // ------------------ creating and using the reducer ------------------ //
  const initModel = useCallback(
    (watchlist: WatchList | undefined): WorkingWatchlistUpload => {
      const name = getWatchlistName(watchlist?.name)
      const permission =
        convertWatchlistPermissionToDisplay(watchlist?.permission) ||
        permissions[0]
      const bookId = defaultBookId
      return {
        name,
        permission,
        bookId,
        rows: createGridRows(watchlist?.symbolsAndCusips || [], false, false),
        isChanged: false
      }
    },
    [defaultBookId]
  )
  const startingWatchlist =
    currentState === 'newWatchlist' ? undefined : currentWatchlist
  const [workingWatchlist, localDispatch] = useReducer(
    uploadWatchlistReducer,
    startingWatchlist,
    initModel
  )

  const updateName = useCallback(
    (newName: string) => {
      localDispatch(createActionForProperty('name', newName))
    },
    [localDispatch]
  )
  const updateBook = useCallback(
    (newBookId: number) => {
      localDispatch(createActionForProperty('bookId', newBookId))
    },
    [localDispatch]
  )
  const updatePermission = useCallback(
    (selectedPermission: PermissionsType) => {
      localDispatch(createActionForProperty('permission', selectedPermission))
    },
    [localDispatch]
  )
  const resetGridRows = useCallback(() => {
    localDispatch({
      type: 'reset',
      payload: {
        watchlist: workingWatchlist,
        isIceberg,
        isTob
      }
    })
    setFormErrorMessage('')
    setGridVersion((v) => v + 1)
  }, [localDispatch, isIceberg, isTob, workingWatchlist])

  const setRows = useCallback(
    (newRows: EnhancedOrderUploadRow[] | RowSetterFn) => {
      if (typeof newRows === 'function') {
        localDispatch(
          createActionForProperty('rows', newRows(workingWatchlist.rows))
        )
      } else {
        localDispatch(createActionForProperty('rows', newRows))
      }
    },
    [workingWatchlist.rows]
  )

  // ------------------ automatic updates ------------------ //

  const hasErrorsInRows = useMemo(() => {
    return workingWatchlist.rows.some((row) => row.errMsg)
  }, [workingWatchlist.rows])
  useEffect(() => {
    if (
      !hasErrorsInRows &&
      !invalidIdentifiers?.length &&
      !!workingWatchlist.name
    ) {
      setFormErrorMessage('')
    }
  }, [hasErrorsInRows, invalidIdentifiers, workingWatchlist.name])
  const classes = cx(styles.contentDropDownMenu, {
    [styles.isIceberg]: isIceberg,
    [styles.hasErrors]: hasErrorsInRows || currentState === 'invalidUpload',
    [styles.isFin]: fin
  })

  useEffect(() => {
    const identifiers = (invalidIdentifiers ?? []).map((obj) => obj.identifier)
    const isInvalid = (identifier?: string) => {
      return identifiers.includes(identifier ?? 'empty identifier')
    }

    const newRows = workingWatchlist.rows.map((row) =>
      isInvalid(row.identifier)
        ? { ...row, errMsg: 'Identifier not found' }
        : { ...row, errMsg: '' }
    )
    localDispatch(createActionForProperty('rows', newRows))
    if (invalidIdentifiers?.length) {
      setFormErrorMessage('One or more identifiers not accepted.')
    }
  }, [invalidIdentifiers])

  // we only want this to happen when iceberg changes, so
  // that is the only thing in the dependency list
  useEffect(() => {
    if (workingWatchlist.isChanged) {
      setRows(
        createGridRows(
          startingWatchlist?.symbolsAndCusips || [],
          isIceberg,
          false
        )
      )
    }
  }, [isIceberg])

  // ------------------ other form interaction ------------------ //
  const { serverErrorMessage } = useSelector(getUploadState)

  const [formErrorMessage, setFormErrorMessage] = useState(serverErrorMessage)
  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      // Original version shows empty name error first, but that requires multiple
      // checks. Revisit if someone complains.
      if (hasErrorsInRows) {
        setFormErrorMessage(invalidOrderMsg)
        return
      }
      dispatch(resetInvalidIdentifiers())
      dispatch(resetUploadOrdersServerError())

      const rowsToProcess = workingWatchlist.rows.filter(
        (row) => !!row.identifier
      )

      const selectedBook = thisBook(workingWatchlist.bookId)
      switch (currentState) {
        case 'newWatchlist': {
          if (!workingWatchlist.name) {
            setFormErrorMessage('Enter a watchlist name')
            return
          }
          dispatch(
            createWatchlistWithEnhancedUploadRows(
              {
                ...workingWatchlist,
                rows: rowsToProcess
              },
              gridIndex,
              selectedBook
            )
          )
          break
        }
        case 'upload': {
          const err = !rowsToProcess.length
            ? currentWatchlist?.name === workingWatchlist.name
              ? 'Enter a valid Ticker/CUSIP/ISIN'
              : invalidOrderMsg
            : ''
          if (err) {
            setFormErrorMessage(err)
            return
          }
          if (!currentWatchlist) {
            return
          }
          dispatch(
            uploadEnhancedOrderRows(
              gridIndex,
              currentWatchlist,
              rowsToProcess,
              workingWatchlist.bookId
            )
          )
        }
      }
      setFormErrorMessage('')
    },
    [workingWatchlist, hasErrorsInRows]
  )

  const onCancel = useCallback(() => {
    dispatch(toggleDropdownState(gridIndex, 'closed'))
    dispatch(resetInvalidIdentifiers())
  }, [])

  const createNewWatchlist = useCallback(() => {
    dispatch(toggleDropdownState(gridIndex, 'newWatchlist'))
    const newWl = {
      name: getWatchlistName(),
      permission: permissions[0],
      bookId: defaultBookId,
      rows: [] as EnhancedOrderUploadRow[],
      isChanged: false
    } as const
    localDispatch({
      type: 'reset',
      payload: {
        watchlist: newWl,
        isTob: false,
        isIceberg: false
      }
    })
    setGridVersion(-1)
  }, [gridIndex])

  const headersToCopy = useMemo(() => {
    const ticker = 'Ticker / ISIN / CUSIP'
    const bidAmt = 'Bid Amt'
    const bidPrice = 'Bid Price'
    const bidSpr = 'Bid Spread'
    const ofrAmt = 'Offer Amt'
    const ofrSpread = 'Offer Spread'
    const ofrPrice = 'Offer Price'
    if (isIceberg) {
      return [
        ticker,
        `Total ${bidAmt}`,
        `Show ${bidAmt}`,
        bidPrice,
        bidSpr,
        ofrSpread,
        ofrPrice,
        `Show ${ofrAmt}`,
        `Total ${ofrAmt}`
      ]
    }
    return [ticker, bidAmt, bidPrice, bidSpr, ofrSpread, ofrPrice, ofrAmt]
  }, [isIceberg])
  const copyData = useCallback(() => {
    const data = validations.length
      ? invalidDataToCopy(validations)
      : workingWatchlist.rows.map(copyRow).join('\t\n')
    const copyText = headersToCopy.join('\t') + '\n' + data
    navigator.clipboard.writeText(copyText).catch(console.warn)
  }, [validations, workingWatchlist.rows, headersToCopy])

  const removeErrorRows = useCallback(() => {
    setRows((rows) =>
      rows.filter((row) => {
        return !row.errMsg
      })
    )
    setFormErrorMessage('')
  }, [workingWatchlist.rows])

  const copyDataMsg = workingWatchlist.rows.some((row) => row.identifier)
    ? 'Copy Data'
    : 'Copy Headers'

  useEffect(() => {
    if (serverErrorMessage) {
      setFormErrorMessage(serverErrorMessage)
    }
  }, [serverErrorMessage])

  return (
    <div className={styles.dropdown}>
      <form
        data-testid="watchlist-upload-form"
        className={classes}
        onSubmit={onSubmit}
      >
        {currentState === 'newWatchlist' ? (
          <NewWatchListInput
            watchlistName={workingWatchlist.name}
            setWatchlistName={updateName}
          />
        ) : (
          <></>
        )}
        <UploadMenuHeader
          clearAllRows={resetGridRows}
          errorMessage={formErrorMessage}
          dropdownState={currentState}
          toggleNewWatchlist={createNewWatchlist}
          cancel={onCancel}
          copyData={copyData}
          title={copyDataMsg}
          isIceberg={isIceberg}
          setIsIceberg={setIsIceberg}
          removeInvalid={removeErrorRows}
        />
        {currentState === 'invalidUpload' ? (
          <OrderErrorTable />
        ) : (
          <Grid
            key={gridVersion}
            isIceberg={isIceberg}
            setRows={setRows}
            rows={workingWatchlist.rows}
          />
        )}
        <UploadMenuFooter
          gridIndex={gridIndex}
          cancel={onCancel}
          setBook={updateBook}
          books={books}
          setPermission={updatePermission}
          permissions={permissions as unknown as string[]}
          processing={processing}
          dropdownState={currentState}
          copy={copyData}
          disableSaving={hasErrorsInRows && !invalidIdentifiers?.length}
        />
      </form>
    </div>
  )
}

export default EnhancedUploadDropdownMenu
