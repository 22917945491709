import { OrderFields } from '../../helpers/hooks/useManageMyOrders'
import { OrderType, TobParameters } from '../order/types'
import { Security } from '../securities/reducer'
import { StagedOrdersAction } from './actions'

export interface StagedOrderInfo {
  price: number | undefined
  spread: number | undefined
  isSpreadOrder: boolean
  size: number
  allOrNone: boolean
  individualMin: number
  custId: number
  tob: TobParameters
  totalSize?: number
}

export interface StagedOrderType {
  ['buy']?: StagedOrderInfo
  ['sell']?: StagedOrderInfo
}

export interface State {
  stagedOrders: Record<Security['id'], StagedOrderType>
  focusedOrder?: { securityId: Security['id']; orderType: OrderType }
  tempFieldValues?: {
    [id: Security['id']]: {
      [key in OrderType]?: {
        [field in OrderFields]?: string
      }
    }
  }
}

export const initialState: State = {
  stagedOrders: {}
}

const getNewTempFieldValues = (
  oldTempFieldValues: State['tempFieldValues'],
  securityId?: Security['id']
) => {
  if (oldTempFieldValues?.[securityId || -1]) {
    return { tempFieldValues: oldTempFieldValues }
  }
  return {}
}

export default (state = initialState, action: StagedOrdersAction): State => {
  switch (action.type) {
    case 'stagedOrders.addOrUpdateStagedOrders': {
      const { tempFieldValues, ...oldState } = state
      return {
        ...oldState,
        stagedOrders: action.payload.stagedOrders.reduce(
          (stagedOrders, stagedOrder) => {
            const {
              securityId,
              orderType,
              price,
              spread,
              isSpreadOrder,
              size,
              allOrNone,
              custId,
              tob,
              totalSize,
              individualMin
            } = stagedOrder
            if (((price || spread) && size) || totalSize) {
              return {
                ...stagedOrders,
                [securityId]: {
                  ...stagedOrders[securityId],
                  [orderType]: {
                    price,
                    spread,
                    isSpreadOrder,
                    size,
                    allOrNone,
                    custId,
                    tob,
                    totalSize: totalSize ?? size,
                    individualMin
                  }
                }
              }
            }
            return { ...stagedOrders }
          },
          state.stagedOrders
        )
      }
    }
    case 'stagedOrders.removeStagedOrder': {
      const { tempFieldValues, ...oldState } = state
      if (
        state.stagedOrders[action.payload.securityId] === undefined ||
        state.stagedOrders[action.payload.securityId][
          action.payload.orderType
        ] === undefined
      ) {
        // return prev state if securityId is not in stagedOrders state
        return oldState
      }

      const { [action.payload.orderType]: _noType, ...newStagedOrder } =
        state.stagedOrders[action.payload.securityId] // newStagedOrder may or may not be empty; {} | { 'buy': {..} }

      if (Object.entries(newStagedOrder).length === 0) {
        // remove entry altogether if obj is empty
        const { [action.payload.securityId]: _noId, ...orders } =
          state.stagedOrders
        return { ...oldState, stagedOrders: orders }
      }

      return {
        ...oldState,
        stagedOrders: {
          ...oldState.stagedOrders,
          [action.payload.securityId]: newStagedOrder
        }
      }
    }
    case 'stagedOrders.removeStagedOrders': {
      const { stagedOrders, tempFieldValues, ...oldState } = state

      const st = { ...stagedOrders }
      // TODO: this originated as mutation code and needs to be cleaned up
      for (const SO of action.payload) {
        if (st.hasOwnProperty(SO.securityId)) {
          st[SO.securityId][SO.orderType] = undefined
          if (
            st[SO.securityId].buy === undefined &&
            st[SO.securityId].sell === undefined
          ) {
            delete st[SO.securityId]
          }
        }
      }
      return {
        ...oldState,
        stagedOrders: st
      }
    }

    case 'stagedOrders.setFocusOnOrder': {
      const { tempFieldValues: oldTempfieldValues, ...oldState } = state
      const newTempfieldValues = getNewTempFieldValues(
        oldTempfieldValues,
        action.payload?.securityId
      )
      return {
        ...oldState,
        focusedOrder: action.payload,
        ...newTempfieldValues
      }
    }
    case 'stagedOrders.setTempOrderFieldValue':
      // we deliberately only keep temp values for one security at a time
      const prevForThisSecurity =
        state.tempFieldValues?.[action.securityId] ?? {}
      return {
        ...state,
        tempFieldValues: {
          [action.securityId]: {
            ...prevForThisSecurity,
            [action.orderType]: { [action.field]: action.payload }
          }
        }
      }
    case 'stagedOrders.clearCheckedStagedOrdersAction': {
      const { tempFieldValues: oldTempfieldValues, ...oldState } = state
      return oldState
    }

    default:
      return state
  }
}
