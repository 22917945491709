import { ColDef } from '@ag-grid-community/core'
import { dateFormatter } from '../../../../helpers/formatting'

export const TAKER_ORDER_ID = 'takerOrderId'
export const MAKER_ORDER_ID = 'makerOrderId'
export const STATUS = 'status'

export const DID_NOT_MATCH_REASON = 'didNotMatchReason'
export const TAKER_OPERATOR = 'takerOperator'
export const MAKER_OPERATOR = 'makerOperator'
export const TAKER_TRADER = 'takerTrader'
export const MAKER_TRADER = 'makerTrader'
export const TAKER_VENUE = 'takerVenue'
export const MAKER_VENUE = 'makerVenue'
export const TAKER_TICKET_ID = 'takerTicketId'
export const MAKER_TICKET_ID = 'makerTicketId'
export const TAKER_INTERFACE = 'takerInterface'
export const MAKER_INTERFACE = 'makerInterface'
export const TAKER_ORDER_PARAMS = 'takerOrderParams'
export const MAKER_ORDER_PARAMS = 'makerOrderParams'
export const TAKER_ORDER_STATUS_UPDATES = 'takerOrderStatusUpdates'
export const MAKER_ORDER_STATUS_UPDATES = 'makerOrderStatusUpdates'

export const ORDER_TIME = 'orderTime'
export const ORDER_DATE = 'orderDate'
export const MAKER_EXTERNAL_ORDER_ID = 'makerExternalOrderId'
export const MAKER_VENUE_NUMBER = 'makerVenueNumber'
export const TAKER_COMPONENT_LOCATION = 'takerComponentLocation'
export const MAKER_COMPONENT_LOCATION = 'makerComponentLocation'

export const SECURITY_BOARD_LABEL = 'securityBoardLabel'
export const ISIN = 'isin'
export const ORDER_TYPE = 'orderType'
export const PRICE = 'price'
export const SPREAD = 'spread'
export const COMPLETED_AMT = 'completedAmt'
export const INITIAL_AMT = 'initialAmt'
export const REMAINING_AMT = 'remainingAmt'
export const SECONDS_LIVE = 'secondsLive'
export const SECURITY_ID = 'securityId'
export const CANCEL_TIME = 'cancelTime'
export const IS_LIVE = 'isLive'
export const WAS_CANCELED = 'wasCanceled'
export const WAS_REJECTED = 'wasRejected'
export const TRADER = 'trader'
export const TAKER_CUSTOMER = 'customer'
export const SPECIFIC = 'specific'
export const TRADE_RECORD_ID = 'tradeRecordId'
export const ACTIVITY_TICKER = 'activityTicker'
export const FIX_VENUE_COUNT = 'fixVenueCount'
export const BASIS_POINTS = 'basisPoints'
export const BROKERAGE = 'brokerage'

export const customizableColumns: ColDef[] = [
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Order Time',
    valueFormatter: dateFormatter
  },
  {
    colId: SECONDS_LIVE,
    field: SECONDS_LIVE,
    headerName: 'Seconds Live'
  },
  {
    colId: STATUS,
    field: STATUS,
    headerName: 'Status'
  },
  {
    colId: DID_NOT_MATCH_REASON,
    field: DID_NOT_MATCH_REASON,
    headerName: 'DidNotMatchReason'
  },
  {
    colId: TAKER_OPERATOR,
    field: TAKER_OPERATOR,
    headerName: 'Taker Operator'
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'Order Type'
  },
  {
    colId: INITIAL_AMT,
    field: INITIAL_AMT,
    headerName: 'Initial Amt'
  },
  {
    colId: COMPLETED_AMT,
    field: COMPLETED_AMT,
    headerName: 'Completed Amt'
  },
  {
    colId: SECURITY_BOARD_LABEL,
    field: SECURITY_BOARD_LABEL,
    headerName: 'Security'
  },
  {
    colId: ISIN,
    field: ISIN,
    headerName: 'ISIN'
  },
  {
    colId: MAKER_OPERATOR,
    field: MAKER_OPERATOR,
    headerName: 'Maker Operator'
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread'
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price'
  },
  {
    colId: BROKERAGE,
    field: BROKERAGE,
    headerName: 'Brokerage'
  },
  {
    colId: BASIS_POINTS,
    field: BASIS_POINTS,
    headerName: 'Basis Points'
  },
  {
    colId: CANCEL_TIME,
    field: CANCEL_TIME,
    headerName: 'Cancel Time'
  },
  {
    colId: REMAINING_AMT,
    field: REMAINING_AMT,
    headerName: 'Remaining Amt'
  },
  {
    colId: IS_LIVE,
    field: IS_LIVE,
    headerName: 'Live'
  },
  {
    colId: TRADE_RECORD_ID,
    field: TRADE_RECORD_ID,
    headerName: 'Trade Record id'
  },
  {
    colId: TAKER_TICKET_ID,
    field: TAKER_TICKET_ID,
    headerName: 'Taker Ticket Id'
  },
  {
    colId: MAKER_TICKET_ID,
    field: MAKER_TICKET_ID,
    headerName: 'Maker Ticket Id'
  },
  {
    colId: TAKER_INTERFACE,
    field: TAKER_INTERFACE,
    headerName: 'Taker Interface'
  },
  {
    colId: TAKER_VENUE,
    field: TAKER_VENUE,
    headerName: 'Taker Venue'
  },

  {
    colId: TAKER_CUSTOMER,
    field: TAKER_CUSTOMER,
    headerName: 'Taker Customer'
  },
  {
    colId: TAKER_ORDER_ID,
    field: TAKER_ORDER_ID,
    headerName: 'Taker Order Id'
  },
  {
    colId: SPECIFIC,
    field: SPECIFIC,
    headerName: 'Specific'
  },
  {
    colId: MAKER_ORDER_ID,
    field: MAKER_ORDER_ID,
    headerName: 'Maker Order Id'
  },
  {
    colId: MAKER_INTERFACE,
    field: MAKER_INTERFACE,
    headerName: 'Maker Interface'
  },
  {
    colId: MAKER_VENUE,
    field: MAKER_VENUE,
    headerName: 'Maker Venue'
  }

  // TODO: Not being populated properly or at all

  // {
  //   colId: MAKER_TRADER,
  //   field: MAKER_TRADER,
  //   headerName: 'Maker Trader',
  //   width: 120,
  //   sortable: true,

  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: TAKER_TRADER,
  //   field: TAKER_TRADER,
  //   headerName: 'Taker Trader',
  //   width: 100,
  //   sortable: true,

  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: SECURITY_ID,
  //   field: SECURITY_ID,
  //   headerName: 'Security Id',
  //   width: 100,
  //   sortable: true,

  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: MAKER_COMPONENT_LOCATION,
  //   field: MAKER_COMPONENT_LOCATION,
  //   headerName: 'Maker Component Location',
  //   width: 225,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: TAKER_COMPONENT_LOCATION,
  //   field: TAKER_COMPONENT_LOCATION,
  //   headerName: 'Taker Component Location',
  //   width: 225,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: TAKER_ORDER_PARAMS,
  //   field: TAKER_ORDER_PARAMS,
  //   headerName: 'Taker Order Params',
  //   width: 450,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: MAKER_ORDER_PARAMS,
  //   field: MAKER_ORDER_PARAMS,
  //   headerName: 'Maker Order Params',
  //   width: 450,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: TAKER_ORDER_STATUS_UPDATES,
  //   field: TAKER_ORDER_STATUS_UPDATES,
  //   headerName: 'Taker Order Status Updates',
  //   width: 250,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // },
  // {
  //   colId: MAKER_ORDER_STATUS_UPDATES,
  //   field: MAKER_ORDER_STATUS_UPDATES,
  //   headerName: 'Maker Order Status Updates',
  //   width: 250,
  //   sortable: true,
  //   cellStyle: { border: '1px solid #e4e5e6' }
  // }
]

export const columnDefinitions: ColDef[] = [...customizableColumns]
