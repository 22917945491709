import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getAllUserOrders } from '../../../store/order/selectors'
import { OrderStatus } from '../../../store/order/types'

import ModalFooter from '../ModalFooter/ModalFooter'

import styles from './LastLookModalFooter.scss'
export interface Props {
  orderId: string
  orderStatusComp: OrderStatus | undefined
  fontClassName: string
  type: string
  tradeAmt: number | undefined
  size: number
  actionCloseModal: () => void
  handleRejectButtonClick: () => void
  handleConfirmButtonClick: (tradeAmt?: number) => () => void
  benchmarkName: string | undefined
  spotCrossSelection: string
}

const LastLookModalFooter: FC<Props> = (props) => {
  const {
    orderId,
    orderStatusComp,
    tradeAmt,
    size,
    actionCloseModal,
    handleConfirmButtonClick,
    handleRejectButtonClick,
    type,
    benchmarkName,
    spotCrossSelection
  } = props

  const allOrders = useSelector(getAllUserOrders)

  const renderButtons = () => {
    if (orderStatusComp === 'accepted') {
      return (
        <button onClick={actionCloseModal} className={styles.accepted}>
          Close
        </button>
      )
    }

    if (orderStatusComp === 'rejected') {
      return (
        <button onClick={actionCloseModal} className={styles.rejected}>
          Close
        </button>
      )
    }

    if (orderStatusComp === 'cancelled') {
      return (
        <button onClick={actionCloseModal} className={styles.rejected}>
          Close
        </button>
      )
    }

    const buttonClassName = type === 'sell' ? styles.sell : styles.buy

    return (
      <>
        <button
          className={styles.reject}
          onClick={handleRejectButtonClick}
          data-testid="lastlook-reject"
        >
          Reject
        </button>
        <button
          className={buttonClassName}
          onClick={handleConfirmButtonClick(tradeAmt)}
          data-testid="lastlook-confirm"
        >
          CONFIRM {type.toUpperCase()}
        </button>
      </>
    )
  }

  const getTitle = () => {
    if (orderStatusComp === 'accepted') {
      const typeLabel = props.type === 'buy' ? 'bought' : 'sold'
      return typeLabel.toUpperCase()
    }

    if (orderStatusComp === 'rejected') {
      return 'Rejected'
    }
  }

  const getContent = () => {
    if (orderStatusComp === 'accepted') {
      const typeLabel = type === 'buy' ? 'bought' : 'sold'
      const tsyTypelabel = type === 'buy' ? 'sold' : 'bought'

      const getTsyStatus = () => {
        const matchingOrder = Object.values(allOrders).find(
          (value: any) => value.corpOrderId === orderId
        )
        switch (matchingOrder?.status) {
          case 'accepted':
            return (
              <div>
                Tsy hedge: {tsyTypelabel} {benchmarkName} {matchingOrder.size} @{' '}
                {matchingOrder.displayPrice}
              </div>
            )
          case 'waitingForConfirmation':
            return <div>Waiting on Tsy Hedge Execution...</div>
          case 'rejected':
            return <div>No Tsy Hedge Executed</div>
          default:
            return
        }
      }
      return (
        <div>
          <div>
            You {typeLabel} {tradeAmt} of {size}
          </div>
          {spotCrossSelection === 'H' && getTsyStatus()}
        </div>
      )
    }

    if (orderStatusComp === 'rejected') {
      return 'Order has been rejected'
    }

    if (orderStatusComp === 'cancelled') {
      const typeLabel = props.type === 'buy' ? 'offer' : 'bid'
      return `The ${typeLabel} attempt has been cancelled`
    }

    if (orderStatusComp === 'error') {
      return 'An error occurred while updating the order'
    }
  }

  const getCustomStyles = () => {
    if (orderStatusComp === 'accepted') {
      return { content: 'bottomContentSuccess', icon: 'icnAccepted' }
    }

    if (orderStatusComp === 'rejected') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }

    if (orderStatusComp === 'cancelled') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }

    if (orderStatusComp === 'error') {
      return { content: 'bottomContentRejected', icon: 'icnRejected' }
    }
  }

  return (
    <ModalFooter
      buttonComponent={renderButtons()}
      title={getTitle()}
      dataTestId="lastlook-status"
      content={getContent()}
      customStyles={getCustomStyles()}
      icon={<div />}
      timer={<div />}
    />
  )
}

export default LastLookModalFooter
