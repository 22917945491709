import cx from 'classnames'
import React, { FC, useState } from 'react'
import gridStyles from '../../../assets/scss/grid.scss'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import EditableTiers from './EditableTiers'
import LiveTiers from './LiveTiers'
import styles from './Tiers.scss'

const Tiers: FC = () => {
  const [editableSelected, setEditableSelected] = useState<boolean>(true)

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader title="Tiers" />
      <div className={styles.tierDiv}>
        <span
          className={cx(
            editableSelected && styles.tabSelected,
            styles.tiersTab
          )}
          onClick={() => setEditableSelected(true)}
        >
          Editable Tiers
        </span>
        <span
          className={cx(
            !editableSelected && styles.tabSelected,
            styles.tiersTab
          )}
          onClick={() => setEditableSelected(false)}
        >
          Live Tiers
        </span>
      </div>
      {editableSelected && <EditableTiers />}
      {!editableSelected && <LiveTiers />}
    </div>
  )
}

export default Tiers
