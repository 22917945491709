import cx from 'classnames'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GetRowIdParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { defaultColumnDefinitions } from '../../../helpers/formatting'
import { activityLogFetch } from '../../../store/activityLog/actions'
import {
  getActivityLog,
  getActivityLogError
} from '../../../store/activityLog/selectors'
import { Activity } from '../../../store/activityLog/types'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import { columnDefinitions } from './columnDefs'
import gridStyles from '../../../assets/scss/grid.scss'

interface Props {
  onToggle?: (value: boolean) => void
}

const getActivityLogRowId = ({ data }: GetRowIdParams<Activity>) => {
  return `${data.matId ?? ''}`
}

const ActivityLogGrid: FC<Props> = ({ onToggle }) => {
  const dispatch = useDispatch()
  const activityLog = useSelector(getActivityLog)
  const activityLogError = useSelector(getActivityLogError)

  useEffect(() => {
    dispatch(activityLogFetch())
  }, [])

  if (activityLogError) {
    return <div>{activityLogError}</div>
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader
        title="Activity Log"
        customClose={onToggle ? () => onToggle(false) : undefined}
      />
      <div className={cx(gridStyles.gridDimensions, 'ag-theme-balham')}>
        <AgGridReact<Activity>
          defaultColDef={defaultColumnDefinitions}
          rowData={activityLog}
          columnDefs={columnDefinitions}
          overlayNoRowsTemplate="No activity found."
          overlayLoadingTemplate="Loading activity…"
          alwaysShowVerticalScroll={true}
          suppressDragLeaveHidesColumns={true}
          getRowId={getActivityLogRowId}
        />
      </div>
    </div>
  )
}

export default ActivityLogGrid
