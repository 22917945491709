import { combineReducers } from 'redux'
import { defaultFilter, Filter } from '../filter/types'
import { composeReducers, uniq } from '../helpers'
import { Order } from '../order/types'
import { GridSpecificAction, SecuritiesAction } from './actions'
import {
  insertGridDef,
  ReduceSecurityStaticData,
  removeGridDef
} from './helpers'
import { QuoteReliability } from './types'

export interface Security {
  id: number
  isRestricted: boolean
  issuerSymbol: string
  cusip: string
  isin: string
  coupon: number
  couponType: string
  maturityDate: Date
  currency: string
  dayCount: string
  debtType: string
  minimumSize: number
  minimumSizeIncrement?: number
  defaultOrderSize?: number
  minimumPriceIncrement?: number
  benchmarkName?: string
  bidBenchmarkPrice: string
  offerBenchmarkPrice: string
  bestBid?: Order
  bestOffer?: Order
  amountIssued: number
  amountOutstanding: number
  datedDate: Date
  benchmarkISIN: string
  benchmarkCusip: string
  benchmarkSecurity: string
  isBenchmark: boolean
  benchmarkMaturity?: Date
  benchmarkCoupon?: number
  country: string
  firstCouponDate?: Date
  nextCouponDate?: Date
  issueDate: Date
  markupRate: number
  payFreq: string
  product?: string
  settlementDate: Date
  sAndPRating: string
  sector: string
  termToMat: string
  minimumPiece: number
  description: string
  boardLabel: string
  series: string
  issuerName: string
  marketVolume: number
  internalVolume: number
  admin7Chord?: Security7ChordData
}

export interface BidOffer7Chord {
  price_7Chord: number
  dateTime_7Chord: Date
  oasSpread_7Chord: number
  tSpread_7Chord: number
  ytm_7Chord: number
  dayOverSignal_7Chord: number
  signalOverSignal_7Chord: number
  lastClose_7Chord: number
}

export interface SecurityStaticData {
  id: number
  isRestricted: boolean
  issuerSymbol: string
  cusip: string
  isin: string
  coupon: number
  couponType: string
  maturityDate: Date
  currency: string
  dayCount: string
  debtType: string
  minimumSize: number
  minimumSizeIncrement?: number
  defaultOrderSize?: number
  minimumPriceIncrement?: number
  benchmarkName?: string
  bidBenchmarkPrice: string
  offerBenchmarkPrice: string
  amountIssued: number
  amountOutstanding: number
  datedDate: Date
  benchmarkISIN: string
  benchmarkCusip: string
  benchmarkSecurity: string
  isBenchmark: boolean
  benchmarkMaturity?: Date
  benchmarkCoupon?: number
  country: string
  firstCouponDate?: Date
  nextCouponDate?: Date
  issueDate: Date
  markupRate: number
  payFreq: string
  product?: string
  settlementDate: Date
  sAndPRating: string
  sector: string
  termToMat: string
  minimumPiece: number
  description: string
  boardLabel: string
  series: string
  issuerName: string
  marketVolume: number
  internalVolume: number
}

export interface Security7ChordData {
  id: number
  averageDailyTradeCount_7Chord?: number
  timesTraded_7Chord?: number
  timeSinceLastTrade_7Chord?: number
  topExpertPrediction_7Chord?: number
  bid?: BidOffer7Chord
  ofr?: BidOffer7Chord
}

export interface SecurityOrderData {
  id: number
  isRestricted: boolean
  bestBid?: Order
  bestOffer?: Order
  isin?: Security['isin']
  marketVolume: number
  isBenchmark: boolean
}

export interface GridDefinition {
  watchlistId: number | undefined
  issuerFilter?: string
  securitiesFilter?: Array<Security['id']>
  queryFilter?: string
  isMine: boolean
  showLive: boolean
  myFirm: boolean
  myBook: boolean
  quoteReliability: QuoteReliability
  order: number
  sortToTop: number[]
  filter: Filter
  useSize: boolean
  size: number | undefined
  useAdvancedFilter: boolean
  selectedSecurityId?: Security['id']
}

interface GridData {
  currentPage?: number
  pages: Record<
    number,
    {
      status: 'loading' | 'loaded'
      securityIds: Array<Security['id']>
    }
  >
  pending: boolean
  error: boolean
}

export interface State {
  gridDefinitions: { [gridIndex: number]: GridDefinition }
  gridData: { [gridIndex: number]: GridData }
  securitiesOrderDataInfoCache: Record<Security['id'], SecurityOrderData>
  securitiesStaticDataInfoCache: Record<Security['id'], SecurityStaticData>
  securities7ChordDataInfoCache: Record<Security['id'], Security7ChordData>
}

interface GridDefinitionsState {
  [gridIndex: number]: GridDefinition
}

interface GridDataState {
  [gridIndex: number]: GridData
}

type SecuritiesInfoCacheState = Record<
  SecurityOrderData['id'],
  SecurityOrderData
>
type SecuritiesStaticInfoCacheState = Record<
  SecurityStaticData['id'],
  SecurityStaticData
>

type Securities7ChordInfoCacheState = Record<
  Security7ChordData['id'],
  Security7ChordData
>

const initialGridDefinitions: GridDefinitionsState = {
  /* 0: {
    watchlistId: undefined,
    isMine: false,
    showLive: false,
    quoteReliability: QuoteReliability.All,
    order: 0
  }*/
}

const initialGridData: GridDataState = {}

const defaultGridData: GridData = {
  currentPage: undefined,
  pages: {},
  pending: false,
  error: false
}

const initialSecuritiesOrderDataInfoCache: SecuritiesInfoCacheState = {}
const initialSecuritiesStaticDataInfoCache: SecuritiesStaticInfoCacheState = {}
const initialSecurities7ChordDataInfoCache: Securities7ChordInfoCacheState = {}

const updateGridDataWithState = <T>(
  gridData: { [gridIndex: number]: T },
  gridIndex: number,
  updatedData: Partial<T>
): { [gridIndex: number]: T } => ({
  ...gridData,
  [gridIndex]: {
    ...gridData[gridIndex],
    ...updatedData
  }
})

const gridDataMiniReducer = (
  gridData = defaultGridData,
  action: GridSpecificAction
): Partial<GridData> => {
  switch (action.type) {
    case 'securities.setCurrentPage':
      return {
        ...gridData,
        currentPage: action.payload.page
      }

    case 'securities.fetchSecurities':
      return {
        ...gridData,
        pending: true,
        error: false,
        pages: {
          ...gridData.pages,
          [action.payload.page]: {
            status: 'loading',
            securityIds: []
          }
        }
      }

    case 'securities.fetchSecuritiesFailure':
      return { pending: false, error: true }

    case 'securities.addOrUpdateSecurities':
      return {
        ...gridData,
        pending: false,
        error: false,
        pages: {
          ...gridData.pages,
          [action.payload.page]: {
            status: 'loaded',
            securityIds: uniq([
              ...(gridData.pages[action.payload.page]?.securityIds || []),
              ...action.payload.securities.map((security) => security.id)
            ])
          }
        }
      }

    case 'securities.setWatchListId':
    case 'securities.setIssuerFilter':
    case 'securities.setSecuritiesFilter':
    case 'securities.setQueryFilter':
    case 'securities.sortValidationsToTopAction':
    case 'securities.saveAdvancedFilter':
    case 'securities.setMyFirm':
    case 'securities.setMyBook':
    case 'securities.setIsMine':
    case 'securities.setUseAdvancedFilter':
      return {
        ...gridData,
        error: false,
        pending: false,
        currentPage: undefined,
        pages: {}
      }
    case 'securities.setShowLive':
      return {
        ...gridData,
        error: false,
        pending: false,
        currentPage: undefined,
        pages: {}
      }
    case 'securities.setQuoteReliability':
      return {
        ...gridData,
        error: false,
        pending: false,
        currentPage: undefined,
        pages: {}
      }
    case 'securities.setSizeFilter':
      return {
        ...gridData,
        error: false,
        pending: false,
        currentPage: undefined,
        pages: {}
      }
    default:
      return gridData
  }
}

const gridDataReducer = (
  gridData = initialGridData,
  action: GridSpecificAction
): State['gridData'] => {
  if (
    !action.payload ||
    // tslint:disable-next-line: strict-type-predicates
    action.payload.gridIndex === undefined ||
    !action.type.startsWith('securities.')
  ) {
    return gridData
  }
  const { gridIndex } = action.payload
  return updateGridDataWithState(
    gridData,
    gridIndex,
    gridDataMiniReducer(gridData[gridIndex], action)
  )
}

const gridDefinitionsMiniReducer = (
  gridDefinition: GridDefinition,
  action: GridSpecificAction
): GridDefinition => {
  switch (action.type) {
    case 'securities.setWatchListId':
      return {
        ...gridDefinition,
        watchlistId: action.payload.watchlistId,
        securitiesFilter: undefined
      }
    case 'securities.setIssuerFilter':
      return {
        ...gridDefinition,
        issuerFilter: action.payload.issuerFilter,
        queryFilter: undefined,
        securitiesFilter: action.payload.issuerFilter
          ? undefined
          : gridDefinition.securitiesFilter
      }

    case 'securities.sortValidationsToTopAction':
      return {
        ...gridDefinition,
        sortToTop: action.payload.sortToTop
      }

    case 'securities.resetSortToTop':
      return {
        ...gridDefinition,
        sortToTop: []
      }

    case 'securities.setQueryFilter':
      return {
        ...gridDefinition,
        issuerFilter: action.payload.query
          ? undefined
          : gridDefinition.issuerFilter,
        queryFilter: action.payload.query,
        securitiesFilter: action.payload.query
          ? undefined
          : gridDefinition.securitiesFilter
      }

    case 'securities.setSecuritiesFilter':
      return {
        ...gridDefinition,
        issuerFilter: action.payload.securityIds
          ? undefined
          : gridDefinition.issuerFilter,
        queryFilter: undefined,
        securitiesFilter: action.payload.securityIds
      }

    case 'securities.setIsMine':
      return {
        ...gridDefinition,
        isMine: action.payload.isMine,
        securitiesFilter: undefined
      }

    case 'securities.setUseAdvancedFilter':
      return {
        ...gridDefinition,
        useAdvancedFilter: action.payload.useAdvancedFilter,
        securitiesFilter: undefined
      }

    case 'securities.setShowLive':
      return {
        ...gridDefinition,
        showLive: action.payload.showLive,
        securitiesFilter: undefined
      }
    case 'securities.setMyFirm':
      return {
        ...gridDefinition,
        myFirm: action.payload.myFirm,
        securitiesFilter: undefined
      }
    case 'securities.setMyBook':
      return {
        ...gridDefinition,
        myBook: action.payload.myBook,
        securitiesFilter: undefined
      }
    case 'securities.setQuoteReliability':
      return {
        ...gridDefinition,
        quoteReliability: action.payload.quoteReliability,
        securitiesFilter: undefined
      }
    case 'securities.setSizeFilter':
      return {
        ...gridDefinition,
        useSize: action.payload.useSize,
        size: action.payload.size,
        securitiesFilter: undefined
      }

    case 'securities.saveAdvancedFilter':
      return {
        ...gridDefinition,
        filter: action.payload.filter,
        securitiesFilter: undefined
      }

    case 'securities.showSecurityDetails':
      return {
        ...gridDefinition,
        selectedSecurityId: action.payload.securityId
      }

    default:
      return gridDefinition
  }
}

const gridDefinitionsReducer = (
  gridDefinitions = initialGridDefinitions,
  action: GridSpecificAction
): State['gridDefinitions'] => {
  const gridIndex = action.payload?.gridIndex
  if (
    // tslint:disable-next-line: strict-type-predicates
    gridIndex === undefined ||
    !gridDefinitions[gridIndex]
  ) {
    return gridDefinitions
  }
  return updateGridDataWithState(
    gridDefinitions,
    gridIndex,
    gridDefinitionsMiniReducer(gridDefinitions[gridIndex], action)
  )
}

const gridCreationReducer = (state: State, action: SecuritiesAction): State => {
  switch (action.type) {
    case 'securities.createGrid':
      const { watchlistId, afterIndex, isMineGrid } = action.payload
      return {
        ...state,
        gridDefinitions: insertGridDef(
          state.gridDefinitions,
          {
            watchlistId,
            isMine: isMineGrid,
            showLive: false,
            quoteReliability: QuoteReliability.All,
            sortToTop: [],
            myFirm: false,
            myBook: false,
            filter: defaultFilter,
            useSize: false,
            size: 200,
            useAdvancedFilter: false
          },
          afterIndex
        )
      }
    case 'securities.removeGrid':
      return {
        ...state,
        gridDefinitions: removeGridDef(
          state.gridDefinitions,
          action.payload.gridIndex
        ),
        gridData: removeGridDef(state.gridData, action.payload.gridIndex)
      }
    case 'securities.unsubscribeGetSecurities':
      return {
        ...state,
        gridData: {}
      }
    default:
      return state
  }
}

const securitiesOrderDataInfoCacheReducer = (
  securitiesInfoCache = initialSecuritiesOrderDataInfoCache,
  action: SecuritiesAction
): State['securitiesOrderDataInfoCache'] => {
  switch (action.type) {
    case 'securities.addOrUpdateSecurities':
      const newSecuritiesInfoCache = { ...securitiesInfoCache }
      for (const security of action.payload.securities) {
        newSecuritiesInfoCache[security.id] = {
          id: security.id,
          isRestricted: security.isRestricted,
          bestBid: security.bestBid,
          bestOffer: security.bestOffer,
          marketVolume: security.marketVolume,
          isBenchmark: security.isBenchmark
        }
      }
      return newSecuritiesInfoCache
    case 'securities.addOrUpdateSecuritiesCache':
      const newSecuritiesInfoCache2 = { ...securitiesInfoCache }
      for (const security of action.payload.securities) {
        newSecuritiesInfoCache2[security.id] = {
          id: security.id,
          isRestricted: security.isRestricted,
          bestBid: security.bestBid,
          bestOffer: security.bestOffer,
          marketVolume: security.marketVolume,
          isBenchmark: security.isBenchmark
        }
      }
      return newSecuritiesInfoCache2
    case 'securities.unsubscribeGetSecurities':
      return {}
    default:
      return securitiesInfoCache
  }
}
const securities7ChordDataInfoCacheReducer = (
  securities7ChordInfoCache = initialSecurities7ChordDataInfoCache,
  action: SecuritiesAction
): State['securities7ChordDataInfoCache'] => {
  switch (action.type) {
    case 'securities.addOrUpdateSecurities':
    case 'securities.addOrUpdateSecuritiesCache':
      const newSecurities7ChordInfoCache = { ...securities7ChordInfoCache }
      for (const security of action.payload.securities) {
        if (security.admin7Chord) {
          newSecurities7ChordInfoCache[security.id] = {
            ...security.admin7Chord,
            id: security.id
          }
        }
      }
      return newSecurities7ChordInfoCache
    case 'securities.unsubscribeGetSecurities':
      return {}
    default:
      return securities7ChordInfoCache
  }
}

const securitiesStaticDataInfoCacheReducer = (
  securitiesInfoCache = initialSecuritiesStaticDataInfoCache,
  action: SecuritiesAction
): State['securitiesStaticDataInfoCache'] => {
  switch (action.type) {
    case 'securities.addOrUpdateSecurities':
      return ReduceSecurityStaticData(
        securitiesInfoCache,
        action.payload.securities
      )
    case 'securities.addOrUpdateSecuritiesCache':
      return ReduceSecurityStaticData(
        securitiesInfoCache,
        action.payload.securities
      )
    /* case 'securities.unsubscribeGetSecurities':
      return {}*/
    default:
      return securitiesInfoCache
  }
}

export default composeReducers(
  combineReducers({
    gridData: gridDataReducer,
    gridDefinitions: gridDefinitionsReducer,
    securitiesOrderDataInfoCache: securitiesOrderDataInfoCacheReducer,
    securitiesStaticDataInfoCache: securitiesStaticDataInfoCacheReducer,
    securities7ChordDataInfoCache: securities7ChordDataInfoCacheReducer
  }),
  gridCreationReducer
)
