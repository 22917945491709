import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { fetchAlertFilters } from '../../store/alertFilter/actions'
import { getAlertFilters } from '../../store/alertFilter/selectors'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import styles from './AlertFilter.scss'
import AlertFilterEdit from './AlertFilterEdit'
import AlertFilterList from './AlertFilterList'

export interface Props {
  setActive: (b: boolean) => void
}
const AlertFilterEditor: FC<Props> = ({ setActive }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAlertFilters())
  }, [])
  const alertFilters = useSelector(getAlertFilters)
  const selectedId = alertFilters.length > 0 ? alertFilters[0].id : 0
  const [selectedAlertFilterId, setSelectedAlertFilterId] = useState(selectedId)
  const { fin, manageWindows } = useOpenFin()

  return (
    <div className={cx(styles.AlertWrapper, fin && styles.openfinAlertWrapper)}>
      <ComponentHeader title={'Alert Filter Editor'} />
      <div className={styles.HeaderWrapper}>
        <AlertFilterList
          filters={alertFilters}
          selectAlertFilterId={setSelectedAlertFilterId}
          selectedAlertFilterId={selectedAlertFilterId}
        />
      </div>
      <AlertFilterEdit
        filterId={selectedAlertFilterId}
        onClose={() =>
          fin ? manageWindows('AlertFilterEditor', 'close') : setActive(false)
        }
        key={selectedAlertFilterId}
      />
    </div>
  )
}

export default AlertFilterEditor
