import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../../assets/scss/grid.scss'
import UserDashboardGrid from './Grid/UserDashboardGrid'

import cx from 'classnames'
import {
  subscribeToUserDashboard,
  unsubscribeFromUserDashboard
} from '../../../store/admin/userDashboard/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'

const UserDashboard: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(subscribeToUserDashboard())
    return () => {
      dispatch(unsubscribeFromUserDashboard())
    }
  }, [])

  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader title={'User Dashboard'} />
      <div className={cx(styles.gridDimensions, 'ag-theme-balham')}>
        <UserDashboardGrid />
      </div>
    </div>
  )
}

export default UserDashboard
