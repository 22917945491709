import cx from 'classnames'
import React, { FC } from 'react'
import ReactModal from 'react-modal'

import { useDispatch } from 'react-redux'
import { useToggleWithCancelCallback } from '../../helpers/hooks'
import { activityLogFetchCancel } from '../../store/activityLog/actions'
import Button from '../Button/Button'
import styles from './ActivityLog.scss'
import ActivityLogGrid from './Grid/ActivityLogGrid'

interface Props {
  isHidden: boolean
}

const ActivityLog: FC<Props> = ({ isHidden }) => {
  const dispatch = useDispatch()

  const [displayModal, toggleModal] = useToggleWithCancelCallback(false, () => {
    dispatch(activityLogFetchCancel())
  })

  return (
    <React.Fragment>
      {displayModal && (
        <ReactModal
          isOpen={displayModal}
          onRequestClose={() => toggleModal(false)}
        >
          <ActivityLogGrid onToggle={toggleModal} />
        </ReactModal>
      )}
      <Button
        onClick={() => toggleModal(true)}
        className={cx(styles.activityLogToggle, {
          hidden: isHidden
        })}
      >
        My Activity Log
      </Button>
    </React.Fragment>
  )
}

export default ActivityLog
