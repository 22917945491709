import { ICellEditorReactComp } from '@ag-grid-community/react'
import cx from 'classnames'
import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import styles from '../bondListStyle.scss'
import { OrderCellEditorParams } from './types'

const agGridKeysToPrevent = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Enter']

const MyOrderEditor = forwardRef<ICellEditorReactComp, OrderCellEditorParams>(
  ({ manager, data: security, field, stopEditing }, ref) => {
    const styleConditions = manager.getStyleConditions(security?.id ?? 0, field)
    const classNames = cx(styles.item, {
      [styles.isPending]: styleConditions.isPending,
      [styles.hasFocus]: styleConditions.hasFocus,
      [styles.hasError]: styleConditions.hasError
    })

    const initialValue = manager.getInitialFieldValueForSecurity(
      security?.id ?? -1,
      field
    )
    const [value, setValue] = useState(initialValue)

    const submitFieldValue = useCallback(() => {
      if (!security) return
      manager.setOrderFieldByName(field, `${value}`, security)
    }, [field, manager, value, security])

    // this component is only instantiated when the column is being edited,
    // so we focus the input on first render
    const refInput = useRef<HTMLInputElement>(null)
    useEffect(() => {
      refInput.current?.focus()
      refInput.current?.select()
      if (security) manager.focusOrder(security.id)
    }, [])

    // exposes the internals for AG Grid to hook into
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          // this is called when the grid thinks we're done editing, so we'll set the value in redux here
          if (value !== initialValue) submitFieldValue()
          return value
        }
      }
    })
    const onInputKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (agGridKeysToPrevent.includes(e.key)) {
          e.stopPropagation()
        }
        if (e.key === 'Enter') {
          if (value !== initialValue) {
            manager.stageAndSubmitOrder(field, `${value}`, security)
          } else {
            manager.submitStagedOrder(security)
          }
          stopEditing()
        }
      },
      [field, manager, value, security, stopEditing]
    )

    const onInputDoubleckick = useCallback((e: MouseEvent) => {
      e.stopPropagation()
    }, [])

    const onCellFocus = useCallback(() => {
      if (security) manager.focusOrder(security.id)
    }, [manager])

    const stopMyEditing = useCallback(() => {
      stopEditing(true)
    }, [])

    return (
      <span
        className={classNames}
        data-testid={`my${manager.orderTypeName}${field}-${
          security?.isin ?? ''
        }`}
        onFocus={onCellFocus}
      >
        <input
          ref={refInput}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onDoubleClick={onInputDoubleckick}
          onBlur={stopMyEditing}
          onKeyDownCapture={onInputKeyDown}
          data-testid={`${security?.isin ?? ''}-${
            manager.orderTypeName
          }-${field}-input`}
          size={11}
        />
      </span>
    )
  }
)

export default MyOrderEditor
