import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { faBellPlus } from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { faBellPlus as finBell } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { useOpenFin } from '../../app/openFinContext'
import { fetchAlertFilters } from '../../store/alertFilter/actions'
import headerStyles from '../Header/header.scss'
import styles from './AlertFilter.scss'
import AlertFilterEditor from './AlertFilterEditor'

const AlertFilter: FC = () => {
  const { fin } = useOpenFin()
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAlertFilters())
  }, [active])

  const handleSetActive = useCallback(
    (b: boolean) => {
      setActive(b)
    },
    [active]
  )
  return (
    <div className={headerStyles.alertButton}>
      <span
        className={cx(
          fin ? styles.finButton : styles.button,
          active && styles.active
        )}
        onClick={() => setActive(!active)}
        data-tip="Alert Manager"
        data-for="alertManager"
      >
        <FontAwesomeIcon icon={fin ? finBell : faBellPlus} />
      </span>
      <ReactTooltip
        id="alertManager"
        backgroundColor="#e9edf1"
        textColor="#656C78"
        effect="solid"
        className="iconTooltip"
        place="bottom"
      >
        Alert Manager
      </ReactTooltip>
      {active && <AlertFilterEditor setActive={handleSetActive} />}
    </div>
  )
}

export default AlertFilter
