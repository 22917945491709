import React, { FC, useState } from 'react'
// @ts-ignore
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import gridStyles from '../../../assets/scss/grid.scss'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import ManagementReportWrapper from './ManagementReportWrapper'
import styles from './ManagementReports.scss'

const ManagementReportMainPage: FC = () => {
  const [date, setDate] = useState(new Date())

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader title="Management Reports" />
      <div className={styles.reportsContainer}>
        <DatePicker selected={date} onChange={(d: Date) => setDate(d)} />

        <ManagementReportWrapper date={date} />
      </div>
    </div>
  )
}

export default ManagementReportMainPage
