import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWindowMaximize,
  faWindowMinimize
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import React, { FC } from 'react'
import { formatMaturityDate } from '../../../helpers/formatting'
import { Styles } from '../../../helpers/types'
import { OrderStatus, OrderType } from '../../../store/order/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { SecurityModalInfo } from '../../../store/windows/types'

interface Props {
  type: OrderType
  orderStatusComp: OrderStatus | undefined
  security: SecurityStaticData
  securityModalInfo?: SecurityModalInfo
  // FIXME: define style classname types
  styles: Styles
  isLastLook: boolean
  isActiveWindow: boolean
  index: number
  handleMinimize: (i: number) => void
}

const HeaderModal: FC<Props> = (props) => {
  const {
    type,
    security,
    securityModalInfo,
    styles,
    isLastLook,
    handleMinimize,
    index,
    isActiveWindow,
    orderStatusComp
  } = props

  const firstBgColor =
    type === 'buy' ? styles.buyfirstBgColor : styles.sellfirstBgColor
  const secondBgColor =
    type === 'buy' ? styles.buySecondBgColor : styles.sellSecondBgColor

  const getModalTitle = () => {
    switch (orderStatusComp) {
      case 'accepted':
        return <span>{type === 'buy' ? 'Bought' : 'Sold'}</span>
      case 'rejected':
        return <span>Rejected</span>
      default:
        return <span>{type === 'buy' ? 'Buy' : 'Sell'}</span>
    }
  }

  return (
    <div
      className={`${styles.rectangleTitle} ${
        isActiveWindow ? styles.active : styles.inactive
      }`}
      onClick={
        !isActiveWindow
          ? () => handleMinimize && handleMinimize(index)
          : undefined
      }
    >
      <div
        className={`${styles.titleBox} ${secondBgColor} ${
          !isActiveWindow ? `${styles.title} ${styles.titleBoxInactive}` : null
        }`}
      >
        {getModalTitle()}
      </div>
      <div
        className={`${styles.titleCenter} ${firstBgColor}  ${
          !isActiveWindow
            ? `${styles.title} ${styles.titleCenterInactive}`
            : null
        }`}
      >
        <div className={styles.titleContent}>
          {!isLastLook && (
            <p className={styles.opacityContent}>
              Benchmark: {security.benchmarkName}
            </p>
          )}

          <p className={styles.firstLineContentTitle}>
            {security.issuerSymbol} {security.coupon}{' '}
            {formatMaturityDate(security.maturityDate, 'normal')}
            {!isLastLook && securityModalInfo && (
              <>
                <span className={styles.opacityContent}>
                  {' '}
                  {security.currency}
                </span>
                <span className={styles.issue}>{securityModalInfo.issue}</span>
              </>
            )}
          </p>
          <div
            className={`${styles.secondLineContentTitle} ${styles.opacityContent}`}
          >
            <span>CUSIP: {security.cusip}</span>
            <span>ISIN: {security.isin}</span>
          </div>
        </div>
      </div>
      {isActiveWindow ? (
        <div
          className={styles.minimize}
          onClick={() => handleMinimize && handleMinimize(index)}
        >
          <FontAwesomeIcon
            icon={faWindowMinimize}
            className={
              type === 'buy' ? styles.buyFirstColor : styles.sellFirstColor
            }
          />
        </div>
      ) : (
        <div className={styles.minimize}>
          <FontAwesomeIcon
            icon={faWindowMaximize}
            className={
              type === 'buy' ? styles.buyFirstColor : styles.sellFirstColor
            }
          />
        </div>
      )}
    </div>
  )
}

export default HeaderModal
