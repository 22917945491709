import React, { FC, FormEvent, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import TimePicker from 'react-time-picker'

// import { useOpenFin } from '../../app/openFinContext'
import { setUserPreferences } from '../../store/userPreferences/actions'
import { getUserPreferences } from '../../store/userPreferences/selectors'
import {
  showTOBSetting,
  showTsySetting
} from '../../store/webSettings/selectors'

import CheckBoxLabelAndInput from '../CheckBoxLabelAndInput/CheckBoxLabelAndInput'
import Checkbox from '../Checkbox'
import ComponentFooter from '../ComponentFooter/ComponentFooter'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import Modal from '../GenericModal/Modal'
import Radio from '../Radio'
import styles from './settingsModal.scss'

type ChangeEvent = FormEvent<HTMLInputElement>
interface Props {
  closeFunc: () => void
}

const SettingsModal: FC<Props> = (props: Props) => {
  // const { fin, closeChildWindow } = useOpenFin()
  const showTsy = useSelector(showTsySetting)
  const showTOB = useSelector(showTOBSetting)
  const { closeFunc } = props
  const userPreferences = useSelector(getUserPreferences)
  const dispatch = useDispatch()
  const openfinStartTime = userPreferences.openfinStartTime
    ? userPreferences.openfinStartTime
    : '8:00'
  const [ofStartTime, setOfStartTime] = useState(openfinStartTime)

  const [tradeSizeMin, setTradeSizeMin] = useState(
    userPreferences.minimumTradeSize !== 0
  )

  const [tradeSizeMax, setTradeSizeMax] = useState(
    userPreferences.maximumTradeSize !== null
  )

  const [inputTradeSizeMin, setInputTradeSizeMin] = useState(
    userPreferences.minimumTradeSize === 0
      ? ''
      : userPreferences.minimumTradeSize.toString()
  )

  const [inputTradeSizeMax, setInputTradeSizeMax] = useState(
    userPreferences.maximumTradeSize === null
      ? ''
      : userPreferences.maximumTradeSize.toString()
  )

  const [overrideDefault, setOverrideDefault] = useState(
    userPreferences.defaultBidOfferValue !== null
  )

  const [defaultBidOfferValue, setDefaultBidOfferValue] = useState(
    userPreferences.defaultBidOfferValue === null
      ? ''
      : userPreferences.defaultBidOfferValue.toString()
  )

  const [overrideTobPriceIncrement, setOverrideTobPriceIncrement] = useState(
    userPreferences.tobPriceIncrement !== null
  )

  const [tobPriceIncrement, setTobPriceIncrement] = useState(
    userPreferences.tobPriceIncrement === null
      ? ''
      : userPreferences.tobPriceIncrement.toString()
  )

  const [overrideTobSpreadIncrement, setOverrideTobSpreadIncrement] = useState(
    userPreferences.tobSpreadIncrement !== null
  )

  const [tobSpreadIncrement, setTobSpreadIncrement] = useState(
    userPreferences.tobSpreadIncrement === null
      ? ''
      : userPreferences.tobSpreadIncrement.toString()
  )

  const [useOpenfinAutoStart, setUseOpenfinAutostart] = useState(
    userPreferences.openfinAutoStart
  )

  const [muteNotifications, setMuteNotifications] = useState(
    userPreferences.muteNotifications
  )

  const [valueLastlookRadioPreference, setvalueLastlookRadioPreference] =
    useState(
      userPreferences.askForOrderConfirmation
        ? userPreferences.lastLookInPopup
          ? 'PopupAndActivityFeed'
          : 'InActivityFeed'
        : 'AutomaticallyAccept'
    )

  const [defaultSpotHedgePreference, setValueSpotHedgePreference] = useState(
    userPreferences.defaultSpotHedgePreference
      ? userPreferences.defaultSpotHedgePreference
      : 'S'
  )

  const setSpotHedgePreference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueSpotHedgePreference(event.target.value)
  }

  const [cancelBalanceUponPartialTrade, setCancelBalanceUponPartialTrade] =
    useState(userPreferences.cancelBalanceUponPartialTrade)

  const handleChangeTradeSizeMin = useCallback(() => {
    setTradeSizeMin(!tradeSizeMin)
  }, [tradeSizeMin])

  const handleChangeInputTradeSizeMin = useCallback(
    (event: ChangeEvent) => {
      setInputTradeSizeMin(event.currentTarget.value)
    },
    [inputTradeSizeMin]
  )
  const handleChangeTradeSizeMax = useCallback(() => {
    setTradeSizeMax(!tradeSizeMax)
  }, [tradeSizeMax])

  const handleChangeInputTradeSizeMax = useCallback(
    (event: ChangeEvent) => {
      setInputTradeSizeMax(event.currentTarget.value)
    },
    [inputTradeSizeMax]
  )
  const handleChangeOverrideDefault = useCallback(() => {
    setOverrideDefault(!overrideDefault)
  }, [overrideDefault])

  const handleChangeDefaultBidOfferValue = useCallback(
    (event: ChangeEvent) => {
      setDefaultBidOfferValue(event.currentTarget.value)
    },
    [defaultBidOfferValue]
  )

  const handleChangeOverrideTobPriceIncrement = useCallback(() => {
    setOverrideTobPriceIncrement(!overrideTobPriceIncrement)
  }, [overrideTobPriceIncrement])

  const handleChangeTobPriceIncrement = useCallback(
    (event: ChangeEvent) => {
      setTobPriceIncrement(event.currentTarget.value)
    },
    [tobPriceIncrement]
  )

  const handleChangeOverrideTobSpreadIncrement = useCallback(() => {
    setOverrideTobSpreadIncrement(!overrideTobSpreadIncrement)
  }, [overrideTobSpreadIncrement])

  const handleChangeTobSpreadIncrement = useCallback(
    (event: ChangeEvent) => {
      setTobSpreadIncrement(event.currentTarget.value)
    },
    [tobSpreadIncrement]
  )

  const handleCancelBalanceUponPartialTrade = useCallback(() => {
    setCancelBalanceUponPartialTrade(!cancelBalanceUponPartialTrade)
  }, [cancelBalanceUponPartialTrade])

  const handleChangeUseOpenfinAutostart = useCallback(() => {
    setUseOpenfinAutostart(!useOpenfinAutoStart)
  }, [useOpenfinAutoStart])

  const handleMuteNotifications = useCallback(() => {
    setMuteNotifications(!muteNotifications)
  }, [muteNotifications])

  const handleValidatePreferences = () => {
    const newPreferences = {
      ...userPreferences,
      lastLookInPopup: valueLastlookRadioPreference === 'PopupAndActivityFeed',
      minimumTradeSize: tradeSizeMin ? Number(inputTradeSizeMin) : 0,
      maximumTradeSize: tradeSizeMax ? Number(inputTradeSizeMax) : null,
      cancelBalanceUponPartialTrade,
      askForOrderConfirmation:
        valueLastlookRadioPreference === 'InActivityFeed' ||
        valueLastlookRadioPreference === 'PopupAndActivityFeed',
      openfinAutoStart: useOpenfinAutoStart,
      openfinStartTime: ofStartTime,
      muteNotifications,
      defaultSpotHedgePreference,
      defaultBidOfferValue: overrideDefault
        ? Number(defaultBidOfferValue)
        : null,
      tobPriceIncrement: overrideTobPriceIncrement
        ? Number(tobPriceIncrement)
        : null,
      tobSpreadIncrement: overrideTobSpreadIncrement
        ? Number(tobPriceIncrement)
        : null
    }
    dispatch(setUserPreferences(newPreferences))
    // fin ? closeChildWindow('Settings') : closeFunc()
    closeFunc()
  }

  const setLastLookPreferences = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setvalueLastlookRadioPreference(event.target.value)
  }

  const contentModal = (
    <div>
      <ComponentHeader title={'Trader Preferences'} />
      <main className={styles.contentContainer}>
        <Checkbox
          locator="mute-notifications"
          onChange={handleMuteNotifications}
          fillColor="#43A980"
          checked={muteNotifications}
        >
          Mute notification sounds
        </Checkbox>
        <Checkbox
          locator="cancel-balance-upon-partial-trade"
          onChange={handleCancelBalanceUponPartialTrade}
          fillColor="#43A980"
          checked={cancelBalanceUponPartialTrade}
        >
          Cancel balance upon partial trade
        </Checkbox>
        <CheckBoxLabelAndInput
          checked={tradeSizeMin}
          onChangeCheckBox={handleChangeTradeSizeMin}
          labelCheckBox="Trade size minimum"
          testid="tradesize"
          isNumber={true}
          inputValue={inputTradeSizeMin}
          onChangeInput={handleChangeInputTradeSizeMin}
          otherContent="(k)"
        />
        <CheckBoxLabelAndInput
          checked={tradeSizeMax}
          disabled={userPreferences.maximumTradeSizeIsHardLimit}
          onChangeCheckBox={handleChangeTradeSizeMax}
          labelCheckBox="Trade size maximum"
          testid="tradesizeMax"
          isNumber={true}
          inputValue={inputTradeSizeMax}
          onChangeInput={handleChangeInputTradeSizeMax}
          otherContent="(k)"
        />
        <div className={styles.autoStart}>
          <Checkbox
            locator="use-openfin-autostart"
            onChange={handleChangeUseOpenfinAutostart}
            fillColor="#43A980"
            checked={useOpenfinAutoStart}
          >
            Autostart Openfin app at:
          </Checkbox>
          <TimePicker
            disabled={!useOpenfinAutoStart}
            value={ofStartTime}
            onChange={setOfStartTime}
            className={styles.picker}
            clearIcon={null}
            clockIcon={null}
            disableClock={true}
          />
        </div>

        <span className={styles.lastLookLabel}>
          Ask for confirmation before accepting orders:
        </span>

        <Radio
          locator="PopupAndActivityFeed"
          name="lastlook"
          value="PopupAndActivityFeed"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'PopupAndActivityFeed'}
        >
          In last look pop-out and activity feed
        </Radio>
        <Radio
          locator="InActivityFeed"
          name="lastlook"
          value="InActivityFeed"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'InActivityFeed'}
        >
          In activity feed only
        </Radio>
        <Radio
          locator="AutomaticallyAccept"
          name="lastlook"
          value="AutomaticallyAccept"
          onChange={setLastLookPreferences}
          checked={valueLastlookRadioPreference === 'AutomaticallyAccept'}
        >
          Don't ask for confirmation (automatically accept orders)
        </Radio>

        {showTsy && (
          <div>
            <span className={styles.lastLookLabel}>
              Auto-Spot/Hedge preference:
            </span>

            <Radio
              locator="Spot"
              name="spotHedge"
              value="S"
              onChange={setSpotHedgePreference}
              checked={defaultSpotHedgePreference === 'S'}
            >
              Auto-Spot
            </Radio>

            <Radio
              locator="Hedge"
              name="spotHedge"
              value="H"
              onChange={setSpotHedgePreference}
              checked={defaultSpotHedgePreference.startsWith('H')}
            >
              Auto-Hedge
            </Radio>
          </div>
        )}
        <CheckBoxLabelAndInput
          checked={overrideDefault}
          onChangeCheckBox={handleChangeOverrideDefault}
          labelCheckBox="Override Default Bid/Offer Size"
          testid="overrideDefaultBidOffer"
          isNumber={true}
          inputValue={defaultBidOfferValue}
          onChangeInput={handleChangeDefaultBidOfferValue}
          otherContent="(k)"
        />
        {showTOB && (
          <>
            <CheckBoxLabelAndInput
              checked={overrideTobPriceIncrement}
              onChangeCheckBox={handleChangeOverrideTobPriceIncrement}
              labelCheckBox="TOB Price increment"
              testid="tobPriceIncrement"
              isNumber={true}
              inputValue={tobPriceIncrement}
              onChangeInput={handleChangeTobPriceIncrement}
            />
            <CheckBoxLabelAndInput
              checked={overrideTobSpreadIncrement}
              onChangeCheckBox={handleChangeOverrideTobSpreadIncrement}
              labelCheckBox="TOB Spread increment"
              testid="tobSpreadIncrement"
              isNumber={true}
              inputValue={tobSpreadIncrement}
              onChangeInput={handleChangeTobSpreadIncrement}
            />
          </>
        )}
      </main>
      <ComponentFooter
        onCancelClick={closeFunc}
        onApplyClick={handleValidatePreferences}
      />
    </div>
  )
  // return <>{fin ? contentModal : <Modal>{contentModal}</Modal>}</>
  return <Modal>{contentModal}</Modal>
}

export default SettingsModal
