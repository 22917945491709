import * as Sentry from '@sentry/react'
import { SentryAction } from './action'

export interface State {
  replay?: Sentry.Replay
}

export const initialState: State = {}

export const reducer = (state = initialState, action: SentryAction): State => {
  switch (action.type) {
    case 'sentry.saveReplayInstance':
      return { ...state, replay: action.payload }
  }
  return state
}
