import React, { MouseEvent } from 'react'
import Cancel from '../../ActionIcon/cancel'
import styles from './DepthOfMarket.scss'

type Props = {
  onClick?: (e: MouseEvent) => void
  title?: string
  testId?: string
}
const CancelButton = ({ onClick, testId, title }: Props) => {
  return (
    <div
      onClick={onClick}
      className={styles.cancelButton}
      data-testid={testId ?? 'cancel-depth-order'}
      title={title}
    >
      <Cancel />
    </div>
  )
}

export default CancelButton
