import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../app/openFinContext'
import { abbreviateVolume } from '../../helpers/formatting'
import { getBenchmarkData } from '../../store/benchmarkData/selectors'
import { BenchmarkList } from '../../store/benchmarkData/types'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import styles from './benchmarkData.scss'

const BenchmarkData = () => {
  const { fin } = useOpenFin()
  const benchmarkData = useSelector(getBenchmarkData)

  return !benchmarkData ? null : (
    <div className={fin ? styles.finSizing : styles.webSizing}>
      {fin && <ComponentHeader title="Benchmarks" />}
      <div className={cx(styles.benchmarkContainer, !fin && styles.addHeight)}>
        <div className={cx(styles.benchmarkTitle, fin && styles.finTitle)}>
          Live on <br /> BondsPro:
        </div>
        <div className={styles.benchmarkData}>
          {benchmarkData.totalOrders.toLocaleString()}{' '}
          <div className={styles.benchmarkLabel}>TOTAL ORDERS</div>
        </div>
        <div className={styles.benchmarkData}>
          {abbreviateVolume(benchmarkData.notional * 1000)}{' '}
          <div className={styles.benchmarkLabel}>NOTIONAL</div>
        </div>
        <div className={cx(styles.benchmarkTitle, fin && styles.finTitle)}>
          Treasury <br /> Benchmarks:
        </div>
        {benchmarkData.benchmarkData.map((item: BenchmarkList) => (
          <div key={item.id} className={styles.table}>
            <div
              className={cx(styles.benchmarkYear, styles.benchmarkYearLabel)}
            >
              {item.benchmarkName}
            </div>
            <div className={styles.benchmarkYear}>
              {item.bestBid}/{item.bestOffer}
            </div>
            <div className={styles.benchmarkYear}>
              {item.yield ? item.yield.toFixed(2) : 'N/A'}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BenchmarkData
