import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import {
  map,
  mergeMap,
  mergeMapTo,
  switchMap,
  tap,
  throttleTime
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { getUserIdFromToken } from '../auth/selectors'
import { setUserPreferences } from '../userPreferences/actions'
import { forceReload } from '../ws/actions'
import {
  HandleSettingsAction,
  loadSettings,
  loadWindowSettings
} from './actions'
import {
  clearStorage,
  defaultBackendSettings,
  getSettingsFromStorage,
  saveStateInStorage,
  settingsObject
} from './helpers'
import { getStateValuesToSave } from './selectors'
import { initialState as initialPreferences } from '../userPreferences/reducer'

const loadSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleSettings'),
    mergeMap((action: HandleSettingsAction) => {
      const settings = getSettingsFromStorage(
        action.payload.stateValuesToSave,
        getUserIdFromToken(state$.value)
      )

      return of(loadSettings(settings))
    })
  )

const loadWindowSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    switchMap(() =>
      getHub()
        .invoke('GetWindowSettings')
        .pipe(map((settings) => loadWindowSettings(settings)))
    )
  )

const saveSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleSettings'),
    mergeMap((action: HandleSettingsAction) => {
      return action$.pipe(
        ofType(...action.payload.actionTypesToTriggerSaving),
        tap(() => {
          saveStateInStorage(
            state$.value,
            action.payload.stateValuesToSave,
            getUserIdFromToken(state$.value)
          )
        }),
        mergeMapTo(EMPTY)
      )
    })
  )

const saveWindowSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.handleWindowSettings'),
    switchMap((action: HandleSettingsAction) => {
      return action$.pipe(
        // tap(action$ => console.log(action$)),
        ofType(...action.payload.actionTypesToTriggerSaving),
        throttleTime(1500),
        // tap(action$ => console.log(action$.type)),
        switchMap(() =>
          getHub()
            .invoke('SaveWindowSettings', settingsObject(state$))
            .pipe(mergeMapTo(EMPTY))
        )
      )
    })
  )

const clearSettingsEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('settings.clearSettings'),
    tap(() => {
      const valuesToSave = getStateValuesToSave(state$.value)
      clearStorage(valuesToSave)
    }),
    mergeMap(() => {
      return getHub().invoke('SaveWindowSettings', defaultBackendSettings)
    }),
    mergeMap(() =>
      of(setUserPreferences(initialPreferences.userPreferences), forceReload())
    )
  )

export default combineEpics(
  loadSettingsEpic,
  saveSettingsEpic,
  clearSettingsEpic,
  loadWindowSettingsEpic,
  saveWindowSettingsEpic
)
