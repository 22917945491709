import {
  CellEditingStoppedEvent,
  CellFocusedEvent,
  CellKeyDownEvent,
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  PasteEndEvent,
  ProcessDataFromClipboardParams,
  RowClassRules
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { OrderType } from '../../../store/order/types'
import { showTitle } from '../../DepthOfMarket/helpers'
import { addErrorMessage, createNewRow } from '../helpers'
import { RowSetterFn } from './EnhancedUploadDropdownMenu'
import { EnhancedOrderUploadRow } from './types'

import styles from '../uploadDropDownMenu.scss'

interface Props {
  isIceberg: boolean
  setRows: (rows: EnhancedOrderUploadRow[] | RowSetterFn) => void
  rows: EnhancedOrderUploadRow[]
}

type GridType = EnhancedOrderUploadRow
type ColTypes = ColDef<GridType> | ColGroupDef<GridType>
const getRowId: GetRowIdFunc<GridType> = ({ data }) => {
  return `${data?.index ?? ''}`
}

const defaultColumn: ColDef<GridType> = {
  editable: true,
  lockPinned: true,
  minWidth: 10,
  menuTabs: [],
  singleClickEdit: true,
  sortable: false,
  suppressAutoSize: true,
  suppressColumnsToolPanel: true,
  suppressPaste: true,
  tooltipField: 'errMsg',
  width: 60
}

const getSizeColumn = (
  type: OrderType,
  isIceberg: boolean
): ColDef<GridType> | ColGroupDef<GridType> => {
  if (isIceberg) {
    const children = [
      {
        field: `${type}Details.size.displaySize`,
        headerName: 'Show Size',
        headerTooltip: showTitle,
        width: 70
      } as const,
      {
        colId: `${type}Total`,
        field: `${type}Details.size.totalSize`,
        headerName: 'Total Size',
        width: 70
      } as const
    ]
    return {
      groupId: `${type}Amt`,
      headerName: 'Amt',
      children: type === 'sell' ? children : children.reverse()
    }
  }
  return {
    headerName: 'Amt',
    field: `${type}Details.size`
  }
}

const getPriceColumn = (
  type: OrderType /* pegged: boolean*/
): ColDef<GridType> | ColGroupDef<GridType> => {
  // TODO: if pegged then return group with floor and limit child columns
  return {
    headerName: 'Price',
    field: `${type}Details.priceAmount`
  }
}
const getSpreadColumn = (
  type: OrderType /* pegged: boolean*/
): ColDef<GridType> | ColGroupDef<GridType> => {
  // TODO: if pegged then return group with floor and limit child columns
  return {
    headerName: 'Spread',
    field: `${type}Details.spreadAmount`
  }
}

// https://stackoverflow.com/a/58436959
// note types here are not working correctly--need more time here
type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Paths<T[K]> extends never
        ? ''
        : `.${Paths<T[K]>}`}`
    }[keyof T]
  : never

type ChildProps<T> = T extends object
  ? { [K in keyof T]: ChildProps<T[K]> }
  : never

type ValidPaths = Paths<Exclude<EnhancedOrderUploadRow, 'index'>>
type ValidChildren = ChildProps<EnhancedOrderUploadRow>

const addPaths = (existingPaths: ValidPaths[], def: ColTypes) => {
  if ('field' in def) {
    existingPaths.push(def.field as unknown as ValidPaths)
    return existingPaths
  }
  if ('children' in def) {
    def.children.reduce(addPaths, existingPaths)
  }
  return existingPaths
}

// TODO: lodash makes this much easier and typesafe
const populateRowField = (
  row: EnhancedOrderUploadRow,
  path: ValidPaths,
  value: string
) => {
  if (!path) return row
  const segments = path.split('.')
  let obj: EnhancedOrderUploadRow | ValidChildren = row
  let segmentCount = 0
  while (segmentCount < segments.length - 1) {
    type Segment = keyof typeof obj
    const segment: Segment = segments[segmentCount] as unknown as Segment
    // @ts-ignore
    obj = obj[segment]
    segmentCount++
  }
  const finalSegment = segments[segmentCount]
  // @ts-ignore
  obj[finalSegment] = value
  return row
}

const Grid = ({ rows, setRows, isIceberg }: Props) => {
  const classes = cx('ag-theme-balham', styles.grid)
  const errClass = styles.errorRow

  // ------------------ grid definition ------------------ //
  const gridRef = useRef<AgGridReact<GridType>>(null)

  const columnDefs = useMemo((): ColTypes[] => {
    const errCols: Array<ColDef<GridType>> = rows.some((row) => row.errMsg)
      ? [
          {
            colId: 'error',
            editable: false,
            field: 'errMsg',
            headerName: 'Error',
            headerClass: styles.headerCell,
            width: 220
          }
        ]
      : []
    return [
      {
        field: 'identifier',
        headerName: 'Ticker / ISIN / CUSIP',
        headerClass: styles.headerCell,
        singleClickEdit: false,
        suppressPaste: false,
        flex: 2
      },
      {
        groupId: 'bid',
        headerName: 'Bid (optional)',
        headerClass: styles.headerCell,
        children: [
          getSizeColumn('buy', isIceberg),
          getPriceColumn('buy'),
          getSpreadColumn('buy')
        ]
      },
      {
        groupId: 'offer',
        headerName: 'Offer (optional)',
        headerClass: styles.headerCell,
        children: [
          getSpreadColumn('sell'),
          getPriceColumn('sell'),
          getSizeColumn('sell', isIceberg)
        ]
      },
      ...errCols
    ]
  }, [isIceberg, rows])

  const getContextMenuItems = useCallback(() => {
    return ['csvExport']
  }, [])

  const rowClassRules = useMemo<RowClassRules<GridType>>(() => {
    return {
      [errClass]: ({ data }) => !!data?.errMsg
    }
  }, [errClass])

  // ------------------ interaction ------------------ //
  const [lastEditedIndex, setLastEditedIndex] = useState('')
  const [rowToFocus, setRowToFocus] = useState(-1)

  const onCellEditingStopped = useCallback(
    ({ data, rowIndex, valueChanged }: CellEditingStoppedEvent<GridType>) => {
      if (!data || !valueChanged) return
      setLastEditedIndex(`${data.index}`)
      setRows((oldRows) => {
        const hadErr = !!data.errMsg
        const newData = addErrorMessage(data)
        // only remove err, don't add here
        const existingRows =
          hadErr && !newData.errMsg
            ? oldRows.map((row) =>
                row.index === newData.index ? newData : row
              )
            : oldRows
        // ensure we always have a row to enter data into
        const extraRows: EnhancedOrderUploadRow[] = []
        if (valueChanged && rowIndex === oldRows.length - 1) {
          const maxIndex = Math.max(...existingRows.map((row) => row.index))
          extraRows.push(createNewRow(maxIndex + 1, '', isIceberg))
        }
        return [...existingRows, ...extraRows]
      })
    },
    [setRows]
  )
  const onCellFocused = useCallback(
    ({ api, rowIndex }: CellFocusedEvent<GridType>) => {
      const node = api.getDisplayedRowAtIndex(rowIndex || -1)
      const idx = parseInt(lastEditedIndex, 10)
      if (!idx || node?.data?.index === idx) return
      setRows((oldRows) =>
        oldRows.map((row) => (row.index === idx ? addErrorMessage(row) : row))
      )
      setLastEditedIndex('')
    },
    [rows]
  )
  const onCellKeyDown = useCallback(
    ({ event, data, rowIndex, api }: CellKeyDownEvent<GridType>) => {
      const ke = event as KeyboardEvent
      if (ke.key === 'Enter' && data) {
        if (rowIndex !== null) {
          setLastEditedIndex('')

          const extraRows: EnhancedOrderUploadRow[] = []
          const maxIndex = Math.max(...rows.map((r) => r.index))
          if (rowIndex === rows.length - 1) {
            extraRows.push(createNewRow(maxIndex + 1, '', isIceberg))
          }
          const newRows = [
            ...rows.map((r) =>
              r.index === data.index ? addErrorMessage(r) : r
            ),
            ...extraRows
          ]

          setRows(newRows)

          api.setFocusedCell(
            Math.min(rowIndex + 1, newRows.length - 1),
            'identifier'
          )
        }
      }
    },
    [rows, isIceberg, lastEditedIndex]
  )

  const processPastedData = useCallback(
    ({ data, api }: ProcessDataFromClipboardParams<GridType>) => {
      const focusedCell = api.getFocusedCell()
      if (!focusedCell) return data
      const rowIndex = focusedCell.rowIndex

      const maxIndex = Math.max(Math.max(...rows.map((row) => row.index)), -1)

      // figure out what column of the pasted data goes to what field
      const paths: ValidPaths[] = []
      const cols = gridRef.current?.api.getColumnDefs()
      if (cols) {
        cols.reduce(addPaths, paths)
      }
      const replaceRows = data
        .filter((dataRow) => dataRow.join(''))
        .map((dataRow, i) => {
          const identifier = dataRow[0]
          const newRow = createNewRow(maxIndex + (i + 1), identifier, isIceberg)
          dataRow.forEach((value, j) => {
            // already used identifier
            if (j === 0) return
            const path = paths[j]
            if (path && path !== 'errMsg') {
              populateRowField(newRow, path, value)
            }
          })
          return addErrorMessage(newRow)
        })
      const beforeRows = rowIndex > 0 ? rows.slice(0, rowIndex) : []
      const endIdx = rowIndex + replaceRows.length
      const afterRows =
        endIdx < rows.length - 1
          ? rows.slice(endIdx)
          : [createNewRow(maxIndex + (data.length + 1), '', isIceberg)]
      const updatedRows = [...beforeRows, ...replaceRows, ...afterRows]
      setRows(updatedRows)
      // set up to open next row for editing when paste finished
      // or first error row if there are errors
      const errIdx = updatedRows.findIndex((r) => r.errMsg)
      setRowToFocus(errIdx === -1 ? rowIndex : errIdx)
      return data.filter((d) => d.join(''))
    },
    [setRows, rows, isIceberg]
  )
  const onPasteEnd = useCallback(
    ({ api }: PasteEndEvent) => {
      if (rowToFocus > -1) {
        api.ensureIndexVisible(rowToFocus)
        api.startEditingCell({ rowIndex: rowToFocus, colKey: 'identifier' })
        setRowToFocus(-1)
      }
    },
    [rowToFocus]
  )

  useDebounce(
    () => {
      if (isIceberg && gridRef.current?.api) {
        gridRef.current.api.flashCells({ columns: ['buyTotal', 'sellTotal'] })
      }
    },
    200,
    [isIceberg]
  )

  return (
    <div className={classes} data-testid="enhanced-upload-grid">
      <AgGridReact<GridType>
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColumn}
        getRowId={getRowId}
        rowData={rows}
        getContextMenuItems={getContextMenuItems}
        onCellEditingStopped={onCellEditingStopped}
        onCellFocused={onCellFocused}
        onCellKeyDown={onCellKeyDown}
        onPasteEnd={onPasteEnd}
        processDataFromClipboard={processPastedData}
        stopEditingWhenCellsLoseFocus={true}
        rowSelection="multiple"
        headerHeight={20}
        groupHeaderHeight={20}
        rowHeight={20}
        rowClassRules={rowClassRules}
      />
    </div>
  )
}

export default Grid
