import { OrderEntryModel } from '../../store/depthOfMarket/types'
import {
  OrderType,
  TobParameters,
  ValidationResult
} from '../../store/order/types'
import { StagedOrder } from '../../store/stagedOrders/types'
import { OrderRowInfo } from '../../store/upload/types'
import { emptyTob } from '../DepthOfMarket/helpers'
import {
  EnhancedOrderUploadRow,
  IcebergSize,
  UploadTob
} from './EnhancedUpload/types'

export const initialRowInfo: OrderRowInfo = {
  index: 0,
  identifier: '',
  bidAmt: '',
  bidPrice: '',
  bidSprd: '',
  ofrAmt: '',
  ofrPrice: '',
  ofrSprd: '',
  bidAon: false,
  ofrAon: false
}

// this will give the rows their 'correct' indices
export const displayInitialTableRows = (
  numRows: number,
  startIndex: number = 0
) =>
  Array(numRows)
    .fill(initialRowInfo)
    .map((row, index) => ({ ...row, index: index + startIndex }))

export const pasteAndConvertAnArray = (content: string) => {
  const result = []
  if (content.includes('\n')) {
    const multiLineArray = content.split('\n')

    multiLineArray.forEach((element) => {
      if (element) {
        result.push(element.split('\t'))
      }
    })
  } else if (content.includes('\t')) {
    result.push(content.split('\t'))
  }
  return result
}

const trimPrice = (price: any) => price?.replace('$', '').trim() || ''

const simpleValueParse = (value: string | undefined) => value?.trim() || ''

const allOrNoneParse = (value: string | undefined) =>
  value?.toLowerCase().endsWith('a') || false

export const orderIsValidForIdentifier = (identifier: string) => {
  const digits = /\d+/
  return digits.test(identifier)
}

export const createArrayUploadOrder = (
  pastValue: string,
  indexInsert: number
): OrderRowInfo[] =>
  pasteAndConvertAnArray(pastValue)
    .filter((content) => content[0].trim() !== headersToCopy[0])
    .map((content, index) => {
      const identifier = simpleValueParse(content[0])
      const canCreateOrder = orderIsValidForIdentifier(identifier)

      return {
        index: indexInsert + index,
        identifier,
        bidAmt: canCreateOrder ? simpleValueParse(content[1]) : '',
        bidAon: canCreateOrder ? allOrNoneParse(content[1]) : false,
        bidPrice: canCreateOrder ? trimPrice(content[2]) : '',
        bidSprd: canCreateOrder ? simpleValueParse(content[3]) : '',
        ofrSprd: canCreateOrder ? simpleValueParse(content[4]) : '',
        ofrPrice: canCreateOrder ? trimPrice(content[5]) : '',
        ofrAmt: canCreateOrder ? simpleValueParse(content[6]) : '',
        ofrAon: canCreateOrder ? allOrNoneParse(content[6]) : false
      }
    })

export const headersToCopy = [
  'Ticker / ISIN / CUSIP',
  'Bid Amt',
  'Bid Price',
  'Bid Spread',
  'Ofr Spread',
  'Ofr Price',
  'Ofr Amt'
]

export const reduceDataToCopy = (data: OrderRowInfo[]) => {
  const clipboardString: string = data
    .map((row) =>
      [
        row.identifier,
        row.bidAmt,
        row.bidPrice,
        row.bidSprd,
        row.ofrSprd,
        row.ofrPrice,
        row.ofrAmt
      ].join('\t')
    )
    .join('\t\n')
  return headersToCopy.join('\t') + '\n' + clipboardString
}

export const invalidDataToCopy = (data: ValidationResult[]) => {
  return data
    .map((row) =>
      [row.isin, row.orderType === 'buy' ? 'Bid' : 'Ofr'].join('\t')
    )
    .join('\t\n')
}
export const createUploadOrder = <T extends OrderType>(
  type: T,
  isIceberg: boolean,
  isTob: boolean
) => ({
  type,
  size: isIceberg ? { displaySize: '', totalSize: '' } : '',
  priceAmount: isTob ? { limit: '', floor: '' } : '',
  spreadAmount: isTob ? { limit: '', floor: '' } : ''
})
export const createNewRow = (
  index: number,
  identifier: string,
  isIceberg: boolean,
  isTob: boolean = false
): EnhancedOrderUploadRow => {
  return {
    index,
    identifier,
    buyDetails: createUploadOrder('buy', isIceberg, isTob),
    sellDetails: createUploadOrder('sell', isIceberg, isTob)
  }
}

const isEmptySize = (size: string | IcebergSize) => {
  if (typeof size === 'string') {
    return !parseInt(size, 10)
  }
  return !parseInt(size.displaySize, 10) && !parseInt(size.totalSize, 10)
}

const isEmptyAmount = (amount: string | UploadTob) => {
  if (typeof amount === 'string') {
    return !amount
  }
  // TODO actual TOB logic
  return true
}

type DetailType =
  | EnhancedOrderUploadRow['buyDetails']
  | EnhancedOrderUploadRow['sellDetails']

const hasBothPriceAndSpread = (order: DetailType) => {
  return !isEmptyAmount(order.priceAmount) && !isEmptyAmount(order.spreadAmount)
}

const hasPriceOrSpread = (order: DetailType) => {
  return !isEmptyAmount(order.priceAmount) || !isEmptyAmount(order.spreadAmount)
}

export const addErrorMessage = (
  row: EnhancedOrderUploadRow
): EnhancedOrderUploadRow => {
  if (
    !row.identifier &&
    (hasPriceOrSpread(row.buyDetails) || hasPriceOrSpread(row.sellDetails))
  ) {
    return { ...row, errMsg: 'Please enter an identifier.' }
  }
  const digits = /\d+/
  const isTicker = !digits.test(row.identifier ?? '')
  if (
    isTicker &&
    (hasPriceOrSpread(row.buyDetails) || hasPriceOrSpread(row.sellDetails))
  ) {
    return { ...row, errMsg: "Can't create orders for tickers." }
  }
  if (
    hasBothPriceAndSpread(row.buyDetails) ||
    hasBothPriceAndSpread(row.sellDetails)
  ) {
    return { ...row, errMsg: 'Please enter price OR spread (not both).' }
  }
  if (
    (hasPriceOrSpread(row.buyDetails) && isEmptySize(row.buyDetails.size)) ||
    (hasPriceOrSpread(row.sellDetails) && isEmptySize(row.sellDetails.size))
  ) {
    return { ...row, errMsg: 'Please enter an amount.' }
  }
  if (
    (!isEmptySize(row.buyDetails.size) && !hasPriceOrSpread(row.buyDetails)) ||
    (!isEmptySize(row.sellDetails.size) && !hasPriceOrSpread(row.sellDetails))
  ) {
    return { ...row, errMsg: 'Please enter a price or spread.' }
  }
  return { ...row, errMsg: '' }
}
export const createGridRows = (
  identifiers: string[],
  isIceberg: boolean,
  isTob: boolean = false
): EnhancedOrderUploadRow[] => {
  return [...identifiers, ''].map((id, i) =>
    createNewRow(i, id, isIceberg, isTob)
  )
}

export const dateFormat = 'M/DD/YY, h:mm a'

const sizeToArr = (size: string | IcebergSize) => {
  if (typeof size === 'string') return [size]
  return [size.displaySize, size.totalSize]
}
export const copyRow = (row: EnhancedOrderUploadRow) => {
  return [
    row.identifier,
    ...sizeToArr(row.buyDetails.size),
    row.buyDetails.priceAmount,
    row.buyDetails.spreadAmount,
    row.sellDetails.spreadAmount,
    row.sellDetails.priceAmount,
    ...sizeToArr(row.sellDetails.size).reverse()
  ].join('\t')
}

const getAmount = (amount: string | UploadTob) => {
  if (typeof amount === 'string') {
    return amount
  }
  return {
    tob: true,
    limitPrice: parseInt(amount.limit, 10),
    floorPrice: parseInt(amount.floor, 10)
  } as TobParameters
}

/*  TODO: I thought we would use this for the actual uploads, because
    I thought we would be doing validations prior to upload.
    We may still decide to do that, but the old logic didn't.
 */
const createOrderEntryModelFromUploadOrder = (
  {
    type,
    size,
    priceAmount,
    spreadAmount
  }:
    | EnhancedOrderUploadRow['buyDetails']
    | EnhancedOrderUploadRow['sellDetails'],
  selectedBook?: number
) => {
  const sizes = sizeToArr(size)
  // if they only provided one value, it's totalsize
  const totalSize = (sizes.length === 1 ? sizes[0] : sizes[1]) || ''
  const displaySize = sizes.length === 1 ? '' : sizes[0] || ''
  const amountType = !isEmptyAmount(priceAmount) ? 'price' : 'spread'
  const amount =
    amountType === 'price' ? getAmount(priceAmount) : getAmount(spreadAmount)
  const aon = sizes.some(
    (aonSize) => aonSize.endsWith('a') && parseInt(aonSize, 10)
  )
  return {
    type,
    amountType,
    displaySize,
    totalSize,
    minSize: '',
    amount,
    selectedBook,
    isLive: false,
    aon
  } as const
}

export const createOrderEntryModelsFromUploadRow = (
  row: EnhancedOrderUploadRow,
  bookId?: number
) => {
  const result: OrderEntryModel[] = []
  if (hasPriceOrSpread(row.buyDetails)) {
    result.push(createOrderEntryModelFromUploadOrder(row.buyDetails, bookId))
  }
  if (hasPriceOrSpread(row.sellDetails)) {
    result.push(createOrderEntryModelFromUploadOrder(row.sellDetails, bookId))
  }
  return result
}

const getStagedSalesAmount = (amount: string | UploadTob) => {
  if (typeof amount === 'string') {
    return parseFloat(amount)
  }
  return parseFloat(amount.floor)
}

const createStagedOrderFromUploadOrder = (
  securityId: number,
  {
    type,
    size,
    priceAmount,
    spreadAmount
  }:
    | EnhancedOrderUploadRow['buyDetails']
    | EnhancedOrderUploadRow['sellDetails'],
  selectedBook?: number
) => {
  // This is hackey--I hadn't realized we'd need to create staged orders from this when I started down this road
  const sizes = sizeToArr(size)
  // if they only provided one value, it's displaySize
  const totalSize = parseInt(sizes[1], 10) || undefined
  const displaySize = parseInt(sizes[0], 10) || 0
  const isSpreadOrder = !isEmptyAmount(spreadAmount)
  const orderAmount = isSpreadOrder ? spreadAmount : priceAmount
  const isPeggedOrder = !(typeof orderAmount === 'string')
  const tob = isPeggedOrder
    ? (getAmount(orderAmount) as TobParameters)
    : emptyTob

  const price = getStagedSalesAmount(priceAmount) || undefined
  const spread = getStagedSalesAmount(spreadAmount) || undefined
  const allOrNone = sizes.some(
    (aonSize) => aonSize.endsWith('a') && parseInt(aonSize, 10)
  )
  const result: StagedOrder = {
    orderType: type,
    securityId,
    price,
    spread,
    isSpreadOrder,
    size: displaySize,
    totalSize,
    allOrNone,
    individualMin: 0,
    custId: selectedBook ?? 0,
    tob
  } as const
  return result
}
export const createStagedOrdersFromUploadRow = (
  securityId: number,
  row: EnhancedOrderUploadRow,
  bookId?: number
) => {
  const result: StagedOrder[] = []
  if (hasPriceOrSpread(row.buyDetails)) {
    result.push(
      createStagedOrderFromUploadOrder(securityId, row.buyDetails, bookId)
    )
  }
  if (hasPriceOrSpread(row.sellDetails)) {
    result.push(
      createStagedOrderFromUploadOrder(securityId, row.sellDetails, bookId)
    )
  }
  return result
}
