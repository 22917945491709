import React, { FC, MouseEvent, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import stop_sign from '../../assets/images/stop_sign.svg'

import {
  cancelCheckedOrders,
  submitCheckedOrders
} from '../../store/checkedOrders/actions'
import {
  getNumberOfCancellableOrders,
  getNumberOfCheckedOrders,
  getNumberOfCheckedStagedOrderIsNotLive
} from '../../store/checkedOrders/selectors'
import { clearCheckedStagedOrders } from '../../store/stagedOrders/actions'
import Play from '../ActionIcon/play'
import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup'
import styles from './MyOrdersFooter.scss'

const MyOrdersControlButtons: FC = () => {
  const dispatch = useDispatch()
  const numberOfCancellableOrders = useSelector(getNumberOfCancellableOrders)
  const numberOfSubmittableOrders = useSelector(
    getNumberOfCheckedStagedOrderIsNotLive
  )
  const numberOfClearableOrders = useSelector(getNumberOfCheckedOrders)

  const handleCancel = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(cancelCheckedOrders())
  }, [])

  const handleclear = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(clearCheckedStagedOrders())
  }, [])

  const handleSubmit = useCallback((event: MouseEvent) => {
    event.preventDefault()
    dispatch(submitCheckedOrders())
  }, [])
  return (
    <div className={styles.footer}>
      <div className={styles.buttons}>
        <ButtonGroup>
          <Button
            onClick={handleclear}
            data-testid="myorders-clearbutton"
            disabled={numberOfClearableOrders === 0}
          >
            Clear ({numberOfClearableOrders})
          </Button>
          <Button
            onClick={handleCancel}
            data-testid="myorders-stopbutton"
            disabled={numberOfCancellableOrders === 0}
          >
            <img
              src={stop_sign}
              className={styles.stopIcon}
              alt="stop sign icon"
            />
            Stop ({numberOfCancellableOrders})
          </Button>
          <Button
            onClick={handleSubmit}
            data-testid="myorders-submitbutton"
            disabled={numberOfSubmittableOrders === 0}
          >
            <Play />
            Submit ({numberOfSubmittableOrders})
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default MyOrdersControlButtons
