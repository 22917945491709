import cx from 'classnames'

import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { ExportFormat, formatQuery, RuleGroupType } from 'react-querybuilder'
import { useDispatch, useSelector } from 'react-redux'

import { saveTier } from '../../../store/admin/tiers/actions'
import {
  getEditableTiersForCust,
  getEditableTiersForUser,
  getLiveTiersForCust,
  getLiveTiersForUser
} from '../../../store/admin/tiers/selectors'
import {
  CPartyType,
  EmptyTier,
  Tier,
  TierList
} from '../../../store/admin/tiers/types'
import { AndOr, AndOrStrings } from '../../../store/alertFilter/types'
import { getCustomers } from '../../../store/customers/selectors'
import { Customer } from '../../../store/customers/types'
import { getUsers } from '../../../store/users/selectors'
import { User } from '../../../store/users/types'
import { getCanEditTiers } from '../../../store/webSettings/selectors'
import SingleTierComponent from './SingleTierComponent'
import styles from './Tiers.scss'

interface Props {
  displayModal: boolean
  closeFunc: () => void
  customer: Customer | undefined
  user: User | undefined
  editable: boolean
}

const createValueArray = (
  allCustomers: number[],
  arr: Array<{ label: string; value: string }>
) => {
  const result: Array<{ label: string; value: string }> = []
  for (const c of allCustomers) {
    const foundVal = arr.find((a) => a.value === c.toString())
    if (foundVal) {
      result.push(foundVal)
    }
  }
  return result
}

const TiersModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const displayModal = props.displayModal
  const customer = props.customer
  const user = props.user
  const editable = props.editable
  const dispatch = useDispatch()
  const tierListCust = editable
    ? useSelector(getEditableTiersForCust)(customer?.id)
    : useSelector(getLiveTiersForCust)(customer?.id)
  const tierListUser = editable
    ? useSelector(getEditableTiersForUser)(user?.id)
    : useSelector(getLiveTiersForUser)(user?.id)
  const tierList: TierList = customer ? tierListCust! : tierListUser!
  const canEditTiers = useSelector(getCanEditTiers)

  const handleValidatePreferences = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // dispatch(setUserPreferences(newPreferences))
    const tier1: Tier = {
      userId: selectedTierUsers[1],
      customers: customersTier1.map((c) => Number(c.value)),
      restrictedCustomers: restrictedCustomersTier1.map((c) => Number(c.value)),
      // types: typesTier1.map((t) => Number(t.value)),
      types: typesTier1,
      // restrictedTypes: restrictedTypesTier1.map((t) => Number(t.value)),
      restrictedTypes: restrictedTypesTier1,
      isDefault: defaultTier === 1,
      isDisabled: isDisabledTier1,
      enabledAndOr: enabledAndOrTier1 === 'And' ? AndOr.And : AndOr.Or,
      restrictedAndOr: restrictedAndOrTier1 === 'And' ? AndOr.And : AndOr.Or,
      tierName: tierName1
    }
    const tier2: Tier = {
      userId: selectedTierUsers[2],
      customers: customersTier2.map((c) => Number(c.value)),
      restrictedCustomers: restrictedCustomersTier2.map((c) => Number(c.value)),
      // types: typesTier2.map((t) => Number(t.value)),
      types: typesTier2,
      // restrictedTypes: restrictedTypesTier2.map((t) => Number(t.value)),
      restrictedTypes: restrictedTypesTier2,
      isDefault: defaultTier === 2,
      isDisabled: isDisabledTier2,
      enabledAndOr: enabledAndOrTier2 === 'And' ? AndOr.And : AndOr.Or,
      restrictedAndOr: restrictedAndOrTier2 === 'And' ? AndOr.And : AndOr.Or,
      tierName: tierName2
    }
    const tier3: Tier = {
      userId: selectedTierUsers[3],
      customers: customersTier3.map((c) => Number(c.value)),
      restrictedCustomers: restrictedCustomersTier3.map((c) => Number(c.value)),
      // types: typesTier3.map((t) => Number(t.value)),
      types: typesTier3,
      // restrictedTypes: restrictedTypesTier3.map((t) => Number(t.value)),
      restrictedTypes: restrictedTypesTier3,
      isDefault: defaultTier === 3,
      isDisabled: isDisabledTier3,
      enabledAndOr: enabledAndOrTier3 === 'And' ? AndOr.And : AndOr.Or,
      restrictedAndOr: restrictedAndOrTier3 === 'And' ? AndOr.And : AndOr.Or,
      tierName: tierName3
    }
    const tier4: Tier = {
      userId: selectedTierUsers[4],
      customers: customersTier4.map((c) => Number(c.value)),
      restrictedCustomers: restrictedCustomersTier4.map((c) => Number(c.value)),
      // types: typesTier4.map((t) => Number(t.value)),
      types: typesTier4,
      // restrictedTypes: restrictedTypesTier4.map((t) => Number(t.value)),
      restrictedTypes: restrictedTypesTier4,
      isDefault: defaultTier === 4,
      isDisabled: isDisabledTier4,
      enabledAndOr: enabledAndOrTier4 === 'And' ? AndOr.And : AndOr.Or,
      restrictedAndOr: restrictedAndOrTier4 === 'And' ? AndOr.And : AndOr.Or,
      tierName: tierName4,
      addTier: addTier4
    }
    const tier5: Tier = {
      userId: selectedTierUsers[5],
      customers: customersTier5.map((c) => Number(c.value)),
      restrictedCustomers: restrictedCustomersTier5.map((c) => Number(c.value)),
      // types: typesTier5.map((t) => Number(t.value)),
      types: typesTier5,
      // restrictedTypes: restrictedTypesTier5.map((t) => Number(t.value)),
      restrictedTypes: restrictedTypesTier5,
      isDefault: defaultTier === 5,
      isDisabled: isDisabledTier5,
      enabledAndOr: enabledAndOrTier5 === 'And' ? AndOr.And : AndOr.Or,
      restrictedAndOr: restrictedAndOrTier5 === 'And' ? AndOr.And : AndOr.Or,
      tierName: tierName5,
      addTier: addTier5
    }
    const tl: TierList = {
      id: tierList ? tierList.id : 0,
      tiers: [tier1, tier2, tier3, tier4, tier5],
      custId: customer ? customer.id : 0,
      userId: user ? user.id : 0,
      isTemp: false,
      updateForTierList: 0,
      isDeleted: false
    }
    dispatch(saveTier(tl))
    closeFunc()
  }

  const [selectedTierUsers, setSelectedTierUsers] = useState<
    Record<number, number>
  >({})

  const [selectedTier1User, setSelectedTier1User] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [selectedTier2User, setSelectedTier2User] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [selectedTier3User, setSelectedTier3User] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [selectedTier4User, setSelectedTier4User] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [selectedTier5User, setSelectedTier5User] = useState<
    Array<{ label: string; value: string }>
  >([])

  let usersInThisCust: User[] | undefined = []
  const [tierUserOptions, setTierUserOptions] = useState<User[]>([])
  const [defaultTier, setDefaultTier] = useState<number>(0)
  const users = useSelector(getUsers)
  const customers = useSelector(getCustomers)

  const [tiersLoaded, setTiersLoaded] = useState(false)

  const [isDisabledTier1, setisDisabledTier1] = useState<boolean>(false)
  const [isDisabledTier2, setisDisabledTier2] = useState<boolean>(false)
  const [isDisabledTier3, setisDisabledTier3] = useState<boolean>(false)
  const [isDisabledTier4, setisDisabledTier4] = useState<boolean>(false)
  const [isDisabledTier5, setisDisabledTier5] = useState<boolean>(false)

  const [customersTier1, setCustomersTier1] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [customersTier2, setCustomersTier2] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [customersTier3, setCustomersTier3] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [customersTier4, setCustomersTier4] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [customersTier5, setCustomersTier5] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [typesTier1, setTypesTier1] = useState<RuleGroupType | undefined>(
    undefined
  )
  const [typesTier2, setTypesTier2] = useState<RuleGroupType | undefined>(
    undefined
  )
  const [typesTier3, setTypesTier3] = useState<RuleGroupType | undefined>(
    undefined
  )
  const [typesTier4, setTypesTier4] = useState<RuleGroupType | undefined>(
    undefined
  )
  const [typesTier5, setTypesTier5] = useState<RuleGroupType | undefined>(
    undefined
  )

  const setTypesTier1WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setTypesTier1(JSON.parse(formatQuery(query, { format }) as string))
  }

  const setTypesTier2WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setTypesTier2(JSON.parse(formatQuery(query, { format }) as string))
  }

  const setTypesTier3WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setTypesTier3(JSON.parse(formatQuery(query, { format }) as string))
  }

  const setTypesTier4WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setTypesTier4(JSON.parse(formatQuery(query, { format }) as string))
  }

  const setTypesTier5WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setTypesTier5(JSON.parse(formatQuery(query, { format }) as string))
  }

  const setRestrictedTypesTier1WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setRestrictedTypesTier1(
      JSON.parse(formatQuery(query, { format }) as string)
    )
  }

  const setRestrictedTypesTier2WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setRestrictedTypesTier2(
      JSON.parse(formatQuery(query, { format }) as string)
    )
  }

  const setRestrictedTypesTier3WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setRestrictedTypesTier3(
      JSON.parse(formatQuery(query, { format }) as string)
    )
  }

  const setRestrictedTypesTier4WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setRestrictedTypesTier4(
      JSON.parse(formatQuery(query, { format }) as string)
    )
  }

  const setRestrictedTypesTier5WithoutIds = (query: RuleGroupType) => {
    const format: ExportFormat = 'json_without_ids'
    setRestrictedTypesTier5(
      JSON.parse(formatQuery(query, { format }) as string)
    )
  }

  const [restrictedCustomersTier1, setRestrictedCustomersTier1] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [restrictedCustomersTier2, setRestrictedCustomersTier2] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [restrictedCustomersTier3, setRestrictedCustomersTier3] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [restrictedCustomersTier4, setRestrictedCustomersTier4] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [restrictedCustomersTier5, setRestrictedCustomersTier5] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [restrictedTypesTier1, setRestrictedTypesTier1] = useState<
    RuleGroupType | undefined
  >(undefined)
  const [restrictedTypesTier2, setRestrictedTypesTier2] = useState<
    RuleGroupType | undefined
  >(undefined)
  const [restrictedTypesTier3, setRestrictedTypesTier3] = useState<
    RuleGroupType | undefined
  >(undefined)
  const [restrictedTypesTier4, setRestrictedTypesTier4] = useState<
    RuleGroupType | undefined
  >(undefined)
  const [restrictedTypesTier5, setRestrictedTypesTier5] = useState<
    RuleGroupType | undefined
  >(undefined)

  const [enabledAndOrTier1, setEnabledAndOrTier1] = useState('')
  const [enabledAndOrTier2, setEnabledAndOrTier2] = useState('')
  const [enabledAndOrTier3, setEnabledAndOrTier3] = useState('')
  const [enabledAndOrTier4, setEnabledAndOrTier4] = useState('')
  const [enabledAndOrTier5, setEnabledAndOrTier5] = useState('')

  const [restrictedAndOrTier1, setRestrictedAndOrTier1] = useState('')
  const [restrictedAndOrTier2, setRestrictedAndOrTier2] = useState('')
  const [restrictedAndOrTier3, setRestrictedAndOrTier3] = useState('')
  const [restrictedAndOrTier4, setRestrictedAndOrTier4] = useState('')
  const [restrictedAndOrTier5, setRestrictedAndOrTier5] = useState('')

  const [tierName1, setTierName1] = useState('')
  const [tierName2, setTierName2] = useState('')
  const [tierName3, setTierName3] = useState('')
  const [tierName4, setTierName4] = useState('')
  const [tierName5, setTierName5] = useState('')

  const [customerOptions, setCustomerOptions] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [userOptions, setUserOptions] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [typeOptions, setTypeOptions] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [addTier4, setAddTier4] = useState<boolean | undefined>(false)
  const [addTier5, setAddTier5] = useState<boolean | undefined>(false)

  const saveRestrictedCustomers = useCallback(
    (tier: number, values: Array<{ label: string; value: string }>) => {
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      switch (tier) {
        case 1:
          setRestrictedCustomersTier1(values)
          break
        case 2:
          setRestrictedCustomersTier2(values)
          break
        case 3:
          setRestrictedCustomersTier3(values)
          break
        case 4:
          setRestrictedCustomersTier4(values)
          break
        case 5:
          setRestrictedCustomersTier5(values)
          break
      }
    },
    [customers]
  )

  const saveCustomers = useCallback(
    (tier: number, values: Array<{ label: string; value: string }>) => {
      values.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      switch (tier) {
        case 1:
          setCustomersTier1(values)
          break
        case 2:
          setCustomersTier2(values)
          break
        case 3:
          setCustomersTier3(values)
          break
        case 4:
          setCustomersTier4(values)
          break
        case 5:
          setCustomersTier5(values)
          break
      }
    },
    [customers]
  )

  useEffect(() => {
    usersInThisCust = users?.filter(
      (u) => u.custId === (customer ? customer.id : 0)
    )
    const opt = []
    if (usersInThisCust) {
      for (const u of usersInThisCust) {
        opt.push(u)
      }
    }
    setTierUserOptions(opt)

    const tier1 = tierList ? tierList.tiers[0] : EmptyTier
    const tier2 = tierList ? tierList.tiers[1] : EmptyTier
    const tier3 = tierList ? tierList.tiers[2] : EmptyTier
    const tier4 = tierList ? tierList.tiers[3] : EmptyTier
    const tier5 = tierList ? tierList.tiers[4] : EmptyTier

    setTiersLoaded(true)
    const custOptions: Array<{ label: string; value: string }> = []
    if (customers) {
      for (const c of customers) {
        custOptions.push({ label: c.shortName, value: c.id.toString() })
      }
    }
    setCustomerOptions(custOptions)

    const usrOptions = []
    if (users) {
      for (const u of users) {
        usrOptions.push({ label: u.userName, value: u.id.toString() })
      }
      setUserOptions(usrOptions)
    }

    const to: Array<{ label: string; value: string }> = []

    for (const item in CPartyType) {
      if (!isNaN(Number(item))) {
        to.push({
          label: CPartyType[item],
          value: Number(item).toString()
        })
      }
    }

    setTypeOptions(to)

    setCustomersTier1(createValueArray(tier1.customers, custOptions))
    setCustomersTier2(createValueArray(tier2.customers, custOptions))
    setCustomersTier3(createValueArray(tier3.customers, custOptions))
    setCustomersTier4(createValueArray(tier4.customers, custOptions))
    setCustomersTier5(createValueArray(tier5.customers, custOptions))

    setTypesTier1(tier1.types)
    setTypesTier2(tier2.types)
    setTypesTier3(tier3.types)
    setTypesTier4(tier4.types)
    setTypesTier5(tier5.types)

    setRestrictedCustomersTier1(
      createValueArray(tier1.restrictedCustomers, custOptions)
    )
    setRestrictedCustomersTier2(
      createValueArray(tier2.restrictedCustomers, custOptions)
    )
    setRestrictedCustomersTier3(
      createValueArray(tier3.restrictedCustomers, custOptions)
    )
    setRestrictedCustomersTier4(
      createValueArray(tier4.restrictedCustomers, custOptions)
    )
    setRestrictedCustomersTier5(
      createValueArray(tier5.restrictedCustomers, custOptions)
    )

    setRestrictedTypesTier1(tier1.restrictedTypes)
    setRestrictedTypesTier2(tier2.restrictedTypes)
    setRestrictedTypesTier3(tier3.restrictedTypes)
    setRestrictedTypesTier4(tier4.restrictedTypes)
    setRestrictedTypesTier5(tier5.restrictedTypes)

    if (tier1.isDefault) {
      setDefaultTier(1)
    } else if (tier2.isDefault) {
      setDefaultTier(2)
    } else if (tier3.isDefault) {
      setDefaultTier(3)
    } else if (tier4.isDefault) {
      setDefaultTier(4)
    } else if (tier5.isDefault) {
      setDefaultTier(5)
    } else {
      setDefaultTier(0)
    }
    const selUsr: Record<number, number> = {}
    setTierUser(1, tier1.userId, usrOptions, selUsr)
    setTierUser(2, tier2.userId, usrOptions, selUsr)
    setTierUser(3, tier3.userId, usrOptions, selUsr)
    setTierUser(4, tier4.userId, usrOptions, selUsr)
    setTierUser(5, tier5.userId, usrOptions, selUsr)

    setisDisabledTier1(tier1.isDisabled)
    setisDisabledTier2(tier2.isDisabled)
    setisDisabledTier3(tier3.isDisabled)
    setisDisabledTier4(tier4.isDisabled)
    setisDisabledTier5(tier5.isDisabled)

    setEnabledAndOrTier1(AndOrStrings[tier1.enabledAndOr])
    setEnabledAndOrTier2(AndOrStrings[tier2.enabledAndOr])
    setEnabledAndOrTier3(AndOrStrings[tier3.enabledAndOr])
    setEnabledAndOrTier4(AndOrStrings[tier4.enabledAndOr])
    setEnabledAndOrTier5(AndOrStrings[tier5.enabledAndOr])

    setRestrictedAndOrTier1(AndOrStrings[tier1.restrictedAndOr])
    setRestrictedAndOrTier2(AndOrStrings[tier2.restrictedAndOr])
    setRestrictedAndOrTier3(AndOrStrings[tier3.restrictedAndOr])
    setRestrictedAndOrTier4(AndOrStrings[tier4.restrictedAndOr])
    setRestrictedAndOrTier5(AndOrStrings[tier5.restrictedAndOr])

    setTierName1(tier1.tierName)
    setTierName2(tier2.tierName)
    setTierName3(tier3.tierName)
    setTierName4(tier4.tierName)
    setTierName5(tier5.tierName)

    setAddTier4(tier4.addTier)
    setAddTier5(tier5.addTier)
  }, [])

  const setDefault = (tier: number) => {
    if (defaultTier === tier) {
      setDefaultTier(0)
    } else {
      setDefaultTier(tier)
    }
  }

  const setTierUser = (
    tier: number,
    id: number,
    usrOptions?: Array<{ label: string; value: string }>,
    selectedUsers?: Record<number, number>
  ) => {
    const newSelectedTierUsers: Record<number, number> = {}
    const selUsers = selectedUsers ?? selectedTierUsers
    for (const i in selUsers) {
      if (selUsers.hasOwnProperty(i)) {
        newSelectedTierUsers[Number(i)] = selUsers[i]
      }
    }
    newSelectedTierUsers[tier] = id
    if (selectedUsers) {
      selUsers[tier] = id
    }
    setSelectedTierUsers(newSelectedTierUsers)
    const options = usrOptions ?? userOptions
    switch (tier) {
      case 1:
        setSelectedTier1User(createValueArray([id], options))
        break
      case 2:
        setSelectedTier2User(createValueArray([id], options))
        break
      case 3:
        setSelectedTier3User(createValueArray([id], options))
        break
      case 4:
        setSelectedTier4User(createValueArray([id], options))
        break
      case 5:
        setSelectedTier5User(createValueArray([id], options))
        break
    }
  }

  const addTier = (e: any) => {
    e.preventDefault()
    addTier4 ? setAddTier5(true) : setAddTier4(true)
  }

  const contentModal = tiersLoaded ? (
    <form onSubmit={handleValidatePreferences}>
      <header className={styles.header}>
        {customer && <label>Tiers for {customer.shortName}</label>}
        {user && <label>Tiers for {user.userName}</label>}
        <button
          data-qa="close"
          type="button"
          className={styles.close}
          onClick={closeFunc}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </header>

      <div className={styles.tierWrapper}>
        <SingleTierComponent
          customer={customer}
          tier={1}
          selectedTierUsers={selectedTierUsers}
          setTierUser={setTierUser}
          tierUserOptions={tierUserOptions}
          defaultTier={defaultTier}
          setDefaultTier={setDefault}
          selectedCustomers={customersTier1}
          saveCustomers={saveCustomers}
          selectedTypes={typesTier1}
          saveTypes={setTypesTier1WithoutIds}
          customerOptions={customerOptions}
          typeOptions={typeOptions}
          selectedTierUser={selectedTier1User}
          selectedRestrictedCustomers={restrictedCustomersTier1}
          saveRestrictedCustomers={saveRestrictedCustomers}
          selectedRestrictedTypes={restrictedTypesTier1}
          saveRestrictedTypes={setRestrictedTypesTier1WithoutIds}
          isDisabled={isDisabledTier1}
          setIsDisabled={setisDisabledTier1}
          enabledAndOr={enabledAndOrTier1}
          saveEnabledAndOr={setEnabledAndOrTier1}
          restrictedAndOr={restrictedAndOrTier1}
          saveRestrictedAndOr={setRestrictedAndOrTier1}
          tierName={tierName1}
          saveTierName={setTierName1}
        />
        <SingleTierComponent
          customer={customer}
          tier={2}
          selectedTierUsers={selectedTierUsers}
          setTierUser={setTierUser}
          tierUserOptions={tierUserOptions}
          defaultTier={defaultTier}
          setDefaultTier={setDefault}
          selectedCustomers={customersTier2}
          saveCustomers={saveCustomers}
          selectedTypes={typesTier2}
          saveTypes={setTypesTier2WithoutIds}
          customerOptions={customerOptions}
          typeOptions={typeOptions}
          selectedTierUser={selectedTier2User}
          selectedRestrictedCustomers={restrictedCustomersTier2}
          saveRestrictedCustomers={saveRestrictedCustomers}
          selectedRestrictedTypes={restrictedTypesTier2}
          saveRestrictedTypes={setRestrictedTypesTier2WithoutIds}
          isDisabled={isDisabledTier2}
          setIsDisabled={setisDisabledTier2}
          enabledAndOr={enabledAndOrTier2}
          saveEnabledAndOr={setEnabledAndOrTier2}
          restrictedAndOr={restrictedAndOrTier2}
          saveRestrictedAndOr={setRestrictedAndOrTier2}
          tierName={tierName2}
          saveTierName={setTierName2}
        />
        <SingleTierComponent
          customer={customer}
          tier={3}
          selectedTierUsers={selectedTierUsers}
          setTierUser={setTierUser}
          tierUserOptions={tierUserOptions}
          defaultTier={defaultTier}
          setDefaultTier={setDefault}
          selectedCustomers={customersTier3}
          saveCustomers={saveCustomers}
          selectedTypes={typesTier3}
          saveTypes={setTypesTier3WithoutIds}
          customerOptions={customerOptions}
          typeOptions={typeOptions}
          selectedTierUser={selectedTier3User}
          selectedRestrictedCustomers={restrictedCustomersTier3}
          saveRestrictedCustomers={saveRestrictedCustomers}
          selectedRestrictedTypes={restrictedTypesTier3}
          saveRestrictedTypes={setRestrictedTypesTier3WithoutIds}
          isDisabled={isDisabledTier3}
          setIsDisabled={setisDisabledTier3}
          enabledAndOr={enabledAndOrTier3}
          saveEnabledAndOr={setEnabledAndOrTier3}
          restrictedAndOr={restrictedAndOrTier3}
          saveRestrictedAndOr={setRestrictedAndOrTier3}
          tierName={tierName3}
          saveTierName={setTierName3}
        />
        {addTier4 && (
          <SingleTierComponent
            customer={customer}
            tier={4}
            selectedTierUsers={selectedTierUsers}
            setTierUser={setTierUser}
            tierUserOptions={tierUserOptions}
            defaultTier={defaultTier}
            setDefaultTier={setDefault}
            selectedCustomers={customersTier4}
            saveCustomers={saveCustomers}
            selectedTypes={typesTier4}
            saveTypes={setTypesTier4WithoutIds}
            customerOptions={customerOptions}
            typeOptions={typeOptions}
            selectedTierUser={selectedTier4User}
            selectedRestrictedCustomers={restrictedCustomersTier4}
            saveRestrictedCustomers={saveRestrictedCustomers}
            selectedRestrictedTypes={restrictedTypesTier4}
            saveRestrictedTypes={setRestrictedTypesTier4WithoutIds}
            isDisabled={isDisabledTier4}
            setIsDisabled={setisDisabledTier4}
            enabledAndOr={enabledAndOrTier4}
            saveEnabledAndOr={setEnabledAndOrTier4}
            restrictedAndOr={restrictedAndOrTier4}
            saveRestrictedAndOr={setRestrictedAndOrTier4}
            tierName={tierName4}
            saveTierName={setTierName4}
          />
        )}
        {addTier5 && (
          <SingleTierComponent
            customer={customer}
            tier={5}
            selectedTierUsers={selectedTierUsers}
            setTierUser={setTierUser}
            tierUserOptions={tierUserOptions}
            defaultTier={defaultTier}
            setDefaultTier={setDefault}
            selectedCustomers={customersTier5}
            saveCustomers={saveCustomers}
            selectedTypes={typesTier5}
            saveTypes={setTypesTier5WithoutIds}
            customerOptions={customerOptions}
            typeOptions={typeOptions}
            selectedTierUser={selectedTier5User}
            selectedRestrictedCustomers={restrictedCustomersTier5}
            saveRestrictedCustomers={saveRestrictedCustomers}
            selectedRestrictedTypes={restrictedTypesTier5}
            saveRestrictedTypes={setRestrictedTypesTier5WithoutIds}
            isDisabled={isDisabledTier5}
            setIsDisabled={setisDisabledTier5}
            enabledAndOr={enabledAndOrTier5}
            saveEnabledAndOr={setEnabledAndOrTier5}
            restrictedAndOr={restrictedAndOrTier5}
            saveRestrictedAndOr={setRestrictedAndOrTier5}
            tierName={tierName5}
            saveTierName={setTierName5}
          />
        )}
      </div>

      <footer>
        {!addTier5 && canEditTiers && (
          <button
            className={cx(styles.footerButton, styles.addTier)}
            onClick={addTier}
          >
            Add Tier
          </button>
        )}

        {editable && canEditTiers && (
          <button className={styles.footerButton} type="submit">
            Save
          </button>
        )}
        <button className={styles.footerButton} onClick={closeFunc}>
          Cancel
        </button>
        <span className={styles.footerTierId}>
          Tier ID: {tierList ? tierList.id : 0}
        </span>
      </footer>
    </form>
  ) : (
    <></>
  )
  return (
    <ReactModal isOpen={displayModal} onRequestClose={() => closeFunc()}>
      {contentModal}
    </ReactModal>
  )
}

export default TiersModal
