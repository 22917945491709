import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  faArchive,
  faBan,
  faBookOpen,
  faCalendar,
  faChartBar,
  faChartLine,
  faDatabase,
  faFileSpreadsheet,
  faFlask,
  faLayerGroup,
  faMessagesDollar,
  faSignalAlt3,
  faUsers
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/modules/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOpenFin } from '../../../app/openFinContext'
import {
  getCanMarkRestricted,
  getCanSeeSystemParamEditor
} from '../../../store/webSettings/selectors'
import styles from './menus.scss'

export interface Props {
  closeMenu: () => void
}

const AdminMenu: FC<Props> = ({ closeMenu }) => {
  const { renderWindowCheck } = useOpenFin()
  const canMarkRestricted = useSelector(getCanMarkRestricted)
  const canSeeSystemParameterEditor = useSelector(getCanSeeSystemParamEditor)

  return (
    <>
      <div className={styles.menuSection}>
        Admin
        <span onClick={closeMenu}>
          <FontAwesomeIcon icon={faTimes} title="Close Menu" />
        </span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('TradeBlotter')}
      >
        <FontAwesomeIcon icon={faArchive} />
        <span>Trade Blotter</span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('ActivityTicker')}
      >
        <FontAwesomeIcon icon={faChartLine} />
        <span>Activity Ticker</span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('AggressAttempts')}
      >
        <FontAwesomeIcon icon={faMessagesDollar} />
        <span>Aggress Attempts</span>
      </div>
      {canSeeSystemParameterEditor && (
        <div
          className={styles.dropdownButton}
          onClick={() => renderWindowCheck('SystemParameters')}
        >
          <FontAwesomeIcon icon={faBookOpen} /> System Parameters
        </div>
      )}

      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('Tiers')}
      >
        <FontAwesomeIcon icon={faLayerGroup} /> Tiers
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('QueueStats')}
      >
        <FontAwesomeIcon icon={faSignalAlt3} /> Queue Stats
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('QuerySelector')}
      >
        <FontAwesomeIcon icon={faDatabase} /> Order/Trade History
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('UserDashboard')}
      >
        <FontAwesomeIcon icon={faUsers} />
        <span>Active User Dashboard</span>
      </div>
      {canMarkRestricted && (
        <>
          <div
            className={styles.dropdownButton}
            onClick={() => renderWindowCheck('RestrictedTrading')}
          >
            <FontAwesomeIcon icon={faBan} />
            <span>Restrict Trading</span>
          </div>
          <div
            className={styles.dropdownButton}
            onClick={() => renderWindowCheck('RestrictedTradingPermanent')}
          >
            <FontAwesomeIcon icon={faBan} />
            <span>Restrict Permanent</span>
          </div>
        </>
      )}
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('GUIUserData')}
      >
        <FontAwesomeIcon icon={faCalendar} />
        <span>GUI User Data</span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('APIUserData')}
      >
        <FontAwesomeIcon icon={faCalendar} />
        <span>API User Data</span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('ExternalLiquidityStats')}
      >
        <FontAwesomeIcon icon={faFlask} />
        <span>External Liquidity</span>
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('WatchlistManager')}
      >
        <FontAwesomeIcon icon={faChartBar} /> Watchlist Manager
      </div>
      <div className={styles.menuSection}>Ops</div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('BBMISIN')}
      >
        <FontAwesomeIcon icon={faFileSpreadsheet} /> B-BMISIN
      </div>
      <div
        className={styles.dropdownButton}
        onClick={() => renderWindowCheck('ManagementReports')}
      >
        <FontAwesomeIcon icon={faChartBar} /> Management Reports
      </div>
    </>
  )
}

export default AdminMenu
