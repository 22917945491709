import { ColumnApi, GetRowIdFunc, GridApi } from '@ag-grid-community/core'
import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { AgGridReact } from '@ag-grid-community/react'
import {
  // getHighQueueStats,
  getQueueStats
} from '../../../../store/admin/queueStats/selectors'
import { QueueStats } from '../../../../store/admin/queueStats/types'
import { columnDefinitions } from './columnDefs'

import cx from 'classnames'
import styles from '../../../../assets/scss/grid.scss'
import { defaultColumnDefinitions } from '../../../../helpers/formatting'

const getRowId: GetRowIdFunc<QueueStats> = ({ data }) => {
  return `${data.id}`
}
const QueueStatsGrid: FC = () => {
  const rowData = useSelector(getQueueStats)

  const [gridApi, setGridApi] = useState<{
    api: GridApi
    columnApi: ColumnApi
  } | null>(null)

  const onGridReady = useCallback(
    ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
      if (!gridApi) {
        setGridApi({ api, columnApi })
        const sortModel = [{ colId: 'queueSize', sort: 'desc' } as const]
        columnApi.applyColumnState({ state: sortModel })
      }
    },
    []
  )

  const rowStyle = (params: any) => {
    if (params.data.isMDQueue && params.data.queueSize > 500000) {
      return { 'background-color': 'red' }
    }
    if (params.data.isMDQueue && params.data.queueSize > 250000) {
      return { 'background-color': 'orange' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 50000) {
      return { 'background-color': 'red' }
    }
    if (!params.data.isMDQueue && params.data.queueSize > 25000) {
      return { 'background-color': 'orange' }
    }
  }

  return (
    <div className={cx(styles.gridDimensions, 'ag-theme-balham')}>
      <AgGridReact
        defaultColDef={defaultColumnDefinitions}
        rowData={rowData}
        columnDefs={columnDefinitions}
        overlayNoRowsTemplate="No CSRMS with high queue found."
        overlayLoadingTemplate="Loading queue stats"
        alwaysShowVerticalScroll={true}
        suppressDragLeaveHidesColumns={true}
        onGridReady={onGridReady}
        getRowStyle={rowStyle}
        rowSelection={'single'}
        getRowId={getRowId}
      />
    </div>
  )
}

export default QueueStatsGrid
