import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import gridStyles from '../../assets/scss/grid.scss'
import {
  clearPassiveOrders,
  fetchPassiveOrders,
  setViewAllPassiveOrdersAction
} from '../../store/passiveOrders/actions'
import { getViewAllPassiveOrders } from '../../store/passiveOrders/selectors'
import Checkbox from '../Checkbox'
import ComponentHeader from '../ComponentHeader/ComponentHeader'
import PassiveOrdersGrid from './Grid/PassiveOrdersGrid'
import styles from './PassiveOrders.scss'

const PassiveOrders: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchPassiveOrders())
  }, [])

  const viewAll = useSelector(getViewAllPassiveOrders)

  const checkChanged = () => {
    dispatch(setViewAllPassiveOrdersAction(!viewAll))
    dispatch(clearPassiveOrders())
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader title="My Orders" />
      <div className={styles.topMenu}>
        <Checkbox
          checkboxClass={styles.cb}
          locator={`test`}
          checked={viewAll}
          onChange={checkChanged}
          disabled={false}
        >
          Show all orders from users I have access to see
        </Checkbox>
      </div>
      <PassiveOrdersGrid />
    </div>
  )
}

export default PassiveOrders
