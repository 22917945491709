import cx from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import gridStyles from '../../../assets/scss/grid.scss'
import {
  clearExternalLiquidityStats,
  externalLiquidityStatsFetch
} from '../../../store/admin/externalLiquidityStats/actions'
import { getExternalOrderStatsPending } from '../../../store/admin/externalOrderStats/selectors'
import { venueFetch } from '../../../store/admin/venues/actions'
import { getVenues } from '../../../store/admin/venues/selectors'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import styles from '../HistoricalUserData/HistoricalData.scss'
import QuerySearchBondsInput from '../Query/QuerySecuritySearch/SearchBondsInput'
import ExternalLiquidityStatsGrid from './Grid/ExternalLiquidityStatsGrid'

const ExternalLiquidityStats: FC = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const pending = useSelector(getExternalOrderStatsPending)

  const venues = useSelector(getVenues)

  const [venueOptionsState, setVenueOptionsState] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [venuesState, setVenuesState] = useState<
    Array<{ label: string; value: string }>
  >([])

  useEffect(() => {
    dispatch(venueFetch())
  }, [])

  useEffect(() => {
    const options: Array<{ label: string; value: string }> = []
    if (venues) {
      for (const u of venues) {
        options.push({ label: u.name, value: u.id.toString() })
      }
    }
    setVenueOptionsState(options)
  }, [venues])
  const runQuery = () => {
    const ids = venuesState.map((v) => Number(v.value))
    dispatch(clearExternalLiquidityStats())
    dispatch(externalLiquidityStatsFetch(ids, search))
  }

  return (
    <div className={gridStyles.outerGridContainer}>
      <ComponentHeader title="External Liquidity Stats" />
      <div className={styles.topInputs}>
        <Select
          className={styles.Select}
          options={venueOptionsState}
          isMulti
          isClearable={true}
          value={venuesState}
          onChange={(event) => {
            if (event) {
              const selVenues = []
              for (const e of event) {
                selVenues.push(e)
              }
              setVenuesState(selVenues)
            }
          }}
        />

        <QuerySearchBondsInput search={search} setSearch={setSearch} />
        <button
          className={cx(styles.btn, pending && styles.disabled)}
          onClick={runQuery}
          disabled={pending}
        >
          Refresh
        </button>
      </div>
      <ExternalLiquidityStatsGrid />
    </div>
  )
}

export default ExternalLiquidityStats
