import { ColDef } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { defaultColumnDefinitions } from '../../../helpers/formatting'
import {
  getOrderValidations,
  ValidationsType
} from '../../../store/order/selectors'
import { getSecurityStaticDataById } from '../../../store/securities/selectors'
import styles from '../uploadDropDownMenu.scss'

type GridType = ValidationsType[number]

const defaultColumn = {
  ...defaultColumnDefinitions,
  tooltipField: 'error' as const
}

const OrderErrorTable = () => {
  const classes = cx('ag-theme-balham', styles.grid)

  const validations = useSelector(getOrderValidations)
  const getStaticData = useSelector(getSecurityStaticDataById)

  const columnDefs: Array<ColDef<GridType>> = useMemo(() => {
    return [
      {
        field: 'isin',
        valueFormatter: ({ value, data }) => {
          const security = data && getStaticData(data.securityId)
          return value ? value : security?.isin
        }
      },
      {
        field: 'orderType',
        width: 90,
        valueFormatter: ({ value }) =>
          value && (value === 'buy' ? 'Bid' : 'Ofr')
      },
      {
        field: 'error',
        flex: 2
      }
    ]
  }, [getStaticData])

  return (
    <>
      <div className={styles.invalidUploadError}>
        Error: The following markets were not added
      </div>
      <div className={classes} data-testid="order-error-table">
        <AgGridReact<GridType>
          columnDefs={columnDefs}
          defaultColDef={defaultColumn}
          headerHeight={20}
          groupHeaderHeight={20}
          rowHeight={20}
          rowData={validations}
        />
      </div>
    </>
  )
}

export default OrderErrorTable
