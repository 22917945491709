import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  faPen,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/regular'
import {
  faArrowsToDottedLine,
  faExclamationTriangle,
  faPlus
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import ReactTooltip from 'react-tooltip'

import AdvancedFilter from '../../components/AdvancedFilter/AdvancedFilter'
import DropDownUploadOrdersByDatePicker from '../../components/DropdownUpload/DropDownUploadOrdersByDatePicker'
import DropDownWatchlistMenu from '../../components/DropDownWatchlistMenu/DropDownWatchlistMenu'
import Expand from '../../components/Expand/expand'
import MyOrdersControlButtons from '../../components/MyOrdersFooter/MyOrdersControlButtons'

import QuoteReliabilityDropDownMenu from '../../components/QuoteReliability/QuoteReliabilityDropDownMenu'

import { fetchBooks } from '../../store/books/actions'
import { getBooks } from '../../store/books/selectors'
import { getOrderValidations } from '../../store/order/selectors'
import {
  fetchSecurities,
  removeGrid,
  setIsMine,
  setShowLive,
  sortValidationsToTopAction
} from '../../store/securities/actions'
import {
  getIsMine,
  getShowLive,
  getWatchlistId
} from '../../store/securities/selectors'
import { toggleDropdownState } from '../../store/upload/actions'
import { getDropdownState } from '../../store/upload/selectors'
import { DropdownState } from '../../store/upload/types'
import {
  cancelAllWatchListOrdersAction,
  fetchWatchListsAction
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'

import stop_sign from '../../assets/images/stop_sign.svg'

import styles from './bondListHeader.scss'
import BondsListHeaderSearch from './BondsListHeaderSearch'
import HeaderButton from './HeaderButton'

import { useOpenFin } from '../../app/openFinContext'
import { getSelectedUser, getUserFromUserId } from '../../store/users/selectors'
import {
  getHasTradingRights,
  getIsAdmin
} from '../../store/webSettings/selectors'

interface Props {
  gridIndex: number
  myOrdersOpen: boolean
  toggleMyOrders: () => void
  advancedFilterActive: boolean
  setAdvancedFilterActive: (b: boolean) => void
  showCollapseRows: boolean
  collapseRows: () => void
  canEditWatchList: boolean
}

const BondListHeader: React.FC<Props> = ({
  gridIndex,
  myOrdersOpen,
  toggleMyOrders,
  advancedFilterActive,
  setAdvancedFilterActive,
  canEditWatchList,
  showCollapseRows,
  collapseRows
}) => {
  const { fin } = useOpenFin()
  const watchlists = useSelector(getWatchList)
  const dispatch = useDispatch()
  const watchlistIdSelected = useSelector(getWatchlistId)(gridIndex)
  const mineChecked = useSelector(getIsMine)(gridIndex)
  const showLiveChecked = useSelector(getShowLive)(gridIndex)
  const books = useSelector(getBooks)
  const dropdownState = useSelector(getDropdownState)(gridIndex)
  const orderValidations = useSelector(getOrderValidations)
  const selUserId = useSelector(getSelectedUser)
  const selectedUser = useSelector(getUserFromUserId)(selUserId)
  const selectedAdminUser = `${selectedUser?.custName}: ${selectedUser?.userName}`
  const isAdmin = useSelector(getIsAdmin)
  const [securitiesWithOrderValidation, setSecuritiesWithOrderValidation] =
    useState([] as number[])
  const hasTradingRights = useSelector(getHasTradingRights)

  const watchListIcon = canEditWatchList ? faPen : faPlus
  const watchListTip = canEditWatchList ? 'Edit Watchlist' : 'New Watchlist'

  const nextDropdownState = useMemo<DropdownState>(() => {
    switch (dropdownState) {
      case 'closed':
        return canEditWatchList ? 'upload' : 'newWatchlist'
      default:
        return 'closed'
    }
  }, [dropdownState, canEditWatchList])

  useEffect(() => {
    const securitiesWithError = orderValidations.map((v) => v.securityId)
    setSecuritiesWithOrderValidation(securitiesWithError)
  }, [JSON.stringify(orderValidations)])

  const sortByOrderValidation = () => {
    dispatch(
      sortValidationsToTopAction(gridIndex, securitiesWithOrderValidation)
    )
    dispatch(fetchSecurities(gridIndex, 0, securitiesWithOrderValidation))
  }

  const handleCloseButton = useCallback(() => {
    dispatch(removeGrid(gridIndex))
  }, [])

  useEffect(() => {
    if (watchlists === undefined) {
      dispatch(fetchWatchListsAction(gridIndex))
    }
  }, [watchlists])

  useEffect(() => {
    if (books === undefined) {
      dispatch(fetchBooks())
    }
  }, [books])

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(gridIndex, watchlistIdSelected))
  }

  const onCheckMine = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setIsMine(gridIndex, checked))
  }, [])

  const onCheckShowLive = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setShowLive(gridIndex, checked))
  }, [])

  const toggleUpload = useCallback(() => {
    dispatch(toggleDropdownState(gridIndex, nextDropdownState))
  }, [dropdownState, nextDropdownState])

  return (
    <div
      className={cx(
        fin ? styles.finHeaderWrapper : styles.gridHeaderWrapper,
        gridIndex !== 0 && styles.addDrag
      )}
    >
      <div
        className={cx(
          styles.gridHeader,
          myOrdersOpen && styles.rightBorderAdjust
        )}
      >
        <div className={styles.headerButtons}>
          <div className={styles.headerDropdown}>
            <DropDownWatchlistMenu
              setActive={setAdvancedFilterActive}
              gridIndex={gridIndex}
              watchLists={watchlists || []}
              className={styles.dropdownWatchlistContainer}
            />
            <BondsListHeaderSearch gridIndex={gridIndex} />
          </div>

          <div className={cx('pretty p-switch p-fill', styles.checkboxWrapper)}>
            <input
              type="checkbox"
              name="checkbox-showlive"
              onChange={onCheckShowLive}
              checked={showLiveChecked}
            />
            <div className="state p-default">
              <label>Show Live</label>
            </div>
          </div>

          {hasTradingRights && (
            <div
              className={cx('pretty p-switch p-fill', styles.checkboxWrapper)}
            >
              <input
                type="checkbox"
                name="checkbox-mine"
                onChange={onCheckMine}
                checked={mineChecked}
                disabled={watchlistIdSelected === 0}
              />
              <div className="state p-default">
                <label>Mine</label>
              </div>
            </div>
          )}

          <div className={styles.headerIcons}>
            <AdvancedFilter
              active={advancedFilterActive}
              setActive={setAdvancedFilterActive}
              gridIndex={gridIndex}
            />

            <QuoteReliabilityDropDownMenu gridIndex={gridIndex} />

            {(fin || !gridIndex) && (
              <HeaderButton
                active={dropdownState === 'upload'}
                toggle={toggleUpload}
                data-testid="manualordersbutton"
                faIcon={watchListIcon}
                dataFor="editList"
                title={watchListTip}
              />
            )}

            {hasTradingRights && (
              <HeaderButton
                data-testid="myordersbutton"
                className={cx(myOrdersOpen && styles.myOrdersOpen)}
                active={myOrdersOpen}
                toggle={toggleMyOrders}
                Icon={Expand}
                title={`${myOrdersOpen ? 'Hide' : 'Show'} My Orders`}
              />
            )}

            {gridIndex !== 0 && (
              <HeaderButton
                data-testid="removegrid"
                dataFor="closeWatchlist"
                active={false}
                toggle={handleCloseButton}
                faIcon={faTimes}
                className={styles.closeWatchlist}
                title="Close Watchlist"
              />
            )}
            {showCollapseRows && (
              <HeaderButton
                active={false}
                data-testid="collapseRows"
                faIcon={faArrowsToDottedLine}
                title="Collapse Rows"
                toggle={collapseRows}
              />
            )}
          </div>
        </div>
      </div>
      {isAdmin && selectedUser && gridIndex !== 0 && (
        <div className={styles.adminText}>{selectedAdminUser}</div>
      )}
      {myOrdersOpen && (
        <div className={styles.myOrdersHeader} data-testid="my-orders-header">
          <span className={styles.myOrdersLabel}>
            My Orders{' '}
            <img
              src={stop_sign}
              alt="stop sign icon"
              className={styles.stopIcon}
              data-testid="myordersstopbutton"
              onClick={cancelAllOrders}
              data-tip="Cancel Watchlist Orders"
              data-for="cancelWatchlistOrders"
            />
            <ReactTooltip
              id="cancelWatchlistOrders"
              backgroundColor="#e9edf1"
              textColor="#656C78"
              effect="solid"
              className="iconTooltip"
            >
              Cancel Watchlist Orders
            </ReactTooltip>
            {securitiesWithOrderValidation.length > 0 && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                onClick={sortByOrderValidation}
              />
            )}
          </span>
          <MyOrdersControlButtons />
          <div className={styles.dFlex}>
            <div className="pretty p-switch p-fill m-auto">
              <input
                type="checkbox"
                name="checkbox-mine"
                onChange={onCheckMine}
                checked={mineChecked}
                disabled={watchlistIdSelected === 0}
              />
              <div className="state p-default">
                <label>Mine</label>
              </div>
            </div>
            {(fin || !gridIndex) && (
              <HeaderButton
                active={dropdownState === 'upload'}
                toggle={toggleUpload}
                data-testid="manualordersbutton"
                faIcon={watchListIcon}
                dataFor="editList"
                title={watchListTip}
              />
            )}
            <span className={styles.myOrdersParameters}>
              <DropDownUploadOrdersByDatePicker gridIndex={gridIndex} />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default BondListHeader
