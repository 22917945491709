import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { RuleGroupType } from 'react-querybuilder'
import Select from 'react-select'
import { useClickAway } from 'react-use'
import { AndOrStrings } from '../../../store/alertFilter/types'
import { Customer } from '../../../store/customers/types'
import { User } from '../../../store/users/types'
import Checkbox from '../../Checkbox'
import GenericDropdownMenu from '../../GenericDropdownMenu/GenericDropdownMenu'
import styles from './Tiers.scss'
import TypeSelector from './TypeSelector'

import {
  faCheckCircle,
  faPencilAlt
} from '@awesome.me/kit-5de77b2c02/icons/modules/classic/light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  tier: number
  setTierUser: (tier: number, id: number) => void
  selectedTierUsers: Record<number, number>
  tierUserOptions: User[]
  customer: Customer | undefined
  defaultTier: number
  setDefaultTier: (tier: number) => void
  selectedCustomers: Array<{ label: string; value: string }>
  saveCustomers: (
    tier: number,
    values: Array<{ label: string; value: string }>
  ) => void
  selectedTypes: RuleGroupType | undefined
  saveTypes: (query: RuleGroupType) => void
  customerOptions: Array<{ label: string; value: string }>
  typeOptions: Array<{ label: string; value: string }>
  selectedTierUser: Array<{ label: string; value: string }>
  selectedRestrictedCustomers: Array<{ label: string; value: string }>
  saveRestrictedCustomers: (
    tier: number,
    values: Array<{ label: string; value: string }>
  ) => void
  selectedRestrictedTypes: RuleGroupType | undefined
  saveRestrictedTypes: (query: RuleGroupType) => void
  isDisabled: boolean
  setIsDisabled: (disabled: boolean) => void
  enabledAndOr: string
  saveEnabledAndOr: (andOr: string) => void
  restrictedAndOr: string
  saveRestrictedAndOr: (andOr: string) => void
  tierName: string
  saveTierName: (tierName: string) => void
}

const SingleTierComponent: FC<Props> = (props: Props) => {
  const tier = props.tier
  const customer = props.customer
  const setTierUser = props.setTierUser
  const selectedTierUsers = props.selectedTierUsers
  const tierUserOptions = props.tierUserOptions
  const defaultTier = props.defaultTier
  const setDefaultTier = props.setDefaultTier
  const selectedCustomers = props.selectedCustomers
  const saveCustomers = props.saveCustomers
  const selectedTypes = props.selectedTypes
  const saveTypes = props.saveTypes
  const customerOptions = props.customerOptions
  // const typeOptions = props.typeOptions
  const selectedTierUser = props.selectedTierUser
  const selectedRestrictedCustomers = props.selectedRestrictedCustomers
  const saveRestrictedCustomers = props.saveRestrictedCustomers
  const selectedRestrictedTypes = props.selectedRestrictedTypes
  const saveRestrictedTypes = props.saveRestrictedTypes
  const isDisabled = props.isDisabled
  const setIsDisabled = props.setIsDisabled
  const enabledAndOr = props.enabledAndOr
  const saveEnabledAndOr = props.saveEnabledAndOr
  const restrictedAndOr = props.restrictedAndOr
  const saveRestrictedAndOr = props.saveRestrictedAndOr
  const tierName = props.tierName
  const saveTierName = props.saveTierName
  const [displayNameInput, setDisplayNameInput] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  useClickAway(ref, () => setDisplayNameInput(false))

  const [tierUserOptionsState, setTierUserOptionsState] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [customerOptionsState, setCustomerOptionsState] = useState<
    Array<{ label: string; value: string }>
  >([])

  const [restrictedCustomerOptionsState, setRestrictedCustomerOptionsState] =
    useState<Array<{ label: string; value: string }>>([])

  useEffect(() => {
    setCustomerOptionsState(
      customerOptions.filter(
        (co) =>
          selectedRestrictedCustomers.find((src) => src.value === co.value) ===
          undefined
      )
    )

    setRestrictedCustomerOptionsState(
      customerOptions.filter(
        (rco) =>
          selectedCustomers.find((sc) => sc.value === rco.value) === undefined
      )
    )
  }, [])

  useEffect(() => {
    const options: Array<{ label: string; value: string }> = []
    if (tierUserOptions) {
      for (const u of tierUserOptions) {
        let add = true
        for (const i in selectedTierUsers) {
          if (selectedTierUsers.hasOwnProperty(i)) {
            const numKey = Number(i)
            if (numKey === tier) {
              continue
            }
            if (selectedTierUsers[numKey] === u.id) {
              add = false
            }
          }
        }
        if (add) {
          options.push({ label: u.userName, value: u.id.toString() })
        }
      }
    }
    setTierUserOptionsState(options)
  }, [tierUserOptions, selectedTierUsers])

  useEffect(() => {
    setRestrictedCustomerOptionsState(
      customerOptions.filter(
        (rco) =>
          selectedCustomers.find((sc) => sc.value === rco.value) === undefined
      )
    )
  }, [selectedCustomers])

  useEffect(() => {
    setCustomerOptionsState(
      customerOptions.filter(
        (co) =>
          selectedRestrictedCustomers.find((src) => src.value === co.value) ===
          undefined
      )
    )
  }, [selectedRestrictedCustomers])

  const handleUpdateName = useCallback(() => {
    const updatedName = tierName
    if (tierName.length === 0) {
      saveTierName(updatedName)
    }
    setDisplayNameInput(false)
  }, [tierName])

  const handleNameOnChange = useCallback(
    (value) => {
      saveTierName(value)
    },
    [saveTierName]
  )

  const handleEditClick = useCallback(() => {
    setDisplayNameInput(true)
  }, [setDisplayNameInput])

  return (
    <div className={styles.SingleTierComponent}>
      <div className={styles.tierHeading}>
        <span ref={ref}>
          Tier {tier} {tierName && !displayNameInput && ` - ${tierName}`}
          {displayNameInput && (
            <input
              type="text"
              className={styles.tierInput}
              placeholder="edit tier name"
              onChange={(event) => handleNameOnChange(event.target.value)}
              value={tierName}
              autoFocus
            />
          )}
          <span>
            {!displayNameInput && (
              <span
                className={styles.deleteWatchlist}
                onClick={() => handleEditClick()}
                title="Edit Tier Name"
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            )}
            {displayNameInput && (
              <span
                className={styles.deleteWatchlist}
                onClick={() => handleUpdateName()}
                title="Confirm New Name"
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
            )}
          </span>
        </span>
      </div>
      <div className={styles.checkboxes}>
        <div className={styles.checkbox}>
          <Checkbox
            locator={`defaultCheck-${tier}`}
            checked={defaultTier === tier}
            onChange={(event) => {
              setDefaultTier(tier)
            }}
            disabled={false}
          >
            Default
          </Checkbox>
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            locator={`disabledCheck-${tier}`}
            checked={isDisabled}
            onChange={(event) => {
              setIsDisabled(!isDisabled)
            }}
            disabled={false}
          >
            Disabled
          </Checkbox>
        </div>
      </div>
      {customer && (
        <div className={styles.apiUser}>
          <div className={styles.inputLabel}>{customer.shortName} user:</div>
          <Select
            className={styles.CustomerSelect}
            options={tierUserOptionsState}
            isClearable={true}
            value={selectedTierUser[0]}
            onChange={(event) => {
              if (event) {
                setTierUser(tier, Number(event.value))
              } else {
                setTierUser(tier, Number(0))
              }
            }}
          />
        </div>
      )}
      <div className={styles.border} />
      <div className={styles.enabled}>
        {customerOptions.length > 0 && (
          <>
            <div className={styles.inputLabel}>Enabled Customers:</div>
            <Select
              className={styles.CustomerSelect}
              options={customerOptionsState}
              isMulti
              isClearable={true}
              value={selectedCustomers}
              onChange={(event) => {
                if (event) {
                  const selCustomers = []
                  for (const e of event) {
                    selCustomers.push(e)
                  }
                  saveCustomers(tier, selCustomers)
                }
              }}
            />
          </>
        )}
        <div className={styles.AndOrDropdownWrapper}>
          <GenericDropdownMenu
            selectedItem={enabledAndOr}
            setSelectedItem={saveEnabledAndOr}
            options={AndOrStrings}
            selectId={'AlertFilterAndOr'}
            openRight={true}
            className=""
          />
        </div>
        <div className={styles.inputLabel}>Enabled Types:</div>
        <TypeSelector initialQuery={selectedTypes} setQuery={saveTypes} />

        <div className={styles.border} />

        <div className={styles.restricted}>
          {customerOptions.length > 0 && (
            <>
              <div className={styles.inputLabel}>Restricted Customers:</div>
              <Select
                className={styles.CustomerSelect}
                options={restrictedCustomerOptionsState}
                isMulti
                isClearable={true}
                value={selectedRestrictedCustomers}
                onChange={(event) => {
                  if (event) {
                    const selCustomers = []
                    for (const e of event) {
                      selCustomers.push(e)
                    }
                    saveRestrictedCustomers(tier, selCustomers)
                  }
                }}
              />
            </>
          )}
          <div className={styles.AndOrDropdownWrapper}>
            <GenericDropdownMenu
              selectedItem={restrictedAndOr}
              setSelectedItem={saveRestrictedAndOr}
              options={AndOrStrings}
              selectId={'AlertFilterAndOr'}
              openRight={true}
              className=""
            />
          </div>
          <div className={styles.inputLabel}>Restricted Types:</div>
          <TypeSelector
            initialQuery={selectedRestrictedTypes}
            setQuery={saveRestrictedTypes}
          />
        </div>
      </div>
    </div>
  )
}

export default SingleTierComponent
