import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveBBMISINs } from '../../../store/admin/bbmisin/actions'
import { StringToBBMISINList } from '../../../store/admin/bbmisin/helpers'
import { getBBMISINErrors } from '../../../store/admin/bbmisin/selectors'

import gridStyles from '../../../assets/scss/grid.scss'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import styles from './BBMISIN.scss'

const BBMISIN: FC = () => {
  const dispatch = useDispatch()
  const serverErrors = useSelector(getBBMISINErrors)
  const [bbmIsinData, setBbmIsinData] = useState<string>('')
  const [frontEndError, setFrontEndError] = useState<string>('')
  const [backEndError, setBackEndError] = useState('')

  const updateBbmIsinData = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBbmIsinData(evt.target.value)
  }

  useEffect(() => {
    setBackEndError(serverErrors)
  }, [serverErrors])

  const uploadData = () => {
    const { bbmIsinList, errors } = StringToBBMISINList(bbmIsinData)
    if (bbmIsinList.length > 0) {
      dispatch(saveBBMISINs(bbmIsinList))
    }
    setFrontEndError(errors.join('\n'))
  }

  return (
    <React.Fragment>
      <div className={gridStyles.outerGridContainer}>
        <ComponentHeader title="BBMISIN" />
        <div className={styles.uploadButton}>
          <button onClick={uploadData}>Upload</button>
        </div>
        <div className={gridStyles.gridDimensions}>
          <textarea
            className={styles.textArea}
            placeholder="Cusip/ISIN BBMISIN"
            cols={100}
            value={bbmIsinData}
            onChange={updateBbmIsinData}
          ></textarea>
        </div>
        <div className={styles.errorSection}>
          <div className={styles.frontEndErrorSection}>
            <label>{frontEndError}</label>
          </div>
          <div className={styles.backEndErrorSection}>
            <label>{backEndError}</label>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BBMISIN
