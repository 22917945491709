import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { EMPTY, from, of } from 'rxjs'
import {
  bufferTime,
  catchError,
  endWith,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import { splitCallByChunksAndAggregateResult } from '../helpers'
import { cancelOrders } from '../order/actions'
import { getUserOrders } from '../order/selectors'
import { SetWatchListId, setWatchListId } from '../securities/actions'
import { Security } from '../securities/reducer'
import {
  getDisplayedSecurityIds,
  getGridIndicesWithWatchlist
} from '../securities/selectors'
import { logError } from '../ws/actions'
import {
  AddSecuritiesToWatchlistAction,
  AppendIssuerToWatchlistAction,
  AppendSecurityToWatchlistAction,
  CancelAllWatchListOrdersAction,
  CheckOrUncheckAllSecuritiesAction,
  checkOrUncheckSecurities,
  DeleteWatchlistAction,
  failedSymbolsAndCusipsFromWatchlistDefinitionUpdate,
  FetchAdminWatchListsAction,
  FetchAdminWatchlistsByIdentifierAction,
  fetchAdminWatchlistsByIdentifierSuccessAction,
  FetchAdminWatchlistsByUserAction,
  fetchAdminWatchlistsByUserSuccessAction,
  fetchAdminWatchListsSuccessAction,
  fetchWatchlistDetails,
  FetchWatchlistDetailsAction,
  fetchWatchlistDetailsSuccess,
  fetchWatchListsAction,
  FetchWatchListsAction,
  fetchWatchListsFailureAction,
  FetchWatchListsFailureAction,
  fetchWatchListsSuccessAction,
  HideWatchlistAction,
  RemoveCheckedSecuritiesFromWatchlistAction,
  resetCheckedSecurities,
  UpdateWatchlistDefinitionAction,
  UpdateWatchlistNameAction,
  watchlistDefinitionUpdated,
  WatchListsAction
} from './actions'
import {
  getResponseForPermission,
  watchlistDetailsFromResponse
} from './helpers'
import {
  getCheckedSecuritiesStatus,
  getCheckedSecurityIds,
  getDetailsForCurrentWatchlist,
  getDetailsForWatchlist,
  getWatchlistDetails
} from './selectors'
import { WatchList, WatchlistDetails } from './types'

export const WATCHLISTS_BUFFER_DELAY = 100
const notEmpty = (array: any[]) => array.length > 0

const fetchWatchListEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType<FetchWatchListsAction>('watchList.fetchWatchLists'),
    switchMap((action) => {
      const { gridIndex } = action.payload
      return getHub()
        .stream('GetWatchlists')
        .pipe(
          map(watchlistDetailsFromResponse),
          bufferTime(WATCHLISTS_BUFFER_DELAY),
          filter(notEmpty),

          map((watchlists) =>
            fetchWatchListsSuccessAction(gridIndex, watchlists)
          ),
          endWith(fetchWatchListsSuccessAction(gridIndex, [])),
          catchError((err) =>
            of(fetchWatchListsFailureAction(gridIndex, err), logError(err))
          )
        )
    })
  )

const fetchWatchListEpicFailureEpic: Epic<WatchListsAction> = (action$) =>
  action$.pipe(
    ofType<FetchWatchListsFailureAction>('watchList.fetchWatchListsFailure'),
    map((action) => fetchWatchListsAction(action.payload.gridIndex))
  )

const cancelAllWatchListOrdersEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CancelAllWatchListOrdersAction>(
      'watchList.cancelAllWatchListOrdersAction'
    ),
    mergeMap((action) => {
      const pendingUserOrders = getUserOrders(state$.value).filter(
        (o) => o.status === 'pending'
      )
      // return of(cancelOrders(pendingUserOrders.map((order) => order.id)))
      const { gridIndex, watchlistId } = action.payload
      if (watchlistId === undefined) {
        return of(cancelOrders(pendingUserOrders.map((order) => order.id)))
      } else {
        const displayedSecurityIds = getDisplayedSecurityIds(state$.value)(
          gridIndex
        )
        const userOrdersToBeCancelled = pendingUserOrders.filter((o) =>
          displayedSecurityIds.includes(o.securityId)
        )
        return of(
          cancelOrders(userOrdersToBeCancelled.map((order) => order.id))
        )
      }
    })
  )

const fetchWatchlistDetailsEpic: Epic<Action> = (action$) => {
  return action$.pipe(
    ofType('watchList.fetchWatchlistDetails'),
    mergeMap((action: FetchWatchlistDetailsAction) => {
      const watchlistId = action.payload.watchlistId
      const gridIndex = action.payload.gridIndex
      return getHub()
        .stream<WatchlistDetails>('GetWatchlist', watchlistId)
        .pipe(
          map((details) =>
            fetchWatchlistDetailsSuccess(
              watchlistId,
              watchlistDetailsFromResponse(details)
            )
          ),
          take(1),
          // catchError(err => of(logError(err)))
          catchError(() => of(setWatchListId(gridIndex, undefined)))
        )
    })
  )
}

const removeSecuritiesFromWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchlist.removeCheckedSecuritiesFromWatchlist'),
    mergeMap((action: RemoveCheckedSecuritiesFromWatchlistAction) => {
      const { watchlistId } = action.payload
      const watchlist = getWatchlistDetails(state$.value)(watchlistId)
      const checkedSecurities = getCheckedSecurityIds(state$.value)[watchlistId]
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke('RemoveFromWatchlist', watchlist.id, checkedSecurities)
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const updateSecuritiesInWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.addSecuritiesToWatchlist'),
    mergeMap((action: AddSecuritiesToWatchlistAction) => {
      const { watchlistId } = action.payload
      const watchlist = getWatchlistDetails(state$.value)(watchlistId)
      let identifiers: string[] = []
      if (action.payload.identifiers) {
        identifiers = action.payload.identifiers
      }

      const watchlistDetails = getDetailsForWatchlist(state$.value)(watchlistId)
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke(
          'UpdateWatchlist',
          watchlist.id,
          watchlist.name,
          identifiers,
          getResponseForPermission(watchlist.permission),
          watchlist.book,
          watchlistDetails?.filter,
          watchlistDetails?.myFirmChecked,
          watchlistDetails?.useSizeChecked,
          watchlistDetails?.size,
          watchlistDetails?.useAdvancedFilter,
          false
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

// TODO: repeated logic from upload epic
const GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE = 1000

const getSecurityIdsFromIdentifier$ = (identifiers: string[]) =>
  splitCallByChunksAndAggregateResult(
    identifiers,
    GET_SECURITIES_IDS_FROM_IDENTIFIERS_CHUNK_SIZE,
    (identifiersChunk) =>
      getHub().invoke<Array<Array<Security['id']>>>(
        'GetSecurityIds',
        identifiersChunk
      )
  )

const updateWatchlistDefinitionEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('watchList.updateWatchlistDefinition'),
    mergeMap((action: UpdateWatchlistDefinitionAction) => {
      const watchlist = action.payload
      return getSecurityIdsFromIdentifier$(watchlist.symbolsAndCusips).pipe(
        map((securityIdsArray) =>
          securityIdsArray
            .map((arr, i) =>
              arr.length === 0 ? watchlist.symbolsAndCusips[i] : ''
            )
            .filter((symbol) => !!symbol)
        ),
        mergeMap((failedSymbols) => {
          const goodSymbols = watchlist.symbolsAndCusips.filter(
            (symbol) => !failedSymbols.some((failed) => failed === symbol)
          )
          return getHub()
            .invoke(
              'UpdateWatchlist',
              watchlist.id,
              watchlist.name,
              goodSymbols,
              getResponseForPermission(watchlist.permission),
              watchlist.book,
              watchlist.filter,
              watchlist.myFirmChecked,
              watchlist.useSizeChecked,
              watchlist.size,
              watchlist.useAdvancedFilter,
              true
            )
            .pipe(
              mergeMap(() =>
                of(
                  watchlistDefinitionUpdated({
                    ...watchlist,
                    symbolsAndCusips: goodSymbols
                  }),
                  failedSymbolsAndCusipsFromWatchlistDefinitionUpdate(
                    watchlist.id as number,
                    failedSymbols ?? []
                  )
                )
              ),
              catchError((err) => of(logError(err)))
            )
        })
      )
    })
  )

const appendSecuritiesToWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.appendSecurityToWatchlist'),
    mergeMap((action: AppendSecurityToWatchlistAction) => {
      const { watchlistId } = action.payload
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke(
          'AppendSecurityToWatchlist',
          watchlistId,
          action.payload.securityIds
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const appendIssuerToWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.appendIssuerToWatchlist'),
    mergeMap((action: AppendIssuerToWatchlistAction) => {
      const { watchlistId } = action.payload
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)
      return getHub()
        .invoke(
          'AppendIssuerToWatchlist',
          watchlistId,
          action.payload.issuer,
          action.payload.append
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const fetchWatchlistDetailsWhenChangingWatchlist: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('securities.setWatchListId'),
    filter(
      (action: SetWatchListId) =>
        action.payload.watchlistId !== undefined &&
        action.payload.watchlistId !== 0 // WatchlistId = 0 means this is a 'Mine' filter
    ),
    map((action) =>
      fetchWatchlistDetails(
        action.payload.watchlistId as number,
        action.payload.gridIndex
      )
    )
  )

const resetCheckedSecuritiesWhenChangingWatchlist: Epic<Action> = (action$) =>
  action$.pipe(
    ofType<SetWatchListId>('securities.setWatchListId'),
    mergeMap((action) =>
      of(resetCheckedSecurities(action.payload.watchlistId ?? 0))
    )
    // mapTo(resetCheckedSecurities())
  )

const checkOrUncheckAllSecuritiesEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<CheckOrUncheckAllSecuritiesAction>(
      'watchlist.checkOrUncheckAllSecurities'
    ),
    mergeMap((action) => {
      const { gridIndex } = action.payload
      const state = state$.value
      const watchlist = getDetailsForCurrentWatchlist(state)(gridIndex)
      if (!watchlist) return EMPTY
      const status = getCheckedSecuritiesStatus(state)(watchlist.id!)

      /* const securityIdsObject = watchlist.securityIds.map((securityId) => {
        return {
          id: securityId,
          watchlistId: watchlist.id
        }
      })*/

      if (status === 'all' || status === 'some') {
        return of(resetCheckedSecurities(watchlist.id ?? 0))
      } else {
        return of(
          checkOrUncheckSecurities(
            watchlist.id ?? 0,
            watchlist.securityIds,
            true
          )
        )
      }
    })
  )

const deleteWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<DeleteWatchlistAction>('watchlist.deleteWatchlist'),
    mergeMap((action) => {
      const watchlistId = action.payload
      const gridIndicesWithWatchlist = getGridIndicesWithWatchlist(
        state$.value
      )(watchlistId)
      return getHub()
        .invoke('DeleteWatchlist', action.payload)
        .pipe(
          mergeMapTo(
            from(
              gridIndicesWithWatchlist.map((gridIndex) =>
                setWatchListId(gridIndex, undefined)
              )
            )
          ),
          // map(() => deleteWatchlistSuccess(action.payload)),
          catchError((err) => of(logError(err)))
        )
    })
  )
const hideWatchlistEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType<HideWatchlistAction>('watchlist.hideWatchlist'),
    mergeMap((action) => {
      const watchlistId = action.payload
      const gridIndicesWithWatchlist = getGridIndicesWithWatchlist(
        state$.value
      )(watchlistId)
      return getHub()
        .invoke('HideWatchlist', action.payload)
        .pipe(
          mergeMapTo(
            from(
              gridIndicesWithWatchlist.map((gridIndex) =>
                setWatchListId(gridIndex, undefined)
              )
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const updateWatchlistNameEpic: Epic<Action> = (action$, state$) =>
  action$.pipe(
    ofType('watchList.updateWatchlistName'),
    mergeMap((action: UpdateWatchlistNameAction) => {
      const { watchlistId, newName } = action.payload
      const watchlist = getWatchlistDetails(state$.value)(watchlistId)
      const watchlistDetails = getDetailsForWatchlist(state$.value)(watchlistId)
      const gridIndices = getGridIndicesWithWatchlist(state$.value)(watchlistId)

      return getHub()
        .invoke(
          'UpdateWatchlist',
          watchlistId,
          newName,
          watchlist.symbolsAndCusips,
          getResponseForPermission(watchlist.permission),
          watchlist.book,
          watchlistDetails?.filter,
          watchlistDetails?.myFirmChecked,
          watchlistDetails?.useSizeChecked,
          watchlistDetails?.size,
          watchlistDetails?.useAdvancedFilter,
          false
        )
        .pipe(
          mergeMap(() =>
            of(
              ...gridIndices.map((index) => setWatchListId(index, watchlistId))
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const fetchAdminWatchlistsEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('watchList.fetchAdminWatchLists'),
    mergeMap((action: FetchAdminWatchListsAction) => {
      return getHub()
        .stream('GetWatchlistsAdminView')
        .pipe(
          map((watchlists: any) =>
            fetchAdminWatchListsSuccessAction(
              watchlists.map(watchlistDetailsFromResponse)
            )
          ),
          catchError((err) => of(logError(err))),
          takeUntil(
            action$.pipe(
              ofType(
                'watchList.fetchAdminWatchlistsByUser',
                'watchList.fetchAdminWatchlistsByIdentifier',
                'watchList.fetchAdminWatchListsSuccess'
              )
            )
          )
        )
    })
  )

const fetchAdminWatchlistsByUserEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('watchList.fetchAdminWatchlistsByUser'),
    mergeMap((action: FetchAdminWatchlistsByUserAction) => {
      return getHub()
        .invoke('GetWatchlistsForUser', action.payload)
        .pipe(
          map((watchlists: WatchList[]) =>
            fetchAdminWatchlistsByUserSuccessAction(
              watchlists.map(watchlistDetailsFromResponse)
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

const fetchAdminWatchlistsByIdentifierEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('watchList.fetchAdminWatchlistsByIdentifier'),
    mergeMap((action: FetchAdminWatchlistsByIdentifierAction) => {
      return getHub()
        .invoke('GetWatchlistsContainingSecurity', action.payload)
        .pipe(
          map((watchlists: WatchList[]) =>
            fetchAdminWatchlistsByIdentifierSuccessAction(
              watchlists.map(watchlistDetailsFromResponse)
            )
          ),
          catchError((err) => of(logError(err)))
        )
    })
  )

export default combineEpics(
  fetchWatchListEpic,
  cancelAllWatchListOrdersEpic,
  fetchWatchListEpicFailureEpic,
  fetchWatchlistDetailsWhenChangingWatchlist,
  fetchWatchlistDetailsEpic,
  updateSecuritiesInWatchlistEpic,
  resetCheckedSecuritiesWhenChangingWatchlist,
  checkOrUncheckAllSecuritiesEpic,
  deleteWatchlistEpic,
  hideWatchlistEpic,
  appendSecuritiesToWatchlistEpic,
  updateWatchlistDefinitionEpic,
  appendIssuerToWatchlistEpic,
  updateWatchlistNameEpic,
  fetchAdminWatchlistsEpic,
  fetchAdminWatchlistsByUserEpic,
  fetchAdminWatchlistsByIdentifierEpic,
  removeSecuritiesFromWatchlistEpic
)
