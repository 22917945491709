import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLogItem } from '../../store/log/actions'
import { cancelOrder, createOrder } from '../../store/order/actions'
import {
  getErrorForOrder,
  getPendingUserOrderForSecurity
} from '../../store/order/selectors'
import { OrderType } from '../../store/order/types'
import { Security } from '../../store/securities/reducer'
import {
  addOrUpdateStagedOrders,
  removeStagedOrder,
  setFocusOnOrder,
  setTempOrderFieldValue
} from '../../store/stagedOrders/actions'
import {
  getStagedOrderBySecurityId,
  getTempFieldValuesForOrder,
  orderIsFocused
} from '../../store/stagedOrders/selectors'
import { StagedOrder } from '../../store/stagedOrders/types'
import { getDefaultBidOfferValue } from '../../store/userPreferences/selectors'
import { formatSize } from '../formatting'
import { calculateOrderPriceFromInput } from '../utils'

type NewLiveOrder = Omit<StagedOrder, 'orderType'> & {
  id: string
}
type NewStagedOrder = Omit<StagedOrder, 'orderType'>

export type OrderFields = 'price' | 'size' | 'spread'

// actions
const createOrderLog = (
  order: NewLiveOrder | NewStagedOrder,
  securityId: number,
  type: OrderType
) => {
  return `create order on security ${securityId} price: ${order.price}, isSpread: ${order.isSpreadOrder} size: ${order.size}, side: ${type}, aon: ${order.allOrNone}`
}
const createOrderAction = (
  order: NewLiveOrder | NewStagedOrder,
  securityId: number,
  type: OrderType
) => {
  const priceOrSpread = (order.isSpreadOrder ? order.spread : order.price) ?? 0
  return createOrder(
    securityId,
    type,
    priceOrSpread,
    order.isSpreadOrder,
    Math.max(order.size, order.totalSize ?? 0),
    order.allOrNone,
    order.individualMin ? order.individualMin : 0,
    order.custId,
    order.tob,
    order.totalSize ?? 0 > order.size ? order.size : undefined
  )
}

// util
const isEmptyOrder = (order: NewLiveOrder | NewStagedOrder) => {
  return !order.price && !order.spread
}
const createNewStagedOrder = (
  securityId: number,
  isSpreadOrder: boolean,
  defaultSize: number
) => {
  return {
    securityId,
    price: 0,
    spread: 0,
    isSpreadOrder,
    size: defaultSize,
    allOrNone: false,
    individualMin: 0,
    custId: 0,
    tob: { tob: false, limitPrice: 0, floorPrice: 0 }
  }
}

const calculateSizeAndAonFromInput = (sizeInput?: string) => {
  if (!sizeInput) {
    return {
      size: 0,
      allOrNone: false
    }
  }
  const isIceberg = /\d+a? \(\d+\)/.test(sizeInput)
  if (isIceberg) {
    const words = sizeInput.replace('(', '').replace(')', '').split(' ')
    const ibAon = words[0]?.length > 1 && words[0].toLowerCase().endsWith('a')
    const size = parseInt(words[0] || '', 10)
    const totalSize = parseInt(words[1] || '', 10)
    if (totalSize >= 1000) {
      return { size, totalSize, allOrNone: ibAon }
    }
  }

  const aon = sizeInput.toLowerCase().endsWith('a') && sizeInput !== 'a'
  if (aon) {
    return {
      allOrNone: aon,
      size: parseFloat(sizeInput) || 0,
      totalSize: undefined
    }
  }
  return {
    size: parseFloat(sizeInput) || 0,
    totalSize: undefined
  }
}

const calculateActualOrImpliedPendingStyle = (
  fieldName: OrderFields,
  isSpread: boolean
) => {
  // true is actual (apply style), false is implied (no style)
  switch (fieldName) {
    case 'size':
      return true
    case 'spread':
      return isSpread
    case 'price':
      return !isSpread
  }
}

const orderTypeMapping = {
  buy: {
    name: 'bid',
    action: 'HIT'
  },
  sell: {
    name: 'offer',
    action: 'LIFT'
  }
}

export const useManageOneOrderType = (orderType: OrderType) => {
  const dispatch = useDispatch()

  // TODO: switch to getStagedOrderBySecurityForOrderType
  // (also check for other selectors that let you zero in on order type)
  const getStagedOrder = useSelector(getStagedOrderBySecurityId)
  const getLiveOrder = useSelector(getPendingUserOrderForSecurity)
  const getOrderValidation = useSelector(getErrorForOrder)
  const getTempFieldValues = useSelector(getTempFieldValuesForOrder)

  const defaultBidOffer = useSelector(getDefaultBidOfferValue)
  const getSecurityOrderIsFocused = useSelector(orderIsFocused)

  const orderTypeName = orderTypeMapping[orderType].name
  const orderTypeAction = orderTypeMapping[orderType].action

  const focusOrder = useCallback((securityId: number) => {
    dispatch(setFocusOnOrder({ securityId, orderType }))
  }, [])

  const getStagedAndLiveOrderForSecurity = useCallback(
    (securityId: number) => {
      const stagedOrder =
        (securityId && getStagedOrder(securityId)?.[orderType]) || undefined
      const liveOrder =
        (securityId && getLiveOrder(securityId, orderType)) || undefined

      return { liveOrder, stagedOrder }
    },
    [getStagedOrder, getLiveOrder]
  )
  const getStagedOrLiveOrderForSecurity = useCallback(
    (securityId: number) => {
      const { stagedOrder, liveOrder } =
        getStagedAndLiveOrderForSecurity(securityId)

      return liveOrder || stagedOrder
    },
    [getStagedAndLiveOrderForSecurity]
  )

  const getStyleConditions = useCallback(
    (securityId: number, field: OrderFields) => {
      const conditions = {
        hasError: false,
        isPending: false,
        hasFocus: false
      }
      if (!securityId) return conditions
      if (getOrderValidation(securityId, orderType) !== undefined) {
        conditions.hasError = true
      }
      const { liveOrder } = getStagedAndLiveOrderForSecurity(securityId)
      if (liveOrder) {
        conditions.isPending =
          !!liveOrder &&
          calculateActualOrImpliedPendingStyle(field, liveOrder.isSpreadOrder)
      }
      const isFocused = getSecurityOrderIsFocused(securityId, orderType)
      if (isFocused) {
        conditions.hasFocus = true
      }
      return conditions
    },
    [
      getStagedAndLiveOrderForSecurity,
      getOrderValidation,
      getSecurityOrderIsFocused
    ]
  )

  const updateLiveAndStagedOrder = useCallback(
    (
      cellValue: string,
      stagedOrder: NewStagedOrder,
      liveOrder?: NewLiveOrder
    ) => {
      if (cellValue === '') {
        if (liveOrder) {
          dispatch(cancelOrder(liveOrder.id))
        }
        if (stagedOrder && isEmptyOrder(stagedOrder)) {
          dispatch(
            removeStagedOrder({ securityId: stagedOrder.securityId, orderType })
          )
        }
        return
      }
      if (liveOrder) {
        dispatch(
          addLogItem(
            createOrderLog(liveOrder, stagedOrder.securityId, orderType)
          )
        )
        dispatch(
          createOrderAction(liveOrder, stagedOrder.securityId, orderType)
        )
      }
      dispatch(addOrUpdateStagedOrders([{ ...stagedOrder, orderType }]))
    },
    []
  )

  const buildNewOrderParamsForSecurity = useCallback(
    (security: Security, isSpreadOrder: boolean) => {
      const { liveOrder: oldLiveOrder, stagedOrder: oldStagedOrder } =
        getStagedAndLiveOrderForSecurity(security.id)
      const newLiveOrder = oldLiveOrder
        ? {
            securityId: security.id,
            price: oldLiveOrder.price,
            spread: oldLiveOrder.spread,
            isSpreadOrder: oldLiveOrder.isSpreadOrder,
            size: oldLiveOrder.size,
            allOrNone: oldLiveOrder.allOrNone,
            individualMin: oldLiveOrder.individualMin || 0,
            custId: oldLiveOrder.custId,
            tob: oldLiveOrder.tob,
            id: oldLiveOrder.id
          }
        : undefined
      const newStagedOrder = oldStagedOrder
        ? { ...oldStagedOrder, securityId: security.id }
        : undefined
      const defaultSize = defaultBidOffer ?? security.defaultOrderSize ?? 100
      return {
        liveOrder: newLiveOrder,
        stagedOrder:
          newStagedOrder ??
          createNewStagedOrder(security.id, isSpreadOrder, defaultSize)
      }
    },
    [getStagedAndLiveOrderForSecurity, defaultBidOffer]
  )
  const getTempFieldValuesForStagedOrder = useCallback(
    (securityId: Security['id']) => {
      return getTempFieldValues(securityId, orderType)
    },
    [getTempFieldValues]
  )

  const getInitialFieldValueForSecurity = useCallback(
    (securityId: number, fieldName: OrderFields) => {
      const uncommittedFieldValues =
        getTempFieldValuesForStagedOrder(securityId)
      const existingOrder = getStagedOrLiveOrderForSecurity(securityId)
      const storedValue =
        existingOrder?.[fieldName] || uncommittedFieldValues?.[fieldName] || ''

      if (fieldName === 'price') {
        return existingOrder && 'displayPrice' in existingOrder
          ? existingOrder.displayPrice
          : storedValue
      }

      if (!storedValue || fieldName !== 'size' || !existingOrder) {
        return storedValue
      }
      const aon = existingOrder.allOrNone ? 'a' : ''
      if (existingOrder) {
        const { totalSize, size } = existingOrder
        if (totalSize && totalSize !== size) {
          return formatSize(size, totalSize, !!aon, true)
        }
      }
      return storedValue + aon
    },
    [getTempFieldValuesForStagedOrder, getStagedOrLiveOrderForSecurity]
  )

  const setOrderSize = useCallback(
    (size: string, security?: Security) => {
      // check if there are any updates to make
      if (!security) return
      const orderUpdates = calculateSizeAndAonFromInput(size || '')
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      if (oldOrder) {
        if (
          oldOrder.size === orderUpdates.size &&
          oldOrder.allOrNone === orderUpdates.allOrNone
        ) {
          return
        }
      } else {
        // you can't make an order just based on size
        dispatch(setTempOrderFieldValue(security.id, orderType, 'size', size))
        return
      }

      // get live and staged orders to work with
      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )
      const newLiveOrder = liveOrder
        ? { ...liveOrder, ...orderUpdates }
        : undefined

      // make the update
      updateLiveAndStagedOrder(
        size,
        { ...stagedOrder, ...orderUpdates },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getStagedOrLiveOrderForSecurity
    ]
  )

  const setOrderPrice = useCallback(
    (price: string, security?: Security) => {
      if (!security) return
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      const newPrice = calculateOrderPriceFromInput(
        price,
        security.product === 'PrinUSGovtOutright'
      )
      if (oldOrder && oldOrder.price === newPrice) return

      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )

      // TODO: figure out what of all this sort of repeated logic can go into buildNewOrderParamsForSecurity
      const newSpread = !!newPrice ? undefined : stagedOrder.spread
      const isSpreadOrder = !!newSpread
      const defaultSize = `${
        defaultBidOffer ?? security.defaultOrderSize ?? 100
      }`
      const sizeAndAon = oldOrder
        ? { size: oldOrder.size, allOrNone: oldOrder.allOrNone }
        : calculateSizeAndAonFromInput(
            getTempFieldValuesForStagedOrder(security?.id ?? -1)?.size ||
              defaultSize
          )

      const newLiveOrder = liveOrder
        ? {
            ...liveOrder,
            price: newPrice,
            spread: newSpread,
            isSpreadOrder,
            ...sizeAndAon
          }
        : undefined
      updateLiveAndStagedOrder(
        price,
        {
          ...stagedOrder,
          price: newPrice,
          spread: newSpread,
          isSpreadOrder,
          ...sizeAndAon
        },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getStagedOrLiveOrderForSecurity,
      getTempFieldValuesForStagedOrder
    ]
  )

  const setOrderSpread = useCallback(
    (spread: string, security?: Security) => {
      if (!security) return
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      const newSpread = parseFloat(spread)
      if (oldOrder && oldOrder.spread === newSpread) return

      const { liveOrder, stagedOrder } = buildNewOrderParamsForSecurity(
        security,
        false
      )

      const defaultSize = `${
        defaultBidOffer ?? security.defaultOrderSize ?? 100
      }`

      const sizeAndAon = oldOrder
        ? { size: oldOrder.size, allOrNone: oldOrder.allOrNone }
        : calculateSizeAndAonFromInput(
            getTempFieldValuesForStagedOrder(security?.id ?? -1)?.size ||
              defaultSize
          )

      const newLiveOrder = liveOrder
        ? {
            ...liveOrder,
            spread: newSpread || undefined,
            isSpreadOrder: !!newSpread,
            price: !!newSpread ? undefined : stagedOrder.spread,
            ...sizeAndAon
          }
        : undefined
      updateLiveAndStagedOrder(
        spread,
        {
          ...stagedOrder,
          spread: newSpread || undefined,
          isSpreadOrder: !!newSpread,
          price: !!newSpread ? undefined : stagedOrder.spread,
          ...sizeAndAon
        },
        newLiveOrder
      )
    },
    [
      buildNewOrderParamsForSecurity,
      updateLiveAndStagedOrder,
      getStagedOrLiveOrderForSecurity,
      getTempFieldValuesForStagedOrder
    ]
  )

  const setOrderFieldByName = useCallback(
    (fieldName: OrderFields, fieldValue: string, security?: Security) => {
      switch (fieldName) {
        case 'spread': {
          setOrderSpread(fieldValue, security)
          break
        }
        case 'price': {
          setOrderPrice(fieldValue, security)
          break
        }
        case 'size': {
          setOrderSize(fieldValue, security)
        }
      }
    },
    [setOrderSpread, setOrderPrice, setOrderSize]
  )

  const canEditThisField = useCallback(
    (fieldName: OrderFields, securityId: number) => {
      const storedOrder = getStagedOrLiveOrderForSecurity(securityId)
      // can always edit staged orders
      if (!storedOrder?.hasOwnProperty('id')) {
        return true
      }
      const isTob = !!storedOrder?.tob?.tob
      switch (fieldName) {
        case 'size':
          return (
            !storedOrder?.totalSize ||
            storedOrder.totalSize === storedOrder.size
          )
        case 'spread':
          return !isTob && Boolean(storedOrder && storedOrder.isSpreadOrder)
        case 'price':
          return !isTob && Boolean(storedOrder && !storedOrder?.isSpreadOrder)
      }
    },
    [getStagedOrLiveOrderForSecurity]
  )

  const submitStagedOrder = useCallback(
    (security: Security) => {
      const securityId = security.id
      const oldStagedOrder =
        securityId && getStagedOrder(securityId)?.[orderType]
      if (!oldStagedOrder) return false
      if (getOrderValidation(securityId, orderType) !== undefined) return false
      const { stagedOrder } = buildNewOrderParamsForSecurity(security, false)
      dispatch(createOrderAction(stagedOrder, securityId, orderType))
      return true
    },
    [getStagedOrder, getOrderValidation]
  )

  const stageAndSubmitOrder = useCallback(
    (fieldName: OrderFields, fieldValue: string, security?: Security) => {
      if (!security) return
      const oldOrder = getStagedOrLiveOrderForSecurity(security.id)
      if (!oldOrder && fieldName === 'size') return
      // there may not really be a staged order, so we make one to submit
      const { stagedOrder } = buildNewOrderParamsForSecurity(security, false)
      const price =
        fieldName === 'price'
          ? calculateOrderPriceFromInput(
              fieldValue,
              security.product === 'PrinUSGovtOutright'
            )
          : oldOrder?.price ?? 0
      const spread =
        fieldName === 'spread'
          ? parseFloat(fieldValue)
          : fieldName === 'price'
          ? undefined
          : stagedOrder.spread
      const isSpreadOrder =
        fieldName === 'size' ? stagedOrder.isSpreadOrder : !!spread

      /*  The size field can have an "a" at the end to indicate all or none.
          If a user has only entered a size and nothing else, we don't create a
          staged order yet--instead we store the entry in temp field values.
          So, if we are entering an order and we're in the size field, we just take
          the value the user entered and calculate size and aon from there.
          If we're not, we first look on the old order, if present.
          Next we get the temp value if we have it and calculate from that.
          Finally, we fall back on the values created from
          buildNewOrderParamsForSecurity
       */
      const sizeAndAon =
        fieldName === 'size'
          ? calculateSizeAndAonFromInput(fieldValue) // this field is size
          : oldOrder
          ? { size: oldOrder.size, allOrNone: oldOrder.allOrNone } // not size, have old order
          : getTempFieldValuesForStagedOrder(security.id)?.size // no old order
          ? calculateSizeAndAonFromInput(
              getTempFieldValuesForStagedOrder(security.id)?.size
            ) // have temp value
          : { size: stagedOrder.size, allOrNone: stagedOrder.allOrNone } // fallback to params

      const newOrder = {
        ...stagedOrder,
        price,
        spread,
        isSpreadOrder,
        ...sizeAndAon
      }
      dispatch(createOrderAction(newOrder, security.id, orderType))
    },
    [
      setOrderSpread,
      setOrderPrice,
      setOrderSize,
      getStagedOrder,
      getOrderValidation
    ]
  )

  return {
    focusOrder,
    getTempFieldValuesForStagedOrder,
    getStagedOrLiveOrderForSecurity,
    getStyleConditions,
    getInitialFieldValueForSecurity,

    orderTypeAction,
    orderTypeName,

    setOrderFieldByName,
    canEditThisField,

    setOrderPrice,
    setOrderSize,
    setOrderSpread,

    stageAndSubmitOrder,
    submitStagedOrder
  }
}

export type OrderManager = ReturnType<typeof useManageOneOrderType>
