import React, { FC } from 'react'
import AdminMenu from './AdminMenu'
import ClientMenu from './ClientMenu'
import styles from './menus.scss'

export interface Props {
  menuName: string
  closeMenu: () => void
}

const LeftMenu: FC<Props> = ({ menuName, closeMenu }) => {
  const getMenu = () => {
    switch (menuName) {
      case 'Admin':
        return <AdminMenu closeMenu={closeMenu} />
      case 'Client':
        return <ClientMenu closeMenu={closeMenu} />
      default:
        return null
    }
  }
  return <div className={styles.menuContainer}>{getMenu()}</div>
}

export default LeftMenu
