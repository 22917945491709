import cx from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from '../../../assets/scss/grid.scss'
import {
  adminTradeBlotterFetch,
  unsubscribeFromBlotter
} from '../../../store/admin/adminTradeBlotter/actions'
import ComponentHeader from '../../ComponentHeader/ComponentHeader'
import TradeBlotterGrid from './Grid/TradeBlotterGrid'
import TradeBlotterModal from './AdminTradeBlotterModal'

const TradeBlotter: FC = () => {
  const dispatch = useDispatch()
  const [selectedRow, setSelectedRow] = useState<string>('')
  const [displayModal, toggleModal] = useState(false)

  useEffect(() => {
    dispatch(adminTradeBlotterFetch())
    return () => {
      dispatch(unsubscribeFromBlotter())
    }
  }, [])

  const gridDoubleClick = (row: any) => {
    handleShowModal(row.data.ticketId)
  }

  const handleShowModal = useCallback(
    (row: string) => {
      setSelectedRow(row)
      toggleModal(!displayModal)
    },
    [displayModal, selectedRow]
  )

  return (
    <div className={styles.outerGridContainer}>
      <ComponentHeader title="Trade Blotter" />
      <div
        className={cx(styles.gridDimensions, 'ag-theme-balham trade-blotter')}
      >
        <TradeBlotterGrid onDoubleClick={gridDoubleClick} />
      </div>
      {displayModal && (
        <TradeBlotterModal
          closeFunc={() => toggleModal(false)}
          paramId={selectedRow}
        />
      )}
    </div>
  )
}

export default TradeBlotter
