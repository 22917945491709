import React, { FC } from 'react'
import ReactModal from 'react-modal'

import { useDispatch } from 'react-redux'
import { useToggleWithCancelCallback } from '../../helpers/hooks'
import { tradeBlotterFetchCancel } from '../../store/activityTradeBlotter/actions'
import Button from '../Button/Button'
import ActivityTradeBlotterGrid from './Grid/ActivityTradeBlotterGrid'
import styles from '../ActivityLog/ActivityLog.scss'

const ActivityTradeBlotter: FC = () => {
  const dispatch = useDispatch()

  const [displayModal, toggleModal] = useToggleWithCancelCallback(false, () => {
    dispatch(tradeBlotterFetchCancel())
  })

  return (
    <>
      {displayModal && (
        <>
          <ReactModal
            isOpen={displayModal}
            onRequestClose={() => toggleModal(false)}
          >
            <ActivityTradeBlotterGrid onToggle={toggleModal} />
          </ReactModal>
        </>
      )}
      <Button
        onClick={() => toggleModal(true)}
        className={styles.activityLogToggle}
      >
        My Trade Blotter
      </Button>
    </>
  )
}

export default ActivityTradeBlotter
